import Vue from "vue";

Vue.directive("closable", {
  bind(el, binding, vnode) {
    const handleOutsideClick = (e) => {
      const { handler, exclude } = binding.value;

      const excludedElements = exclude.map(
        (refName) => vnode.context.$refs[refName]
      );

      if (el && vnode.context) {
        const clickedOnExcludedEl = excludedElements.some(
          (excludedEl) => excludedEl && excludedEl.contains(e.target)
        );

        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          vnode.context[handler]();
        }
      }
    };

    el._vueClickOutside_ = handleOutsideClick;

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },

  unbind(el) {
    if (el._vueClickOutside_) {
      document.removeEventListener("click", el._vueClickOutside_);
      document.removeEventListener("touchstart", el._vueClickOutside_);
      delete el._vueClickOutside_;
    }
  },
});
