import Cookies from "js-cookie";

async function adminAuth(to, from, next) {
	const permission = Cookies.get("access_level");
	const allowedRules = ["owner", "admin"];
	const isAdmin = allowedRules.includes(permission);

	if (isAdmin) {
		return next();
	}

	return next({ name: "Home" });
}

export { adminAuth };
