<template>
  <button
    :class="['button', ...cssClasses]"
    :disabled="disabled"
    @click.prevent="click"
    :style="'height:' + height + 'px ; font-size:' + font + 'px;'"
  >
    <slot> </slot>
  </button>
</template>
<script>
export default {
  props: {
    height: {
      default: "",
    },
    font: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      required: true,
      default: "primary",
      validator: (val) =>
        [
          "primary",
          "white",
          "danger",
          "info",
          "info2",
          "link-info",
          "outline-info",
          "outline-primary",
          "link",
          "outline-danger",
          "black",
        ].includes(val),
    },
  },
  computed: {
    cssClasses() {
      let classes = [];

      this.outline
        ? classes.push(`button-outline-${this.variant}`)
        : classes.push(`button-${this.variant}`);

      return classes;
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.button {
  border: none;
  background: var(--maincolor);
  color: #fff;
  outline: none !important;
  font-weight: 600;
  height: 50px;
  padding: 0 42px !important;
  border-radius: 30px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:hover {
    transform: scale(1.07);
  }
  &:focus {
    box-shadow: none;
  }
}

.button-primary {
  color: #fff;
  background: var(--maincolor);
}

.button-white {
  width: auto;
  color: #777;
  background: transparent;
  border: 1px #fff solid;
  cursor: pointer;

  &:hover {
    background: #777;
    color: #fff;
  }
}

.button-danger {
  color: #fff;
  background: #ff0c37;
  &:hover {
    box-shadow: 0 8px 20px rgba(255, 12, 55, 0.2);
  }
}

.button-info {
  width: auto;
  color: #fff;
  background: #002363;
  border: 1px #002363 solid;
  cursor: pointer;

  &:hover {
    background: transparent;
    color: #002363;
  }
}

.button-info2 {
  width: auto;
  color: #fff;
  background: #002363;
  border: 1px #002363 solid;
  cursor: pointer;
}

.button-info3 {
  width: auto;
  color: #002363;
  background: #0023631a;
  border: none;
  cursor: pointer;
}

.button-black {
  width: auto;
  color: #fff;
  background: rgb(2, 0, 22);
  cursor: pointer;
}

.button-link-info {
  color: #333;
  background: #f7f7f7;
  border: none;
  cursor: pointer;
}
.button-link {
  color: #fff;
  background: #333;
  border: none;
  cursor: pointer;
}

// Outlines

.button-outline-primary {
  background: transparent;
  color: var(--maincolor);
  border: 1px var(--maincolor) solid;

  &:hover {
    background: var(--maincolor);
    color: #fff;
  }
}

.button-outline-white {
  background: transparent;
  color: #777;
  border: 1px #777 solid;

  &:hover {
    background: #777;
    color: #333;
  }
}

.button-outline-danger {
  background: transparent;
  color: #ff0c37;
  border: 1px #ff0c37 solid;

  &:hover {
    background: #ff0c37;
    color: #fff;
  }
}

.button-outline-info {
  background: transparent;
  color: #002363;
  border: 1px #002363 solid;

  &:hover {
    background: #002363;
    color: #fff;
  }
}

.button-outline-warning {
  background: transparent;
  color: #ffcb37;
  border: 1px #ffcb37 solid;

  &:hover {
    background: #ffcb37;
    color: #fff;
  }
}

.button-outline-black {
  background: transparent;
  color: #000;
  border: 1px #000 solid;

  &:hover {
    background: #000;
    color: #fff;
  }
}

@media screen and (max-width: 1200px) {
  .button {
    height: 45px;
    padding: 0 20px !important;
    border-radius: 30px;
    font-size: 13px;
  }
}
</style>
