import store from '@/store/index'

function isAuthenticated(to, from, next) {
    // if (store.getters.isAuthenticated) {
    //     return next('/home')
    // }

    return next()
}

const unauthentication = isAuthenticated

export default unauthentication