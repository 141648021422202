import { func } from "@/services/libs/convertColor.js";

const defineMainColor = (paramColor) => {
  const isNull = !paramColor || paramColor === "null";
  const color = isNull ? "#002363" : paramColor;
  const el = document;
  el.documentElement.setAttribute("data-color", color);
  const headTag = document.getElementsByTagName("head")[0];
  const styleTag = document.createElement("style");
  styleTag.innerHTML = `
        :root {
            --maincolor: ${color}
        }
  `;
  headTag.appendChild(styleTag);
  const doc = document.documentElement;
  doc.setAttribute("data-color", color);
  doc.style.setProperty("--maincolor", color);
  doc.style.setProperty("--maincolortrans", color + "0f");
  doc.style.setProperty("--maincolorn", '#' + func.convertColor(toString(color), 210));
};

export default defineMainColor;
