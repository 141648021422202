<template>
  <div class="search-item" @click="open">
    <TextPlay class="path" size="xxs" color="var(--subtitle-color)">{{ item.path }}</TextPlay>
    <TextPlay class="title" color="var(--title-color)">{{ item.title }}</TextPlay>
  </div>
</template>
  
<script>
import { mapActions } from 'vuex';


export default {
  name: 'CourseHome',
  props: ['item'],
  methods: {
    ...mapActions({
      openLesson: "courses/actionOpenLesson",
    }),
    async open() {
      if (this.item.is_liberated === true) {
        this.$emit("click");
        const params = await this.openLesson({ courseId: this.item.course_id, moduleId: this.item.module_id, lessonId: this.item.lesson_id });
        await this.$router.push({
          name: "course-lesson",
          params,
        });
      } else {
        this.$bvToast.toast(this.$t("search_modal.error.liberation"), {
          title: this.$t("search_modal.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
  }
}
</script>
  
<style lang="scss" scoped>

.search-item {
  padding: 0.75rem 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: #FFFFFF12;
  }
}

.path {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

</style>
