import PlansService from "@/services/resources/PlansService";
const plansService = PlansService.build();

export default {
	async fetchMe() {
		const result = await plansService.read("me");
		return result;
	},
	async fetchPlans({ commit }) {
		return new Promise(async resolve => {
			const response = await plansService.read("");
      commit("memberPlan/SET_PLANS", response, { root: true });

			resolve(response);
		});
	},
};
