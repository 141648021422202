import ExerciseService from "@/services/resources/ExerciseService";
const serviceExercise = ExerciseService.build();

export default {
  async actionFetchExercise({ commit }, paths) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceExercise.read(`/${paths.join("/")}`);
        commit("exercises/SET_SELECTED_EXERCISE", response, { root: true });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async actionFetchExercisesAreas({ commit }, categoryName) {
    return new Promise(async (resolve, reject) => {
      if (this.getters["exercises/getSelectedCategory"]?.url === categoryName)
        return resolve();
      commit("exercises/SET_EXERCISES_AREA", [], { root: true });

      try {
        const response = await serviceExercise.search();
        commit("exercises/SET_EXERCISES_AREA", response.sub_areas, {
          root: true,
        });
        commit(
          "exercises/SET_CATEGORIES_DATA",
          response.categories.map((category) => ({
            ...category,
            title: category.name,
            id: category.id,
          })),
          { root: true }
        );
        commit(
          "exercises/SET_SELECTED_CATEGORY",
          response.categories.find((category) => category.url === categoryName),
          { root: true }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  async actionFetchExercisesByCategory({ commit }, path) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceExercise.read(
          `/${path.area}/${path.subCategory}?page=${path.page}`
        );
        const prevExercisesData = path.refresh
          ? []
          : this.getters["exercises/getExercises"];

        commit(
          "exercises/SET_EXERCISES_DATA",
          {
            ...(response || {}),
            exercises: {
              ...(response.exercises || {}),
              data: [
                ...(prevExercisesData?.exercises?.data || []),
                ...response.exercises.data,
              ],
            },
          },
          { root: true }
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  async actionSetSelectedCategory({ commit }, selectedCategory) {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          this.getters["exercises/getSelectedCategory"]?.url ===
          selectedCategory.url
        )
          return resolve();
        commit("exercises/SET_EXERCISES_DATA", {}, { root: true });
        commit("exercises/SET_SELECTED_CATEGORY", selectedCategory, {
          root: true,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  async actionSetSubCategory({ commit }, subCategoryURL) {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.getters["exercises/getSubCategory"]?.url === subCategoryURL)
          return resolve();
        commit("exercises/SET_EXERCISES_DATA", {}, { root: true });
        const subCategory =
          this.getters["exercises/getAreasFromCategory"]
            ?.find((area) =>
              area.subjects?.find((subject) => subject.url === subCategoryURL)
            )
            ?.subjects?.find((subject) => subject.url === subCategoryURL) ||
          null;
        commit("exercises/SET_SUB_CATEGORY", subCategory, {
          root: true,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  async submitAnswer({ commit }, { answer, exercise }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceExercise.submitAnswer(answer, exercise);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  async resetAnswers({ commit }, { area, subCategory }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceExercise.resetAnswers(area, subCategory);
        const exercises = this.getters["exercises/getExercises"];
        const newExercises = exercises.exercises.data.map((exercise) => ({
          ...exercise,
          member_answers: [],
        }));
        commit(
          "exercises/SET_EXERCISES_DATA",
          {
            ...exercises,
            correct_answers: 0,
            incorrect_answers: 0,
            exercises: {
              ...exercises.exercises,
              data: newExercises,
            },
          },
          { root: true }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  async signedPDFUrl({ commit }, { area, subCategory }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceExercise.signedPDFUrl(area, subCategory);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  async downloadExercisesPDF({ commit }, { area, subCategory }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceExercise.downloadExercisesPDF(
          area,
          subCategory
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
