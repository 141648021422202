import store from "@/store/index";

async function gamification(to, from, next) {
	const isGamificationActive = store.getters["config/getIsGamificationActive"];
	const isAdmin = store.getters["config/getIsAdmin"];

	if (isAdmin) {
		return next();
	}

	if (isGamificationActive) {
		return next();
	} else {
		return next({ name: "Home" });
	}
}

async function gamificationConfig(to, from, next) {
	await store.dispatch("config/actionFetchSettings");
	const isAdmin = store.getters["config/getIsAdmin"];

	if (isAdmin) {
		return next();
	} else {
		return next({ name: "gamification" });
	}
}

async function gamificationRanking(to, from, next) {
	await store.dispatch("config/actionFetchSettings");
	const isGamificationActive = store.getters["config/getIsGamificationActive"];
	const isAdmin = store.getters["config/getIsAdmin"];

	if (isAdmin && isGamificationActive) {
		return next();
	} else {
		return next({ name: "gamification" });
	}
}

export { gamification, gamificationConfig, gamificationRanking };
