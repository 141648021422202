import MediasService from "@/services/resources/MediaService";
const serviceMedias = MediasService.build();

export default {
	async updateFileName(_, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await serviceMedias.postID(data);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchMedias(_, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await serviceMedias.read(data);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
};
