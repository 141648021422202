<template>
	<div
		class="arquive-thumb"
		:style="computedStyle"
	>
		<iframe
			v-if="isIframe"
			height="100%"
			width="100%"
			:src="src"
		>
		</iframe>
		<img
			v-else-if="isImage && src"
			:style="computedStyle"
			class="arquive-thumb__img"
			:src="src"
		/>
		<inline-svg
			v-else
			class="arquive-thumb__svg"
			:class="type === 'mpeg' || type == 'audio/mpeg' ? 'mpeg' : 'default'"
			:src="typeSVG"
			:width="isImageNotFounded || !type ? '124px' : '42px'"
			:height="isImageNotFounded || !type ? '124px' : '42px'"
		/>
	</div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
	name: "ArchiveThumb",
	components: {
		InlineSvg,
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		src: {
			type: String,
			default: null,
		},
		isIframe: {
			type: Boolean,
			default: false,
		},
		borderRadius: {
			type: String,
			default: "8px",
		},
	},
	computed: {
		computedStyle() {
			return {
				borderRadius: this.borderRadius,
			};
		},
		assetsUrl() {
			return process.env.VUE_ASSETS_URL;
		},
		isImage() {
			const isMpegWithImage = (this.type === "mpeg" || this.type === "audio/mpeg") && this.src;
			const isVideo = this.type === "video/mp4" || this.type === "video/quicktime";
			const isImage =
				this.type === "image" ||
				this.type === "image/jpeg" ||
				this.type === "image/jpg" ||
				this.type === "image/gif" ||
				this.type === "image/webp" ||
				this.type === "image/png";
			return isImage || isVideo || isMpegWithImage;
		},
		isImageNotFounded() {
			return this.isImage && !this.src;
		},
		typeSVG() {
			if (this.isImageNotFounded || !this.type) {
				return this.assetsUrl + `/images/file-types/image_not_found.svg`;
			}

			// Mapping of MIME types to icons
			const typeMap = {
				pdf: "PDF-LM",
				zip: "ZIP-LM",
				rar: "RAR-LM",
				xls: "XLS-LM",
				xlsx: "XLS-LM",
				ppt: "PPT-LM",
				pptx: "PPT-LM",
				doc: "DOC-LM",
				docx: "DOC-LM",
				csv: "XLS-LM",
				mp3: "MPEG-LM",
				mpeg: "MPEG-LM",
				"application/pdf": "PDF-LM",
				"application/zip": "ZIP-LM",
				"application/x-rar": "RAR-LM",
				"application/x-rar-compressed": "RAR-LM",
				"application/vnd.rar": "RAR-LM",
				"application/vnd.ms-excel": "XLS-LM",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLS-LM",
				"text/csv": "XLS-LM",
				"application/vnd.ms-powerpoint": "PPT-LM",
				"application/vnd.openxmlformats-officedocument.presentationml.presentation": "PPT-LM",
				"application/msword": "DOC-LM",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOC-LM",
				"audio/mpeg": "MPEG-LM",
			};

			// Check src extension
			if (this.src) {
				const extension = this.src.split(".").pop().toLowerCase();
				if (typeMap[extension]) {
					return this.assetsUrl + `/images/file-types/${typeMap[extension]}.svg`;
				}
			}

			// Check the MIME type of the file
			const mimeTypeIcon = typeMap[this.type.toLowerCase()];
			return this.assetsUrl + `/images/file-types/${mimeTypeIcon}.svg`;
		},
	},
};
</script>

<style lang="scss" scoped>
.arquive-thumb {
	background-color: var(--arquive-card-thumb-bg);
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	::v-deep .arquive-thumb__svg.mpeg path {
		stroke: var(--file-type-icon-color);
	}

	::v-deep .arquive-thumb__svg.default path {
		fill: var(--file-type-icon-color);
	}
}
</style>
