import store from "@/store"

globalThis.getYoutubeID = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : false;
}


globalThis.isActive = (value) => value === "on" || value === true;


function getUserInfo() {
  const me = store.getters["auth/getMe"]
  if (!me || !me.current) {
    return null
  }

  return {
    memberId: me.current?.currentMember?.id,
    siteId: me.current?.currentSite?.id
  }
}

/**
 * this method shold be called by GTM scripts in https://tagmanager.google.com
 * @returns {Promise<{memberId: string, siteId: string}>}
 */
globalThis.waitUserInfo = () => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const info = getUserInfo()
      if (info) {
        console.log('user info found', info)
        clearInterval(interval)
        resolve(info)
      }
    }, 1000)
  })
}

globalThis.waitForElement = (selector, callback) => {
  let isVisible = false;
  const observer = new MutationObserver(mutations => {
    if (document.querySelector(selector)) {
      if (!isVisible) {
        console.log('element is visible')
        waitUserInfo().then(callback)
      }
      isVisible = true;
    } else {
      console.log('element is not visible')
      isVisible = false;
    }
  });

  // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
  observer.observe(document.body, {
      childList: true,
      subtree: true
  });
}
