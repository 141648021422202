import Cookies from "js-cookie";

import actions from "@/store/modules/user/actions";
import getters from "@/store/modules/user/getters";
import mutations from "@/store/modules/user/mutations";
import { tryJsonParse } from "@/utils/json";

const state = {
  metasSite: tryJsonParse(Cookies.get("metasSite")) || {},
  certificateConfig: tryJsonParse(Cookies.get("certificateConfig")) || {},
  existConfigCert: false,
  hasPermission: false,
  // currentMember: (Cookies.get('currentMember')) ? JSON.parse(Cookies.get('currentMember')) : {},
  currentSite: tryJsonParse(Cookies.get("currentSite")) || {},
  hasEssayPermission: false,
};

export { state, getters, actions, mutations };
