import Rest from "@/services/Rest";

/**
 * @typedef {ExerciseService}
 */
export default class StudyScheduleService extends Rest {
  /**
   * @type {String}
   */
  static resource = "study-schedule";

  getSettings() {
    return this.get("/settings");
  }

  createSettings(startDate, hoursPerWeek, language, includeBasicMath) {
    return this.post("/settings", {
      start_date: startDate,
      hours_per_week: hoursPerWeek,
      language,
      include_basic_math: includeBasicMath,
    });
  }

  getStudySchedule(startWeek, endWeek) {
    return this.get(`?weeks_range=${startWeek}-${endWeek}`);
  }
}
