import Rest from '@/services/Rest';

/**
 * @typedef {CertificateExtensionService}
 */
export default class CertificateExtensionService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'certificate-extension';

    /**
     * Updates certificate status for a specific member on a specific site.
     * @param {number|string} siteId - The site ID.
     * @param {number|string} memberId - The member ID.
     * @returns {Promise<any>} - A promise that resolves with the response data.
     * @throws {Error} - If the parameters are invalid.
     */
    certificatesUpdate(siteId, memberId) {
        this.validateParams(siteId, memberId);
        return this.read(`${siteId}/list/${memberId}`);
    }

     /**
     * Issuance certificate for a specific member on a specific site.
     * @param {number|string} siteId - The site ID.
     * @param {number|string} memberId - The member ID.
     * @param {number|string} courseId - The course ID.
     * @returns {Promise<any>} - A promise that resolves with the response data.
     * @throws {Error} - If the parameters are invalid.
     */
    issuanceCertify(siteId, memberId, courseId) {
        this.validateParams(siteId, memberId, courseId);
        return this.post("/",{siteId, memberId, courseId})
    }

    /**
     * save certificate status for a specific member on a specific site.
     * @param {number|string} siteId - The site ID.
     * @param {number|string} memberId - The member ID.
     * @param {number|string} courseId - The course ID.
     * @returns {Promise<any>} - A promise that resolves with the response data.
     * @throws {Error} - If the parameters are invalid.
     */
    saveCertificate(siteId, memberId, courseId) {
        this.validateParams(siteId, memberId, courseId);
        return this.read(`${siteId}/certificate-save/${memberId}/course/${courseId}`);
    }

    validateParams(siteId, memberId,courseId = null) {
        if ((typeof siteId !== 'number' && typeof siteId !== 'string') || (typeof siteId === 'string' && siteId.trim() === '')) {
          throw new Error('Invalid siteId. It must be a number or a non-empty string.');
        }
  
        if ((typeof memberId !== 'number' && typeof memberId !== 'string') || (typeof memberId === 'string' && memberId.trim() === '')) {
          throw new Error('Invalid memberId. It must be a number or a non-empty string.');
        }

        if (courseId && (typeof courseId !== 'number' && typeof courseId !== 'string') || (typeof courseId === 'string' && courseId.trim() === '')) {
            throw new Error('Invalid courseId. It must be a number or a non-empty string.');
        }
    }

    shouldShowCertificate(certificate) {
        return !certificate.certificate_extension_config.auto_issuance && !certificate.viewed && certificate.status !=="emited";
    }
}