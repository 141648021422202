export default {
  getStartDate: (state) => state.startDate,
  getTotalWeeks: (state) => state.totalWeeks,
  getWeekNumber: (state) => state.weekNumber,
  getModules: (state) => state.modules,
  getHoursPerWeek: (state) => state.hoursPerWeek,
  getLanguage: (state) => state.language,
  getIncludeBasicMath: (state) => state.includeBasicMath,
  getCurrentWeekNumber: (state) => state.currentWeekNumber,
};
