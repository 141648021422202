export default {
  getPlans(state) {
    return state.plans;
  },
  getPlanById: (state) => (id) => {
    return state.plans.find(plan => plan.id === id);
  },
  getActivePlan(state) {
    return state.plans.find(plan => plan.activated === 1);
  }
}