<template>
	<div class="pagination-play">
		<IconSax
			class="pagination-play__prev"
			:class="{ 'pagination-play__prev--disabled': currentPage == 1 }"
			@click="$emit('onPrev')"
			size="1rem"
			name="arrow-left-2"
		/>
		<div
			v-for="(page, index) in visualisablePages"
			:key="index"
			@click="$emit('onSelectPage', page)"
			class="pagination-play__page"
			:class="{ 'pagination-play__page--active': currentPage == page }"
		>
			<TextPlay
				weight="semibold"
				:text="page"
				size="sm"
			/>
		</div>
		<IconSax
			class="pagination-play__next"
			:class="{ 'pagination-play__next--disabled': currentPage == Math.ceil(total / perPage) }"
			@click="$emit('onNext')"
			size="1rem"
			name="arrow-right-3"
		/>
	</div>
</template>

<script>
export default {
	name: "PaginationPlay",
	props: {
		total: {
			type: [Number, String],
			required: true,
		},
		currentPage: {
			type: [Number, String],
			required: true,
		},
		perPage: {
			type: [Number, String],
			required: true,
		},
		maxVisible: {
			type: [Number, String],
			default: 4,
		},
	},
	computed: {
		visualisablePages() {
			const pages = Math.ceil(this.total / this.perPage);
			const currentPage = parseInt(this.currentPage);
			const maxVisible = parseInt(this.maxVisible);
			const visualisablePages = [];

			if (pages <= maxVisible) {
				for (let i = 1; i <= pages; i++) {
					visualisablePages.push(i);
				}
			} else {
				const half = Math.floor(maxVisible / 2);
				let start = currentPage - half;
				let end = currentPage + half;

				if (start < 1) {
					start = 1;
					end = maxVisible;
				}

				if (end > pages) {
					end = pages;
					start = pages - maxVisible + 1;
				}

				for (let i = start; i <= end; i++) {
					visualisablePages.push(i);
				}
			}

			return visualisablePages;
		},
	},
};
</script>

<style lang="scss" scoped>
.pagination-play {
	display: flex;
	justify-content: center;
	gap: 6px;

	&__prev,
	&__next {
		display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
		cursor: pointer;
		border-radius: 4px;
    transition: all 0.1s ease;

		::v-deep svg path {
			fill: var(--pagination-btn-color);
		}

		&:hover {
			::v-deep svg path {
				fill: var(--pagination-btn-hover-color) !important;
			}

			background-color: var(--pagination-btn-hover-bg);
		}

		&--disabled {
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.5;
		}
	}

	&__page {
		color: var(--pagination-btn-color);
		background-color: transparent;
		border-radius: 4px;
    transition: all 0.1s ease;
		cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

		&:hover {
			color: var(--pagination-btn-hover-color);
			background-color: var(--pagination-btn-hover-bg);
		}

		&--active {
      color: var(--pagination-btn-hover-color);
			background-color: var(--pagination-btn-hover-bg);
		}
	}
}
</style>
