// mutations.js

function countSundays (startDate, endDate) {
  let count = 1;
  let date = startDate;

  while (date <= endDate) {
    if (date.getDay() === 1) {
      count++;
      date.setDate(date.getDate() + 7);
    }
    date.setDate(date.getDate() + 1);
  }

  return count;
}

export default {
  SET_STUDY_SCHEDULE (state, studySchedule) {
    state.modules = studySchedule.lessons;
    state.totalWeeks = studySchedule.totalWeeks;
    state.startDate = studySchedule.start_date;
  },
  SET_SETTINGS (state, settings) {
    state.startDate = settings.start_date;
    state.hoursPerWeek = settings.hours_per_week;
    state.language = settings.language;
    state.includeBasicMath = settings.include_basic_math === "0" ? false : true;
    state.totalWeeks = settings.total_weeks;
    state.weekNumber = countSundays(new Date(state.startDate), new Date());
    state.currentWeekNumber = state.weekNumber;
  },
  SET_CURRENT_WEEK_NUMBER (state, currentWeekNumber) {
    state.currentWeekNumber = currentWeekNumber;
  },
  SET_WEEK_NUMBER (state, weekNumber) {
    state.weekNumber = weekNumber;
  }
};
