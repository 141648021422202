// mutations.js
export default {
  SET_EXERCISES_AREA(state, response) {
    state.exercisesAreas = response;
  },
  SET_EXERCISES_DATA(state, response) {
    state.exercises = response;
  },
  SET_CATEGORIES_DATA(state, allCategories) {
    state.allCategories = allCategories;
  },
  SET_SELECTED_CATEGORY(state, selectedCategory) {
    state.selectedCategory = selectedCategory;
  },
  SET_SUB_CATEGORY(state, subCategory) {
    state.subCategory = subCategory;
  },
  SET_SELECTED_EXERCISE(state, selectedExercise) {
    state.selectedExercise = selectedExercise;
  },
};
