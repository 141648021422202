<template>
	<div class="avatar-play">
		<b-avatar
			:class="{ 'skeleton-play': skeleton }"
			:src="src"
			:text="computedName"
			:size="mapSize"
		/>
	</div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
		},
		size: {
			type: String,
			default: "xs",
			validator: function (value) {
				return ["xs", "sm", "base", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl"].indexOf(value) !== -1;
			},
		},
		customSize: {
			type: String,
		},
		name: {
			type: String,
			default: "",
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		mapSize() {
			if (this.customSize) return this.sizeStyle;

			let sizeMap = {
				xs: "1.25rem", // 20px
				sm: "1.563rem", // 25px
				base: "1.875rem", // 30px
				md: "1.875rem", // 30px
				lg: "2.188rem", // 35px
				xl: "2.5rem", // 40px
				"2xl": "2.813rem", // 45px
				"3xl": "3.125rem", // 50px
				"4xl": "3.438rem", // 55px
				"5xl": "3.75rem", // 60px
				"6xl": "4.063rem", // 65px
				"7xl": "4.375rem", // 70px
			};

			return sizeMap[this.size];
		},
		computedName() {
			if (this.src) return "";

			return this.name[0];
		},
	},
};
</script>

<style lang="scss">
.avatar-play {
	.b-avatar {
		background-color: var(--maincolor);

		.b-avatar-text span {
			font-weight: bold;
		}
	}
}
</style>
