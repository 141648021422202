import middleware from "@/router/middleware";
import authentication from "@/router/middleware/authentication";
import {
	gamification,
	gamificationConfig,
	gamificationRanking,
} from "@/router/middleware/gamification";
import unauthentication from "@/router/middleware/unauthentication";
import { adminAuth } from "../middleware/admin-routes";

export default [
	{
		path: "/cronograma/introducao",
		name: "study_schedule_intro",
		component: () => import("../../views/StudyScheduleIntro.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/cronograma/criacao",
		name: "study_schedule_creation",
		component: () => import("../../views/StudyScheduleCreation.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/cronograma",
		name: "study_schedule",
		component: () => import("../../views/StudySchedule.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/intensivao",
		name: "intensivao",
		component: () => import("../../views/Intensivao.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/evolucional",
		name: "evolucional",
		component: () => import("../../views/Evolucional.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/exercicios/:area",
		name: "exercises_area",
		component: () => import("../../views/ExercisesCategories.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/exercicios/:area/:subCategory",
		name: "exercise_list",
		component: () => import("../../views/ExercisesList.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/exercicios/:area/:subCategory/:exercise",
		name: "exercise",
		component: () => import("../../views/Exercise.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/",
		name: "Login",
		component: () => import(/* webpackChunkName: "login" */ "../../views/Login.vue"),
		beforeEnter: middleware([unauthentication]),
	},
	{
		path: "/set-password",
		name: "Resetar Senha",
		component: () => import("../../views/ResetPassword.vue"),
		beforeEnter: middleware([unauthentication]),
	},
	{
		path: "/home",
		name: "Home",
		component: () => import(/* webpackChunkName: "homeNova" */ "../../views/Home.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/my-courses",
		name: "MyCourses",
		component: () => import("../../views/MyCourses.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/select-site",
		name: "Selecionar Site",
		component: () => import("../../views/SelectSite.vue"),
		beforeEnter: middleware([unauthentication]),
	},
	// {
	//     path: '/cursos/:curso',
	//     name: 'curso',
	//     component: () =>
	//         import ('../../views/Player.vue'),
	//     beforeEnter: middleware([authentication]),
	//     children: [{
	//         path: ':modulo/',
	//         name: 'modulo',
	//         component: () =>
	//             import ('../../views/Player.vue'),
	//         beforeEnter: middleware([authentication]),
	//         children: [{
	//             path: ':aula/',
	//             name: 'aula',
	//             component: () =>
	//                 import ('../../views/Player.vue'),
	//             beforeEnter: middleware([authentication]),
	//         }]
	//     }]
	// },
	{
		path: "/curso/:courseId",
		name: "course",
		component: () => import("../../views/Lesson.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/curso/:courseId/modulo/:moduleId",
		name: "course-module",
		component: () => import("../../views/Lesson.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/curso/:courseId/modulo/:moduleId/aula/:lessonId",
		name: "course-lesson",
		component: () => import("../../views/Lesson.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/config_curso",
		name: "config_curso",
		component: () => import("../../views/CreateCourse.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
		children: [
			{
				path: ":curso",
				name: "config_curso_param",
				component: () => import("../../views/CreateCourse.vue"),
				beforeEnter: middleware([authentication, adminAuth]),
			},
		],
	},
	{
		path: "/gamification",
		name: "gamification",
		component: () => import("../../views/Gamification.vue"),
		beforeEnter: middleware([authentication, gamification]),
	},
	{
		path: "/gamification/config",
		name: "gamification-config",
		component: () => import("../../views/GamificationConfig.vue"),
		beforeEnter: middleware([authentication, gamificationConfig]),
	},
	{
		path: "/gamification/ranking",
		name: "gamification-ranking",
		component: () => import("../../views/GamificationRanking.vue"),
		beforeEnter: middleware([authentication, gamificationRanking]),
	},
	{
		path: "/config_curso/:courseId/modulo/:moduleId/aula/:lessonId/voomptube",
		name: "course-lesson-voomptube",
		component: () => import("../../views/ConfigCourseVoompTube.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/link/:index",
		name: "custom_link",
		component: () => import("../../views/CustomLink.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/config_curso/vendas/:curso/",
		name: "curso_vendas",
		component: () => import("../../views/CreateCourseSale.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/config_curso/:curso/module",
		name: "curso_module",
		component: () => import("../../views/CreateModule.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/config_curso/:curso/module/:module/lesson",
		name: "curso_module_lesson",
		component: () => import("../../views/CreateLesson.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/config_curso/class/:curso",
		name: "Sala de Aula",
		component: () => import("../../views/CreateClass.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/certificates",
		name: "Certificados",
		component: () => import("../../views/Certificates.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/essays",
		name: "essays",
		component: () => import("../../views/Essays.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/create-certificates/:curso",
		name: "Certificados_Criar",
		component: () => import("../../views/CreateCertificates.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/curso/venda/:id",
		name: "Vender Curso",
		component: () => import("../../views/SaleCourse.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/comunidade",
		name: "Comunidade",
		component: () => import("../../views/Community.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/cards",
		name: "Cards",
		component: () => import("../../views/Cards.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/banners",
		name: "Banner",
		component: () => import("../../views/CreateBanner.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/members",
		name: "Membros",
		component: () => import("../../views/Members.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/config_curso/emails/:curso",
		name: "E-mails",
		component: () => import("../../views/CreateEmail.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/minha-conta",
		name: "Minha conta",
		component: () => import("../../views/MyAccountStudent.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/ajuda",
		name: "Ajuda",
		component: () => import("../../views/Helper.vue"),
		beforeEnter: middleware([authentication]),
	},
	{
		path: "/configuracoes",
		name: "Meu Painel",
		component: () => import("../../views/MyPainel.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/not-project",
		name: "Sem Projeto",
		component: () => import("../../views/NotProject.vue"),
		beforeEnter: middleware([unauthentication]),
	},
	{
		path: "/config-domain",
		name: "Configurar Dominio",
		component: () => import("../../views/ConfigDomain.vue"),
		beforeEnter: middleware([unauthentication, adminAuth]),
	},
	// {
	//   path: "/login-to-google/:auth",
	//   name: "Login Google Auth",
	//   component: () => import("../../views/LoginGoogleOff.vue"),
	// },
	// {
	//   path: "/redirect-gd/:token/:site_id",
	//   name: "Login Google Token",
	//   component: () => import("../../views/Redirect.vue"),
	// },
	{
		path: "/report-problem",
		beforeEnter() {
			location.href = "https://app.pipefy.com/public/form/0xqJRdmJ";
		},
	},
	{
		path: "/assinatura",
		name: "Assinaturas",
		component: () => import("../../views/Signature.vue"),
		beforeEnter: middleware([authentication, adminAuth]),
	},
	{
		path: "/notifications",
		name: "Notifications",
		component: () => import("../../views/Notifications.vue"),
		beforeEnter: middleware([authentication]),
	},
];
