import Rest from "@/services/Rest";

/**
 * @typedef {ExerciseService}
 */
export default class ExerciseService extends Rest {
  /**
   * @type {String}
   */
  static resource = "exercises";

  // submit answer /{area_url}/{subject_url}/{exercise_url}
  submitAnswer(answer, exercise) {
    return this.post(
      `/${exercise.area_url}/${exercise.subject_url}/${exercise.url}`,
      {
        answer_id: answer,
      }
    );
  }

  resetAnswers(area, subCategory) {
    return this.destroy(`/${area}/${subCategory}/answers`);
  }

  downloadExercisesPDF(area, subCategory) {
    return this.get(`/${area}/${subCategory}/pdf`);
  }

  signedPDFUrl(area, subCategory) {
    return this.post(`/${area}/${subCategory}/signed-pdf-url`);
  }
}
