// state.js
export default {
  mobile: 0,
  layout: {
    /**
     * 1 - small screens; ex: mobile
     * 2 - medium screens or some pages; ex: tablets
     * 3 - large screens; ex: desktop
     */
    breakpoint: 3, // 1, 2, 3
    breakpointLock: undefined,
  },
  sidebar: {
    collapsible: false,
    visible: true,
    locked: false,
  },
};
