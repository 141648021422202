<template>
  <div
    class="grid-plans"
    :style="
      'grid-template-columns: repeat(' +
        (repeat ? repeat : plans.length) +
        ', 1fr);'
    "
  >
    <div
      class="plan"
      v-for="(plan, index) in plans"
      :key="plan.name + index"
      :class="{ recomended: index === 2 }"
    >
      <div class="mb-4 d-flex between">
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :stroke="changeColor(index)"
            d="M19 4C19 5.65685 14.9706 7 10 7C5.02944 7 1 5.65685 1 4M19 4C19 2.34315 14.9706 1 10 1C5.02944 1 1 2.34315 1 4M19 4V18C19 19.66 15 21 10 21C5 21 1 19.66 1 18V4M19 11C19 12.66 15 14 10 14C5 14 1 12.66 1 11"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <b-badge v-if="index === 2">{{ $t('settings.plans.txt3') }}</b-badge>
      </div>
      <h2 :class="{ 'text-white': index === 2 }">{{ plan.name }}</h2>
      <p class="text mb-4" :class="{ 'text-white': index === 2 }">
        {{ plan.description }}
      </p>
      <h1 :class="{ 'text-white': index === 2 }" v-if="plan.value == 'Grátis'">{{ $t('settings.plans.txt8') }}</h1>
      <h1 :class="{ 'text-white': index === 2 }" v-else>{{ plan.value }}</h1>

      <p class="text" :class="{ 'text-white': index === 2 }">{{ plan.time }}</p>
      <BaseButton
        v-if="plan.activated === 1"
        variant="link-info"
        :height="40"
        :font="13"
        class="w100 mb-4 mt-4"
        >{{ $t('settings.plans.txt4') }}</BaseButton
      >
      <BaseButton
        @click="openPlan(plan.product_id, index)"
        v-else-if="index === 2"
        variant="link-info"
        :height="40"
        :font="13"
        class="w100 mb-4 mt-4"
        >{{ $t('settings.plans.txt5') }}</BaseButton
      >
      <!-- <BaseButton
        @click="openSuport"
        v-else-if="index === 3"
        variant="primary"
        :height="40"
        :font="13"
        class="w100 mb-4 mt-4"
        >Chamar Suporte</BaseButton
      > -->
      <BaseButton
        @click="openPlan(plan.product_id, index)"
        v-else
        variant="primary"
        :height="40"
        :font="13"
        class="w100 mb-4 mt-4"
        >{{ $t('settings.plans.txt5') }}</BaseButton
      >
      <div class="d-flex details mt-4">
        <svg
          width="13"
          height="10"
          viewBox="0 0 13 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.7022 0.289387C12.6162 0.197689 12.5139 0.124912 12.4012 0.0752425C12.2885 0.0255733 12.1676 0 12.0455 0C11.9234 0 11.8025 0.0255733 11.6897 0.0752425C11.577 0.124912 11.4747 0.197689 11.3887 0.289387L4.49736 7.58766L1.60206 4.51574C1.51277 4.42447 1.40738 4.35275 1.29188 4.30462C1.17639 4.25649 1.05306 4.23291 0.92894 4.23516C0.804822 4.23741 0.68234 4.26559 0.568489 4.31793C0.454648 4.37017 0.351656 4.44579 0.265408 4.5402C0.179159 4.63461 0.111347 4.74604 0.0658268 4.86823C0.020316 4.99042 -0.00200475 5.12083 0.000141283 5.25212C0.00228732 5.38332 0.0288629 5.51294 0.078342 5.63328C0.12783 5.75371 0.199251 5.86259 0.288533 5.95387L3.84059 9.71061C3.92658 9.80228 4.0289 9.87507 4.14161 9.92477C4.25434 9.97437 4.37524 10 4.49736 10C4.61947 10 4.74035 9.97437 4.85311 9.92477C4.96577 9.87507 5.06808 9.80228 5.15411 9.71061L12.7022 1.72752C12.7961 1.63591 12.8711 1.52472 12.9223 1.40096C12.9735 1.27721 13 1.14356 13 1.00845C13 0.873345 12.9735 0.739707 12.9223 0.615949C12.8711 0.492192 12.7961 0.381006 12.7022 0.289387Z"
            fill="#002363"
          />
        </svg>
        <p
          class="text"
          v-if="Number.isInteger(plan.gb)"
          :class="{ 'text-white': index === 2 }"
        >
          {{ plan.storage_limit }} {{ $t('settings.plans.txt6') }}
        </p>
        <p class="text" v-else :class="{ 'text-white': index === 2 }">
          {{ plan.storage_limit }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SiteService from "@/services/resources/SiteService";
const serviceSite = SiteService.build();

export default {
  props: ["plans", "repeat"],
  data() {
    return {};
  },
  methods: {
    openPlan(e, index) {
      serviceSite.read("/owner").then((resp) => {
        //console.log("get owner", resp);
        // this.plans.splice(0, index);
        window.open(
          `https://pay.voompcreators.${process.env.VUE_URL_DOMAIN}/${e}?fn=${resp.name}&em=${resp.email}&ph=${resp.cellphone}&document=${resp.document_number}`,
          "_blank"
        );
      });
    },
    openSuport() {
      window.$crisp.push(["do", "chat:open"]);
      window.$crisp.push([
        "do",
        "message:send",
        ["text", this.$t('settings.plans.txt7')],  
      ]);
    },
    changeColor(e) {
      if (e === 0) {
        return "#FF9E75";
      } else if (e === 1) {
        return "#1061FF";
      } else if (e === 2) {
        return "#fff";
      } else {
        return "#000";
      }
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .grid-plans {
    display: block !important;
  }
}
.grid-plans {
  display: grid;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  gap: 12px;

  span.badge.badge-secondary {
    background: #ffffff54 !important;
    padding-top: 7px;
    height: 23px;
  }
  .between {
    justify-content: space-between;
  }
  .text-white {
    color: #fff !important;
  }
  .details {
    svg {
      margin-top: 5px;
      margin-right: 15px;
    }
  }
  .plan {
    padding: 30px;
    transition: 0.4s;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    justify-self: center;
  }
  .plan:hover {
    border: 1px solid #ededf0;
  }
  .recomended {
    background: var(--maincolor);
  }
  .w100 {
    width: 100% !important;
  }
  .progress {
    height: 5px;
  }
  .progress-step {
    background: transparent !important;
    border-left: 1px solid rgb(207, 207, 207);
  }
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: var(--fontcolor);
  }
  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: var(--fontcolor);
  }
  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #81858e;
  }
}
</style>
