// state.js
export default {
  params: {},
  course: {
    title: "",
  },
  modules: [],
  currentModuleOpened: null,
  coursePercentage: 0,
  currentLesson: {},
  currentModuleLessons: [],
  currentLessonComments: [],
  lessonComments: [],
  prevLesson: null,
  nextLesson: null,
  loading: {
    all: true,
    lesson: true,
    module: true,
    course: true,
    comments: true
  },
  submitting: {
    completion: false,
    comment: false,
  }
};
