// actions.js
export default {
  actionSetMobile({ commit }, mobile) {
    commit("SET_MOBILE", mobile);
  },
  actionToggleSidebar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  actionHideSidebar({ commit }) {
    commit("HIDE_SIDEBAR");
  },
  actionSetSidebarLocked({ commit }, value) {
    commit("SET_SIDEBAR_LOCKED", value)
  },
  actionRefreshLayout({ state, commit }) {
    if (state.mobile > 1140) {
      commit('SET_LAYOUT_BREAKPOINT', 3);
      commit('SET_SIDEBAR_COLLAPSABLE', false);
      document.documentElement.setAttribute("data-device", "desktop");
    }
    if (state.mobile <= 1140) {
      commit('SET_LAYOUT_BREAKPOINT', 2);
      commit('SET_SIDEBAR_COLLAPSABLE', true);
      document.documentElement.setAttribute("data-device", "tablet");
    }
    if (state.mobile <= 600) {
      commit('SET_LAYOUT_BREAKPOINT', 1);
      commit('SET_SIDEBAR_COLLAPSABLE', true);
      document.documentElement.setAttribute("data-device", "smartphone");
    }
  },
  actionSetSidebarCollapsable({ commit }, value) {{
    commit("SET_SIDEBAR_COLLAPSABLE", value);
  }}
};
