<template>
	<element
		:is="link ? 'router-link' : 'button'"
		:to="link"
		data-testid="button-play"
		:style="computedStyle"
		:class="[
			'custom-button',
			size,
			type,
			rounded ? 'rounded' : '',
			fullWidth ? 'fullWidth' : '',
			{ disabled: disabled },
			{ 'skeleton skeleton-dark skeleton-button': !loader && loading },
			$vnode.data.staticClass,
		]"
		@click="emitClick"
		:disabled="disabled"
		@mouseover="handleMouseHover(true)"
		@mouseleave="handleMouseHover(false)"
	>
		<IconSax
			v-if="icon"
			:name="icon"
			:type="iconType"
		/>

		<slot>
			<TextPlay
				:size="fontSize"
				:weight="fontWeight"
				:text="text"
				:color="color"
			/>
		</slot>

		<IconSax
			v-if="iconRight"
			:name="iconRight"
			:type="iconType"
		/>

    <b-spinner v-if="loading && loader === 'spinner'" small  variant="light" label="Loading"/>
	</element>
</template>

<script>
export default {
	data() {
		return {
			isHovering: false,
		};
	},
	props: {
		text: {
			type: String,
		},
		icon: {
			type: String,
		},
		iconRight: {
			type: String,
		},
		fontSize: {
			type: String,
			default: "sm",
		},
		fontWeight: {
			type: String,
			default: "semibold",
		},
		loading: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: "md",
		},
		minWidth: {
			type: String,
		},
		w: {
			type: String,
		},
		h: {
			type: String,
		},
		type: {
			// eslint-disable-next-line vue/require-prop-type-constructor
			type: "outline" | "normal" | "clear",
			default: "outline",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		link: {
			type: String,
		},
		iconType: {
			type: String,
			default: "outline",
		},
		color: {
			type: String,
		},
		borderColor: {
			type: String,
		},
		borderColorOnHover: {
			type: String,
		},
		bgColor: {
			type: String,
		},
		bgColorOnHover: {
			type: String,
		},
		p: {
			type: String,
		},
    loader: {
      type: String,
      default: null
    }
	},
	methods: {
		handleMouseHover(isHovering) {
			this.isHovering = isHovering;
		},
		emitClick() {
			if (!this.disabled) {
				this.$emit("click");
			}
		},
	},
	computed: {
		computedStyle() {
			return {
				minWidth: this.minWidth,
				width: this.w,
				height: this.h,
				color: this.color,
				padding: this.p,
				backgroundColor: this.isHovering ? this.bgColorOnHover : this.bgColor,
				borderColor: this.isHovering ? this.borderColorOnHover : this.borderColor,
			};
		},
	}
};
</script>

<style lang="scss" scoped>
.custom-button {
	cursor: pointer;
	display: inline-flex;
	height: 40px;
	padding: 0px 24px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	transition: background-color 0.3s;
	border: 1px solid var(--maincolor);
	border-radius: var(--default-radius);

	::v-deep svg {
		width: 1rem;
		height: 1rem;
	}

	&.normal {
		color: #ffffff;
		background-color: var(--maincolor);

		p,
		span,
		.text-play {
			color: #ffffff;
		}

		::v-deep svg path {
			fill: var(--neutral-white) !important;
		}

		&:hover {
			opacity: 0.9;

			::v-deep svg path {
				fill: var(--neutral-white) !important;
			}

			.isax,
			i {
				color: var(--neutral-white) !important;
			}
		}
	}

	&.outline {
		color: var(--maincolor);
		background: none;

		p,
		span,
		.text-play {
			color: var(--maincolor);
		}

		::v-deep svg path {
			fill: var(--maincolor) !important;
		}

		.isax,
		i {
			color: var(--maincolor) !important;
		}

		&:hover {
			p,
			span,
			.text-play {
				color: #ffffff;
			}

			::v-deep svg path {
				fill: #fff !important;
			}

			.isax,
			i {
				color: var(--neutral-white) !important;
			}
		}
	}

	&.clear {
		border: none;
	}

	&.rounded {
		border-radius: 999px !important;
	}

	&.gray {
		color: var(--text-color);
		background: none;
		border: 1px solid var(--neutral-gray-400);

		p,
		span,
		.text-play {
			color: var(--text-color);
		}

		::v-deep svg path {
			fill: var(--text-color);
		}

		.isax,
		i {
			color: var(--text-color) !important;
		}

		&:hover {
			p,
			span,
			.text-play {
				color: #ffffff;
			}

			border: 1px solid var(--maincolor);

			::v-deep svg path {
				fill: var(--neutral-white);
			}

			.isax,
			i {
				color: var(--neutral-white) !important;
			}
		}
	}

	&.feedback-error {
		color: var(--feedback-error-500);
		background: none;
		border: 1px solid var(--feedback-error-500);

		::v-deep svg path {
			fill: var(--feedback-error-500);
		}

		&:hover {
			border: 1px solid var(--feedback-error-500);
			background: var(--feedback-error-500);
			color: var(--neutral-white);
			
			::v-deep svg path {
				fill: var(--neutral-white) !important;
			}
		}
	}

	&.smm {
		font: var(--body-semibold-12);
		height: 32px;
		padding: 0px 12px;
	}

	&.sm {
		font: var(--body-semibold-14);
		height: 32px;
		padding: 0px 12px;
	}

	&.lg {
		font: var(--body-semibold-16);
		height: 48px;
		padding: 0px 12px;
	}

	&:hover {
		background-color: var(--maincolor);
		color: #ffffff;

		::v-deep svg path {
			fill: var(--neutral-white);
		}
	}

	&:active,
	&.active {
		background-color: var(--maincolor);
		color: #ffffff;

		::v-deep svg path {
			fill: var(--neutral-white);
		}
	}

	&.disabled {
		cursor: not-allowed;
		background-color: var(--neutral-gray-200);
		.text-play {
			color: var(--neutral-gray-600);
		}
	}

	&.normal.disabled {
		background-color: var(--neutral-gray-200);
		color: var(--neutral-gray-600);
		border-color: var(--neutral-gray-200);
	}

	&.outline.disabled {
		cursor: not-allowed;
		opacity: 0.9;
		background-color: transparent;
		color: var(--neutral-gray-600);
		border-color: var(--neutral-gray-200);
	}

	&.fullWidth {
		width: 100%;
		justify-content: flex-start;
		text-align: left;
	}

	&.cancel {
		border: none;
		background-color: var(--cancel-btn-bg);
		color: var(--cancel-btn-color);

		&:hover {
			opacity: 0.9;
		}
	}

	&.danger {
		border: none;
		background-color: var(--feedback-error-500);
		color: var(--neutral-white);
		::v-deep svg path {
			fill: var(--neutral-white) !important;
		}

		&:hover {
			opacity: 0.9;
		}
	}
}
</style>
