<template>
  <div class="root" :class="className">
    <div :class="'overlay-transparent ' + (searchCourse.length >= 3 ? 'active' : '')" @click="searchCourse = ''"></div>
    <div class="search">
      <IconSax size="1rem" name="search-normal" class="search-img" @click="fetch" />
      <input ref="input" autocomplete="one-time-code" v-model="searchCourse" v-on:keyup="handleInput" v-on:keydown.enter="fetch"
        class="search-input" :placeholder="$t('search_modal.label')" />
      <a v-if="searchCourse.length > 1 || isMobile" class="close" @click="clear">
        <IconSax class="close__icon" name="add" color="var(--subtitle-color)" />
      </a>
    </div>
    <div v-if="searchCourse.length >= 3 || isMobile" :class="{
      'search-items': true,
      'search-items--mobile': isMobile
    }">
      <div v-if="loading && searchCourse.length >= 1" class="items-center justify-center w-full flex">
        <Loading />
      </div>
      <div v-else-if="isEmpty && searchCourse.length >= 1" class="items-center justify-center w-full flex flex-col gap-3 p-8">
        <IconSax name="search-normal" size="2.5rem" color="var(--maincolor)" />
        <TextPlay color="var(--title-color)" class="text-center" weight="bold">
          Não encontramos resultados para: <br />
          <TextPlay :text="searchCourse" color="var(--maincolor)" weight="bold" />
        </TextPlay>
        <TextPlay color="var(--title-color)" class=text-center>
          Para facilitar a sua pesquisa, procure sempre digitar corretamente o termo da busca ou utilizar
          palavras-chaves.
        </TextPlay>
      </div>
      <TabsPlay v-else-if="searchCourse.length >= 1" enableAllTabs :tabs="tabs" v-model="selectedTab" class="tabs" :textSize="isMobile ? 'xs' : 'base'">
        <template slot="0">
          <SearchList :items="courses" class="search-list" @item-click="searchCourse = ''" />
        </template>
        <template slot="1">
          <SearchList :items="modules" class="search-list" @item-click="searchCourse = ''" />
        </template>
        <template slot="2">
          <SearchList :items="lessons" class="search-list" @item-click="searchCourse = ''" />
        </template>
      </TabsPlay>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SearchList from "./SearchList.vue";
import notify from "@/services/libs/notificacao";

import CourseService from "@/services/resources/CourseService";
import SearchService from "@/services/resources/SearchService";
import MetaService from "@/services/resources/MetaService";
import _ from 'lodash';

const serviceCourse = CourseService.build();
const serviceSearch = SearchService.build();
const serviceMeta = MetaService.build();

export default {
  props: ["isMobile", "permission", "className"],
  components: { SearchList },
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
      getTheme: 'config/getTheme',
      getMe: "auth/getMe",
    }),
    courses() {
      return this.getAllSearch["App\\Models\\Course"] || []
    },
    modules() {
      return this.getAllSearch["App\\Models\\Module"]?.filter(m => m.course !== null) || []
    },
    lessons() {
      return this.getAllSearch["App\\Models\\Lesson"] || []
    },
    isEmpty() {
      return this.courses.length === 0 && this.modules.length === 0 && this.lessons.length === 0;
    },
    tabs() {
      return [
        { id: 'course', icon: 'book', label: this.$t('search_modal.courses') + ` (${this.courses.length})` },
        { id: 'module', icon: 'element-3', label: this.$t('search_modal.modules') + ` (${this.modules.length})` },
        { id: 'lesson', icon: 'book-1', label: this.$t('search_modal.lessons') + ` (${this.lessons.length})` },
      ];
    }
  },

  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.openSearch = false;
      } else if (evt.keyCode === 40) {
        that.getNextItem();
      } else if (evt.keyCode === 38) {
        that.getLastItem();
      }
    });
  },
  data() {
    return {
      openSearch: false,
      searchCourse: "",
      getAllSearch: {},
      homeVertical: "16:9",
      ratingHome: true,
      selectedItem: null,
      typeSelectedItem: "",
      loading: true,
      selectedTab: 1
    };
  },
  methods: {
    handleInput() {
      this.loading = true;
      this.lazyFetch();
    },
    clear() {
      if (!this.searchCourse) {
        this.$emit("clear");
      }
      this.searchCourse = "";
      this.$refs.input.focus();
    },
    openModalBuy(data) {
      this.openSearch = false;
      if (data.is_liberated === false) {
        notify("erro", this.$t("search_modal.error.liberation"));
      } else {
        notify("erro", this.$t("search_modal.error.access"));
        let data = data.id + "/meta?keys[]=tipo_venda";
        serviceCourse
          .read(data)
          .then((resp) => {
            if (resp.tipo_venda === "tela") {
              this.$router.push("/curso/venda/" + data.id);
            } else {
              this.$root.$emit("modal-buy-course", data);
              this.$root.$emit("bv::show::modal", "modal-buy", "#btnShow");
            }
          })
          .catch((err) => console.log(err));
      }
    },
    getHomeVertical() {
      if (this.getSettings.home_vertical === false || this.getSettings.home_vertical !== null) {
        this.homeVertical = "16:9";
      } else {
        this.homeVertical = "5:7";
      }

    },
    getEvent() {
      if (this.$route.name === 'course-lesson' || this.$route.name === 'course-module' || this.$route.name === 'course') {
        this.$router.go()
      }
    },
    getRating() {
      serviceMeta
        .search("keys[]=rating_home")
        .then((resp) => {
          if (resp.rating_home === null || resp.rating_home === "on") {
            this.ratingHome = true;
          } else {
            this.ratingHome = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.ratingHome = true;
        });
    },
    lazyFetch: _.debounce(function () {
      this.fetch();
    }, 500),
    async fetch() {
      this.loading = true;
      try {
        if (this.searchCourse.length < 3) {
          this.getAllSearch = {};
          return
        }
        this.getAllSearch = await serviceSearch.search2("search_string=" + this.searchCourse)
      } finally {
        this.loading = false;
        if (this.courses.length > 0) {
          this.selectedTab = 0;
          return
        }
        if (this.modules.length > 0) {
          this.selectedTab = 1;
          return
        }
        if (this.lessons.length > 0) {
          this.selectedTab = 2;
          return
        }
      }
    },
  },
  mounted() {
    this.getHomeVertical();
    this.$root.$on("openSearch", () => {
      this.openSearch = true;
    });
    if (this.isMobile) {
      this.$refs.input.focus();
    }
  },
};
</script>

<style scoped lang="scss">
.overlay-transparent {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: none;

  &.active {
    display: block;
  }
}

.root {
  position: relative;
  flex: 1;
  max-width: 31rem;
}

.search {
  position: relative;
  display: flex;
  width: 100%;
  height: 2.5rem;
  padding: 0rem 0.625rem;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background-color: var(--search-background);
  border: 1px solid var(--search-background);
  transition: border 0.3s ease;
  z-index: 1002;

  &:hover {
    border: 1px solid var(--neutral-gray-500);
  }
}

.search-input {
  @include input-unstyle;
  flex: 1;
  color: var(--search-color);
  z-index: 1001;
}

.search-img {
  flex-shrink: 0;
  cursor: pointer;
  z-index: 1001;
}

.search-items {
  // @include animation-opacity;
  position: absolute;
  top: 2.8rem;
  z-index: 1001;
  display: flex;
  width: 31rem;
  height: auto;
  padding: 1rem 0px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 2.5rem;
  flex-shrink: 0;

  border-radius: 0.3125rem;
  border: 1px solid var(--bordercolor, #404043);
  background: var(--search-background-list, #fff);
  /* Shadow D */
  box-shadow: 0px 1px 4px 0px #00000029;
  max-height: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &--mobile {
    border: none;
    background: var(--backgroundcolor);
    left: 0px;
    right: 0px;
    top: 3.5rem;
    height: calc(100vh - 3.5rem);
    max-height: calc(100vh - 3.5rem);
    box-shadow: none;
    width: 100vw;
  }
}

.spaceCourse {
  padding: 4px 0px;
  cursor: pointer;
}

.spaceCourse.selectedItem {
  background: #e8e8e8;
}

.search-items-content {
  height: 100%;
  width: 100%;
  transition: 0.3s;
  overflow: auto;
  border-radius: 3px;
}

.search-group-title {
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  color: var(--opposite-color);
  margin-top: 25px;
  margin-bottom: 0;
  padding: 0;
}

.removeLink {
  text-decoration: none;
}

.search-items-content::-webkit-scrollbar {
  background-color: #fff;
  width: 2px;
}

.search-items-content::-webkit-scrollbar-track {
  background-color: #c7c7c7;
}

.search-items-content::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 31px;
}

.search-items-content::-webkit-scrollbar-button {
  display: none;
}

.spaceCourse {
  a {
    height: 100%;
  }
}

.search-content-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.p-8 {
  padding: 3rem;
}

.text-center {
  text-align: center;
}

.close {
  cursor: pointer;
}

.close__icon {
  rotate: 45deg;
}

::v-deep .tabs {
  width: 100%;

  & .tabs-play__tabs {
    padding: 0px 1rem;
  }

  & .tab__module svg {
    rotate: 45deg;
  }

}

</style>
