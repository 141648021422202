import StudyScheduleService from "@/services/resources/StudyScheduleService";
const serviceStudySchedule = StudyScheduleService.build();

export default {
  async actionFetchSettings({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceStudySchedule.getSettings();
        commit("studySchedule/SET_SETTINGS", response, { root: true });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async actionCreateSettings({ commit }, { startDate, hoursPerWeek, language, includeBasicMath }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceStudySchedule.createSettings(startDate, hoursPerWeek, language, includeBasicMath);
        commit("studySchedule/SET_SETTINGS", response, { root: true });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async actionFetchStudySchedule({ commit }, { startWeek, endWeek }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceStudySchedule.getStudySchedule(startWeek, endWeek);
        commit("studySchedule/SET_STUDY_SCHEDULE", response, { root: true });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  actionSetWeekNumber({ commit }, weekNumber) {
    commit("studySchedule/SET_WEEK_NUMBER", weekNumber, { root: true });
    return weekNumber;
  },
};
