<template>
	<div class="select-form-play p-0">
		<slot>
			<div class="d-flex flex-column p-0">
				<TextPlay
					v-if="label"
					class="mb-2"
					tag="label"
					:text="label"
					:color="labelColor"
					size="sm"
					:for="id"
					weight="semibold"
				/>
				<SelectPlay
					:placeholder="placeholder"
					:options="options"
					:id="id"
					@input="$emit('input', $event)"
					:value="value"
					:name="name"
				/>
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		label: String,
		name: String,
		placeholder: String,
		value: [String, Number],
		id: String,
		labelColor: {
			type: String,
			default: "var(--fontcolor)",
		},
		options: {
			type: Array,
			required: true,
		},
	},
};
</script>
