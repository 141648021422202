const scrollToElement = (elementId, layoutId = '.layout-page__overflow', intervalTime = 0, scrollTime = 0) => {
  const getCurrentElement = () => {
    return document.getElementById(elementId);
  }

  const scrollEvent = () => {
      getCurrentElement()?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  setTimeout(scrollEvent, scrollTime);

  if(getCurrentElement() === null) {
    let layoutPage = document.querySelector(layoutId);
    layoutPage.scrollTo(0, layoutPage.scrollHeight);

    let intervalId = setInterval(() => {
      let accordionById = getCurrentElement()
      if (accordionById) {
        accordionById.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        clearInterval(intervalId);
      } else {
          let layoutPage = document.querySelector(layoutId);
          layoutPage.scrollTo(0, layoutPage.scrollHeight);
      }
    }, intervalTime);

    // Adiciona um ouvinte de evento que se auto-remove após ser acionado
    layoutPage.addEventListener('scroll', function handler() {
      clearInterval(intervalId);
      layoutPage.removeEventListener('scroll', handler);
    });
  }
}

export default scrollToElement;