// getters.js

import { NO_CATEGORY } from "@/utils/const";

const uniqueIds = (courses) => {
  if (courses) {
    const categoriasUnicas = {};

    courses.forEach((curso) => {
      const category = curso.category || NO_CATEGORY

      const id = category.id;
      if (!categoriasUnicas[id]) {
        categoriasUnicas[id] = Object.assign({}, category)
      }
    });

    const categoriasUnicasArray = Object.values(categoriasUnicas);

    return categoriasUnicasArray.sort((a, b) => a.order - b.order);
  }
};

export default {
  getHomeData: (state) => state.homeData,
  getAllCategories: (state) => uniqueIds(state?.homeData?.courses),
  getOpenedAccordionCategories: (state) => state.openedAccordionCategories,
  getModalInfoCourse: (state) => state.modalInfoCourse,
  getModalBuyCourse: (state) => state.modalBuyCourse,
  getModalFirstAccess: (state) => state.modalFirstAccess,
  getModalTermsOfUse: (state) => state.modalTermsOfUse,
  getRearrangeCourse: (state) => state.rearrange,
  getCoursesList: (state) => state?.allCourses,
  getLoadingModule: (state) => state.loadingModule,
  getEmptyState: (state) => state.emptyState
};
