<template>
    <div class="empty-logo" @click="openUrlAction('/configuracoes?page=custom')">
        <div class="empty-logo__container">
            <p class="add-logo-icon"><IconSax name="gallery-add" size="32px" /></p>
            <p class="add-logo">{{ $t('empty_state.add_logo') }}</p>
            <p class="add-logo-text">{{ $t('empty_state.logo_recommended_size') }}</p>
            <p class="add-logo-text">{{ $t('empty_state.logo_size') }}</p>
            <p class="add-logo-text">{{ $t('empty_state.logo_title') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        openUrlAction(url) {
            this.$router.push(url);
        }
    }
}

</script>

<style lang="scss" scoped>
.empty-logo{
    margin-top: 47px;
    cursor: pointer;
    display: flex;
    width: 190px;
    height: 165px;
    padding: 24px 5px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: var(--empty-state-border);
    background: var(--empty-state-backgorund);
}


.add-logo-icon{
    display: flex;
    justify-content: space-around;
    margin-bottom: 8px;
        ::v-deep .svg-inner path{
        fill: #9E9E9E;

        [data-theme="dark"] & {
            fill: #fff;
        }
    }
}

p{
    margin: 0;
}

.add-logo{
    text-align: center;
    margin-bottom: 8px;
    color: #141416;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.6px;

    [data-theme="dark"] & {
    color: #fff;
    }
}

.add-logo-text{
    color:  #878787;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;

    [data-theme="dark"] & {
        color: #9E9E9E;
    }
}
</style>