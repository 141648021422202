export function convertTrigger(trigger) {
  let name_trigger = "";
  switch (trigger) {

      case "classEndDate":{
        name_trigger = "Data de Conclusão de Aula";
        break
      }

      case "inactivityTime":{
        name_trigger = "Tempo de Inatividade";
        break
      }

      case "moduleEndDate":{
        name_trigger = "Data de Conclusão do Módulo";
        break
      }

      case "courseEndDate":{
        name_trigger = "Data de Conclusão do Curso";
        break
      }

      case "certificateDate":{
        name_trigger = "Emissão do Certificado";
        break
      }

      case "courseProgress":{
        name_trigger = "Progresso do Curso";
        break
      }

      case "1DayOfInactivity":
        name_trigger = "1 Dia";
        break;
      case "5DaysOfInactivity":
        name_trigger = "5 Dias";
        break;
      case "10DaysOfInactivity":
        name_trigger = "10 Dias";
        break;
      case "20DaysOfInactivity":
        name_trigger = "20 Dias";
        break;
      case "30DaysOfInactivity":
        name_trigger = "30 Dias";
        break;
      case "60DaysOfInactivity":
        name_trigger = "60 Dias";
        break;
      case "90DaysOfInactivity":
        name_trigger = "90 Dias";
        break;
      case "25Progress":
        name_trigger = "Concluiu 25%";
        break;
      case "50Progress":
        name_trigger = "Concluiu 50%";
        break;
      case "75Progress":
        name_trigger = "Concluiu 75%";
        break;
      case "100Progress":
        name_trigger = "Concluiu 100%";
        break;
        

      
    default: {
      name_trigger = trigger;
      break;
    }
  }
  return name_trigger;
}

export function getExtensionFileFromURL(url) {
  return url.split('.').pop();
}
