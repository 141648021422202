import Rest from '@/services/Rest';

/**
 * @typedef {NotificationService}
 */
export default class NotificationService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'notifications';

    markAsRead(id) {
        return this.post(`/${id}/read`);
    }

    markAllAsRead(page) {
        return this.post('/markAllAsRead?page='+page);
    }

    getAllNotifications() {
        return this.get("");
    }

    listNotificationPolling(page){
        return this.get("/listNotificationPolling?page="+page);
    }

    listNotifications(page) {
        return this.get("/list?page="+page);
    }
}