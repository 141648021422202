import MemberService from "@/services/resources/MemberService";
import SignatureService from "@/services/resources/SignatureService";

const serviceMember = MemberService.build();
const serviceSignature = SignatureService.build();

export default {
	async fetchCombosAndCourseClasses({ commit }, memberId) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await serviceMember.read(`/${memberId}/course-class-and-signatures`);

				commit("SET_COMBOS", response.signatures);
				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchDeactivetedCombosAndCourseClasses({ commit }, memberId) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await serviceMember.read(`/${memberId}/course-class-and-signatures?inactive=true`);

				commit("SET_DEACTIVATED_COMBOS", response.signatures);
				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async fetchCombos({ commit }, memberId) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await serviceMember.read(`/${memberId}/course-class-and-signatures`);
				commit("SET_COMBOS", response.signatures);
				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async reactivateCombo({ commit }, pivotId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = {
					id: "/restore-signature",
					signature_student_id: pivotId,
				};

				const response = await serviceMember.postID(data);
				commit("REACTIVATE_COMBO", pivotId);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},
	async deactivateCombo({ commit }, pivotId) {
		return new Promise(async (resolve, reject) => {
			try {
				const data = {
					id: "/remove-signature",
					signature_student_id: pivotId,
				};

				const response = await serviceMember.postID(data);
				commit("DEACTIVATE_COMBO", pivotId);

				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	},

	async actionDeleteSignature({ commit }, signatureId) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await serviceSignature.destroy(signatureId);
				resolve(response);
			} catch (error) {
				reject(error);
			}
		});
	}
};
