<template>
  <div>
    <section class="root">
      <nav class="nav">
        <!-- <div class="flex-row gap-1" @click="$router.go(-1)">
          <IconSax name="arrow-left" />
          <span class="font-bold">Voltar</span>
        </div>
        <span class="title">Configurações do Voomp Tube</span> -->
        <section class="accordion" :class="{ open: active == 'link' }">
          <div class="accordion-title" @click="setActive('link')">
            Link do vídeo desmonetizado no Youtube
          </div>
          <div v-if="active == 'link'" class="gap-1">
            <b-form-input 
              v-model="url" 
              name="url"
              placeholder="Link do seu vídeo no youtube" 
              v-validate="'required'" 
              class="text-sm"
              style="color: black !important"
            />
            <span>
              Coloque seu vídeo no Youtube como ”não listado” ou ”público”. Os gráficos de retenção e relatórios
              disponíveis estão somente em seu canal do Youtube para a sua segurança e dos seus dados.
            </span>
            <Divider margin="0" mt="1" />
          </div>
        </section>
        <section class="accordion" :class="{ open: active == 'theme' }">
          <div class="accordion-title" @click="setActive('theme')">
            Aparência do player
          </div>
          <div v-if="active == 'theme'" >
            <div class="player-themes">
              <div 
                @click="setTheme('default')"
                :class="{
                  'player-theme': true,
                  'selected': theme == 'default'
                }"
              >
                <div class="player-theme__box player-theme__box--default flex-row justify-center align-center">
                  <IconSax name="play-circle" type="bold" size="2.5rem" color="white" />
                </div>
                <span class="font-bold">Tema Padrão</span>
              </div>
              <div 
                @click="setTheme('platform')"
                :class="{
                  'player-theme': true,
                  'selected': theme == 'platform'
                }"
              >
                <div class="player-theme__box player-theme__box--platform flex-row justify-center align-center">
                  <IconSax name="play-circle" type="bold" size="2.5rem" color="white" />
                </div>
                <span class="font-bold">Tema da plataforma</span>
              </div>
            </div>
            <Divider margin="0" mt="1" />
          </div>
        </section>
        <section class="accordion" :class="{ open: active == 'start' }">
          <div class="accordion-title" @click="setActive('start')">
            Tela de Inicio
          </div>
          <div v-if="active == 'start'">
            <p>
              Selecione uma imagem da sua biblioteca de mídia para que seja exibida como capa do vídeo enquanto seu aluno não clicar para iniciar o vídeo na tela da aula. Dimensão recomendada: 1280x720px ou proporcional.
            </p>
            <ButtonPlay type="normal" size="sm" @click="showModalUploadMedia('beginURL')">
              Biblioteca de mídia
            </ButtonPlay>
            <Divider margin="0" mt="1" />
          </div>
        </section>
        <section class="accordion" :class="{ open: active == 'pause' }">
          <div class="accordion-title" @click="setActive('pause')">
            Tela de Pausa
          </div>
          <div v-if="active == 'pause'">
            <p>
              Selecione uma imagem da sua biblioteca de mídia para que seja exibida como capa do vídeo quando seu aluno pausar o vídeo na tela da aula. Dimensão recomendada: 1280x720px ou proporcional.
            </p>
            <ButtonPlay type="normal" size="sm" @click="showModalUploadMedia('pauseURL')">
              Biblioteca de mídia
            </ButtonPlay>
            <Divider margin="0" mt="1" />
          </div>
        </section>
        <section class="accordion" :class="{ open: active == 'continue' }">
          <div class="accordion-title" @click="setActive('continue')">
            Tela de Continuação
          </div>
          <div v-if="active == 'continue'">
            <p>Toda vez que seu aluno trocar de aba, ao voltar para a Voomp Play a tela de continuação será exibida para ele escolher entre continuar da onde parou ou começar o vídeo do início.</p>
            <b-form-checkbox
              v-model="carryOn"
              name="carryOn"
              switch>
              <span style="color: black; font-weight: normal;">Habilitar tela de continuação</span>
            </b-form-checkbox>
          </div>
        </section>
      </nav>
      <article class="content flex-col items-center gap-2">
        <div class="content-video">
          <div class="content-video__empty" v-if="!url && !error" >
            <span class="text-white font-bold">Informe o link do seu video no Youtube no campo ao lado.</span>
          </div>
          <div class="content-video__error" v-if="error" >
            <span class="text-white font-bold">
              {{ error }}
            </span>
          </div>
          <VoompTube 
            v-if="url && !error" 
            :url="url"
            :theme="theme"
            :beginURL="beginURL"
            :pauseURL="pauseURL"
            :carryOn="carryOn"
            :preview="preview"
          />
        </div>
        <ButtonPlay :disabled="!url" type="normal" @click="submit">
          <b>Salvar e inserir vídeo na aula</b>
        </ButtonPlay>
      </article>
    </section>
    <ModalUpload 
      id="modal-media-image" 
      :z-index="999" 
      @onMediaSelected="onMediaSelected"
      type="image"
    />
  </div>
</template>
<script>
import { decode_vsl, encode_vsl } from '@/components/players/voomptube/VSLTube';


export default {
  props: {
    data: {
      type: String,
      handler (data) {
        this.load(data);
      }
    }
  },
  data() {
    return {
      mediaProperty: "",
      url: '',
      theme: 'default',
      carryOn: false,
      beginURL: undefined,
      pauseURL: undefined,
      active: "link",
      preview: "start",
      error: "",
    }
  },
  mounted() {
    this.load(this.data);
  },
  methods: {
    load(data) {
      if (!data) {
        return
      }
      const decoded = decode_vsl(data)
      this.url = decoded.url
      this.theme = decoded.theme
      this.beginURL = decoded.beginURL
      this.pauseURL = decoded.pauseURL
      this.carryOn = decoded.carryOn
    },
    async setActive(active = "link") {
      if (this.active == active) {
        this.active = ''
        return;
      }
      switch (active) {
        case "link", "theme", "start": {
          this.preview = "start"
          break;
        }
        case "pause": {
          this.preview = "pause"
          break;
        }
        case "continue": {
          this.preview = "continue"
          break;
        }
      }
      this.active = active;
    },
    setTheme(theme = 'default') {
      this.theme = theme 
    },
    showModalUploadMedia(mediaProperty) {
      this.mediaProperty = mediaProperty
      this.$bvModal.show("modal-media-image")
    },
    onMediaSelected(media) {
      this[this.mediaProperty] = media.url
    },
    submit() {
      const youtube_id = getYoutubeID(this.url);
      const data = {
        type: "voomptube",
        media: encode_vsl({
          url: this.url,
          theme: this.theme,
          beginURL: this.beginURL,
          pauseURL: this.pauseURL,
          carryOn: this.carryOn,
        }),
        thumbnail: this.beginURL || "https://img.youtube.com/vi/" + youtube_id + "/sddefault.jpg"
      };
      this.$emit("onConfigured", data);
    }
  },
  watch: {
    url() {
      if (this.url && !getYoutubeID(this.url)) {
        this.error = "Informe um link válido do Youtube no campo ao lado.";
      } else {
        this.error = "";
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.root {
  display: flex;
  flex-direction: row;
  height: 630px;
  /*height: 100vh;
  width: 100vw;*/
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: var(--backgroundcolor);
  /* box-shadow: 2px 0px 15px 0px #00000014; */
  flex: 0.3;
  min-width: 400px;
  overflow-y: auto;
  gap: 0.5rem;
  flex-wrap: nowrap;
  background: var(--neutral-gray-80);
}

.title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 1rem 0;
}

.accordion {
  position: relative;
  display: flex;
  flex-direction: column;
}

.accordion-title {
  all: unset;
  padding: 1rem 0;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;

  &::after {
    content: '';
    background: url('/svg/iconsax/outline/arrow-down-1.svg');
    background-size: cover;
    margin-left: .75em;
    transition: 0.2s;
    width: 20px;
    position: absolute;
    height: 20px;
    right: 0;
  }

  .accordion.open > &::after {
    transform: rotate(180deg);
  }
}

.text-tips {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.player-themes {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.player-theme {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.2rem;

  &.selected {
    background: #E2E2E2;
  }
}

.player-theme__box {
  width: 150px;
  height: 90px;
  top: 209px;
  left: 204px;
  border-radius: 0.2rem;

  &--default {
    background-color: #333;
  }

  &--platform {
    background-color: var(--maincolor);
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--neutral-gray-100);
  align-items: center;
  justify-content: center;
}


.text-sm {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.content-video {
  width: 745px;
  height: 417px;
  background: #1E1E20;
}

.content-video__empty {
  margin: 25%;
}

.content-video__error {
  margin: 25%;
  background-color: red;
}

.text-white {
  color: white;
}


</style>
