export default {
  getCourse: (state) => state.course,
  getCoursePercentage: (state) => state.coursePercentage,
  getModules: (state) => state.modules,
  getCurrentModuleLessons: (state) => state.currentModuleLessons,
  getCurrentLesson: (state) => state.currentLesson,
  getParams: (state) => state.params,
  getLoading: (state) => state.loading,
  getSubmitting: (state) => state.submitting,
  getPrevLesson: (state) => state.prevLesson,
  getNextLesson: (state) => state.nextLesson,
  getLessonComments: (state) => state.lessonComments,
  getCurrentModuleOpened: (state) => state.currentModuleOpened,
};
