<template>
  <b-modal id="modal-select-painel" hide-footer size="lg">
    <div class="spaceModal">
      <div class="titleModal">Meus Projetos</div>
      <div class="header">
        <button class="newPainel" @click="openNew">Novo Projeto</button>
      </div>
      <div class="spaceSelectPainel">
        <div class="spacePainels" v-for="painel in Painels" :key="painel.id" @click="selectedPainel(painel)">

          <div class="flexData">
            <div class="spaceImg" v-if="painel.logo === null">
              <img src="@/assets/icons/logo-fake.svg" alt="logo" />
            </div>
            <div class="spaceImg" v-else>
              <img :src="painel.logo" alt="logo" />
            </div>
            <div>
              <div class="title">{{ painel.name }}</div>
              <div class="descricao">{{ painel.description }}</div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      Painels: [],
      permission: false,
    };
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
    }),
  },
  methods: {
    openNew() {
      this.$root.$emit("bv::hide::modal", "modal-select-painel", "#btnShow");
      this.$root.$emit("bv::show::modal", "modal-new-painel", "#btnShow");
    },
    async selectedPainel(data) {
     
      data = {
        siteId: data.id,
        siteName: data.name,
        ...data,
        data: [],
        current: this.$route.path
      }
      
      await this.$store.dispatch("auth/blogSelected", data);
    },
    getPermission() {
      let permission = Cookies.get("access_level");
      if (permission === "admin" || permission === "owner") {
        this.permission = true;
      } else {
        this.permission = false;
      }
    },
    getPainel() {
      Cookies.set("memberAuth", this.getMe.member.Auth, { expires: 365 });
      this.Painels = this.getMe.member.sites;
    },
    logout() {
      this.$store.dispatch("auth/logoutRequest").catch(function () { });
    },
  },
  mounted() {
    this.getPainel();
    this.getPermission();
    this.$root.$on("createsite", (data) => {
      this.Painels = data;
      Cookies.set("blogsClub", data, { expires: 365 });
    });
    this.$root.$on("getAllPainels", (data) => {
      this.getPainel();
      this.getPermission();
    });
  },
};
</script>
<style lang="scss">
#modal-select-painel {
  .header {
    display: flex;
    justify-content: flex-end;
  }

  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    height: 55px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 25px;
    margin-top: -35px;
    margin-bottom: 20px;
    width: max-content;
  }

  .modal-header {
    border: none !important;
  }

  .spaceModal {
    padding: 5px ​30px 0px 30px;

    .logout {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ff0c37;
      margin-top: 10px;
      cursor: pointer;
    }

    .logout2 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      margin-top: 10px;
      cursor: pointer;
    }

    .titleModal {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }

    .spaceSelectPainel {
      background: var(--backgroundcolor);
      border-radius: 30px;
      padding: 20px 5px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;

      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }

      .spacePainels {
        border: 0.5px solid #ededf0;
        border-radius: 3px;
        padding: 15px;
        transition: 0.3s;
      }

      .spacePainels:hover {
        border: 0.5px solid #ededf0;
        padding: 15px;
        transform: scale(1.1);
      }

      .lineDiv {
        height: 0.5px;
        background: #ededf0;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 25px;
      }

      .flexData {
        display: flex;
        margin-bottom: 10px;
        margin-top: 10px;
        cursor: pointer;

        .spaceImg {
          width: 60px;
          height: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px;
          border-radius: 50%;


          img {
            width: 100%;
          }
        }

        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor);
          margin-left: 20px;
          line-height: 40px;
        }

        .descricao {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          color: #5d5d5d;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
