<template>
  <b-modal id="modal-new-certify" hide-footer>
    <template #modal-header="{ close }">
      <div></div>
      <div class="modalCertificate__close" @click="close()">
        <IconSax name="close-circle" type="outline" />
      </div>
    </template>
    <div class="modalCertificate">
      <div class="modalCertificate__hero">
        <IconSax name="medal-star" type="bold" />
      </div>
      <div class="modalCertificate__title">
        {{ $t('modal_new_certificate.title') }}
      </div>
      <div class="modalCertificate__percent" v-html="getPercentetext()">
      </div>
      <div class="modalCertificate__linkPage">
        {{ $t('modal_new_certificate.link_page') }}
        <router-link to="/certificates"> {{ $t('modal_new_certificate.title_routes') }}</router-link>.
      </div>
      <div class="modalCertificate__congragulations">
        {{ $t('modal_new_certificate.congragulations') }}
      </div>
      <div class="modalCertificate__action">
        <ButtonPlay
          type="normal"
          class="animationPulse modalCertificate__action--button"
          @click="downloadCertify"
        >
          <IconSax name="document-download" type="outline" />
          <span> {{ $t('modal_new_certificate.download') }}</span>
        </ButtonPlay>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { sanitize } from "@/utils/strings";
import axios from "axios";
import Cookies from "js-cookie";

import CertifyService from "@/services/resources/CertifyService";
const serviceCertificate = CertifyService.build();

import CertificateExtensionService from "@/services/resources/CertificateExtensionService";
const certificateExtensionService = CertificateExtensionService.build();

export default {
  components: {},
  data() {
    return {
      nameCourse: "",
      idCertify: null,
    };
  },
  methods: {
    getCertificate() {
      serviceCertificate
        .read("/notification")
        .then((response) => {
          if (response.length > 0) {

            if(response[0].certificate_extension_config){
              if(certificateExtensionService.shouldShowCertificate(response[0])){
                this.$root.$emit("bv::show::modal", "modal-certificate-available");
                return false;
              }else{
                this.$root.$emit("openCertifyToModal", response);
                this.nameCourse = response[0].title;
                this.idCertify = response[0].id;
              }
            }else{
              this.$root.$emit(
                "bv::show::modal",
                "modal-new-certify",
                "#btnShow"
              );
                this.nameCourse = response[0].title;
                this.idCertify = response[0].id;
              }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPercentetext() {
      return this.$t('modal_new_certificate.percent') + " <b>" + this.nameCourse + ".</b>";
    },
    downloadCertify() {
      axios({
        url:
          process.env.VUE_APP_API_HOST +
          `/certificate/download/${this.idCertify}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      }).then((response) => {
        const type = response.headers["content-type"];
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type })
        );

        let certificateNamePrefix = this.$t('modal_new_certificate.certificate_name_prefix');
        let sanitizedCourseName = certificateNamePrefix + '-' + sanitize(this.nameCourse);
        
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", sanitizedCourseName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
  mounted() {
    this.getCertificate();

    this.$root.$on("openCertifyToModal", (data) => {
      this.$root.$emit("bv::show::modal", "modal-new-certify", "#btnShow");
      this.nameCourse = data.title;
      this.idCertify = data.id;
    });
  },
};
</script>

<style lang="scss">
#modal-new-certify {
  .modal-header {
    border: none;
    padding: 16px 15px 0px 15px !important;
  }
  .modal-content {
    padding: 0px !important;
    min-height: 580px;
    max-width: 430px;
    background: var(--backgroundcolor) !important;
    border-radius: 8px;
  }
  .modal-body {
    padding: 0px 0;
  }
}
</style>

<style lang="scss" scoped>
.modalCertificate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0px 69px 40px 69px;
  text-align: center;
  font: var(--body-regular-16);
  color: var(--opposite-color);

  &__linkPage {
    a {
      color: var(--maincolor);
    }
  }

  &__close {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    ::v-deep {
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: var(--fontcolor);
        }
      }
    }
  }

  &__hero {
    ::v-deep svg {
      width: 80px;
      height: 80px;
      path {
        fill: var(--maincolor);
      }
    }
  }

  &__title {
    font: var(--body-bold-24);
  }

  &__action {
    font: var(--body-semibold-12);

    &--button {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
