import Rest from "@/services/Rest";

/**
 * @typedef {NPSResearchService}
 */
export default class NPSResearchService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'nps-research'

}
