<template>
  <div 
    :style="cssProps"
    :class="{
      container_wrap: true,
      noMargin: noMargin,
    }">
    <div :class="[type]"></div>
  </div>
</template>

<script>
export default {
  name: "Divider",
  props: {
    type: {
      type: "horizontal" | "vertical",
      default: "horizontal",
    },
    margin: {
      default: "0.5"
    },
    mt: {
      default: "0"
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssProps() {
      return {
        '--margin': `${this.margin}rem`,
        '--margin-top': this.mt ? `${this.mt}rem` : `${this.margin}rem`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--margin) 0;
  margin-top: var(--margin-top);
}

.noMargin {
  margin: 0 !important;
}

.vertical {
  width: 1px;
  height: 100%;
  background-color: var(--neutral-gray-100);

  [data-theme="dark"] & {
    background-color: var(--neutral-gray-700);
  }
}

.horizontal {
  width: 100%;
  height: 1px;
  background-color: var(--neutral-gray-100);

  [data-theme="dark"] & {
    background-color: var(--neutral-gray-700);
  }
}
</style>
