<template>
  <div id="sidebar" class="menu-container"
    :class="{
      'menu-container--colapsable': sidebar.collapsible,
      'menu-container--hidden': !sidebar.visible
    }"
  >
    <div class="menu logo-close">
      <div class="menu-logo" v-if="currentLogoMark || currentLogo && hasPermission">
        <router-link to="/home" class="after-element">
          <img  :src="currentLogoMark || currentLogo" alt="logo" class="ml-logo mb-4 mt-4" />
        </router-link>
      </div>

      <EmptyLogo v-else-if="!currentLogoMark && !currentLogo && hasPermission" />

      <div class="menu-logo" v-else>
        <router-link to="/home" class="after-element">
          <img  class="ml-logo mb-4 mt-4" />
        </router-link>
      </div>

      
      <SidebarMenu />
    </div>
    <div 
      v-if="sidebar.collapsible && sidebar.visible"
      @click="actionHideSidebar"
      class="sidebar__overlay"
    ></div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"

import SidebarMenu from "./SidebarMenu.vue";
import Logo from "@/components/base/Logo.vue";
import EmptyLogo from "./EmptyLogo.vue";

export default {
  data() {
    return {
      srcLogo: ""
    };
  },
  components: {
    SidebarMenu,
    Logo,
    EmptyLogo
  },
  computed: {
    ...mapGetters({
      layout: "layout/getLayout",
      sidebar: "layout/getSidebar",
      metasSite: "user/metasSite",
      studentCantView: "user/studentCantView",
      hasPermission: "user/hasPermission"
    }),

    currentLogoMark() {
      if (this.metasSite) {
        return this.metasSite?.logomarca;
      } else return null;
    },
    currentLogo() {
      if (this.metasSite) {
        return this.metasSite?.logo;
      } else return null;
    },
  },
  methods: {
    ...mapActions({
      actionHideSidebar: "layout/actionHideSidebar",
    }),
    logout() {
      this.$store.dispatch("auth/logoutRequest").catch(function () { });
    },
    minimizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
    logo() {
      this.srcLogo = require("@/assets/logo-collapsed.png");
    },
  },
  mounted() {
    this.logo();
  },
};
</script>

<style lang="scss" scoped>
/* fix scroll in iphone */
#sidebar {
  -webkit-overflow-scrolling: touch;
}

.sidebar__overlay {
  position: fixed;
  height: 100vh;
  left: 15rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    position: absolute;
    width: 100vw;
  }
}

.menu-container {
  transition: all 1s;
  display: flex;
  width: 15rem;
  flex-direction: column;
  align-items: flex-start;
  background: var(--backgroundcolor);

  &--colapsable {
    position: fixed;
    height: 100vh;
    z-index: 9999;
  }

  &--hidden {
    display: none;
  }
}

.menu {
  display: flex;
  padding: 1rem 1.5rem 2.5rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  width: 100%;
  // align-self: stretch;
  border-right: 1px solid var(--sidebar-border);
  overflow: auto;
}

.menu-logo {
  display: flex;
  padding: 0.75rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.menu-logo a img {
  height: 45px;
  object-fit: contain;

  @media screen and (max-width: 576px) {
      height: 45px;
  }
}
</style>
