<template>
	<div class="textarea-form-play p-0">
		<slot>
			<div
				class="d-flex flex-column p-0"
				:style="{ width: w }"
			>
				<TextPlay
					v-if="label"
					class="mb-2"
					tag="label"
					:text="label"
					:color="labelColor"
					size="sm"
					:for="id"
					weight="semibold"
					:skeleton="skeleton"
				/>
				<TextareaPlay
					:skeleton="skeleton"
					:readonly="readonly"
					:disabled="disabled"
					:name="name"
					:placeholder="placeholder"
					:rows="rows"
					:cols="cols"
					:heightByWrite="heightByWrite"
					:min="min"
					:max="max"
					class="textarea-form-play__textarea-play"
					@input="$emit('input', $event)"
					@enter="$emit('enter')"
					:value="value"
					:maxlength="maxlength"
					:w="w"
				/>
				<TextPlay
					v-if="showErrorMessage && errors.items.length > 0"
					tag="span"
					class="textarea-form-play__error"
					:text="errors?.items[0]?.msg"
					size="sm"
				/>
				<TextPlay
					v-if="description"
					class="textarea-form-play__description mt-2"
					tag="span"
					size="xs"
					:color="descColor"
					:text="description"
					:skeleton="skeleton"
				/>
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	$_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    }
  },
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		label: String,
		name: String,
		placeholder: String,
		value: [String, Number],
		validation: [String, Object],
		id: String,
		description: String,
		cols: [String, Number],
		rows: [String, Number],
		heightByWrite: {
			type: Boolean,
			default: false,
		},
		descColor: {
			type: String,
			default: "var(--text-color)",
		},
		labelColor: {
			type: String,
			default: "var(--fontcolor)",
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		maxlength: {
			type: [String, Number],
			default: 100,
		},
		min: {
			type: [String, Number],
		},
		max: {
			type: [String, Number],
		},
		w: {
			type: String,
			default: "100%",
		},
	},
	methods: {
		validateField() {
			this.$validator.validate(this.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.textarea-form-play {
	&__error {
		margin-top: 8px;
		color: var(--feedback-error-500, #ff3932);
	}
}
</style>
