import fp from "@/utils/fp";
import router from "@/router";
import * as Tus from 'tus-js-client'

import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

import CertifyService from "@/services/resources/CertifyService";
const serviceCertificate = CertifyService.build();


import CommentService from "@/services/resources/CommentService";
import { MB_1 } from "@/utils/const";
const serviceComments = CommentService.build();



export default {
  /**
   * - when: moduleId or lessonId are missing
   *   - then: get current lesson and fill next params
   * - when: moduleId has changed
   *   - then: call loadModule to get module's data
   */
  actionCurrentLessonOpened({ commit, state }, currentModuleOpened) {
    commit("courses/SET_CURRENT_LESSON_OPENED", currentModuleOpened, {
      root: true,
    });
  },
  async actionOpenLesson(
    { commit, state, dispatch },
    { courseId, moduleId, lessonId, orientation }
  ) {
    const next = {
      courseId: Number(courseId),
      lessonId: Number(lessonId) || null,
      moduleId: Number(moduleId) || null,
    };
    console.info("Loading Lesson", next)

    const { params } = state;


    let promises = [];
    try {
      if (params.courseId !== courseId) {
        commit("CLEAR");
        promises.push(dispatch("actionFetchCourse", next));
        promises.push(dispatch("actionFetchCoursePercentage", next));
        await Promise.all(promises);
        promises = [];
        next.moduleId = moduleId || (await dispatch("actionNextModule", state))?.id
      }

      if (params.moduleId !== next.moduleId) {
        promises.push(dispatch("actionFetchModuleLessons", next));
      }

      if (!next.moduleId || !next.lessonId) {
        await Promise.all(promises);
        promises = [];

        const lesson = await dispatch("actionComputeCurrentLesson", {
          orientation,
        });

        // next.moduleId = await lesson?.module_id;

        next.lessonId = await lesson?.id;

        await router.push({
          name: "course-lesson",
          params: { ...next },
        });

        await dispatch("actionCurrentLessonOpened", next.moduleId);
      }

      if (params.lessonId != next.lessonId) {
        promises.push(dispatch("actionFetchLesson", next));
      }

      await Promise.all(promises);
      commit("SET_PARAMS", next);

      await dispatch("actionFetchComments");
      await dispatch("actionComputeNavigation");
      await dispatch("config/actionDefineMainColor", null, { root: true });
    } finally {
      commit("SET_LOADING", { course: false, module: false, lesson: false, comments: false });
    }
    return next;
  },
  async actionFetchCourse({ commit, dispatch }, { courseId }) {
    try {
      commit("SET_LOADING", { course: true });
      const params = ["data[]=course", "data[]=modules"].join("&");
      const { course, modules } = await serviceCourse.read(
        `/${courseId}/watch?${params}`
      );
      commit("SET_COURSE", course);
      commit("SET_MODULES", modules);
    } finally {
      await dispatch("config/actionDefineMainColor", null, { root: true });
      // commit("SET_LOADING", { course: false });
    }
  },

  async actionNextModule({ commit, dispatch }, { modules }) {
    const module = fp.find((item) => {
      return item.lessons_completed_count < item.lessons_count
    }, modules);
    return module || modules[0]
  },

  async actionFetchModuleLessons({ commit, dispatch }, { courseId, moduleId }) {
    try {
      const params = [
        "data[]=currentModuleLessons",
        moduleId ? `current_module_id=${moduleId}` : undefined,
      ].join("&");
      const { currentModuleLessons } = await serviceCourse.read(
        `/${courseId}/watch?${params}`
      );
      await commit("SET_CURRENT_MODULE_LESSONS", currentModuleLessons);
      await commit('SET_CURRENT_LESSON_OPENED', moduleId);
    } finally {
      await dispatch("config/actionDefineMainColor", null, { root: true });
      // await commit("SET_LOADING", { module: false });
    }
  },

  /**
   * @example
   * getCurrentLesson(courseId, moduleId, "first");
   * --> get first module's lesson
   * getCurrentLesson(courseId, moduleId, "last");
   * --> get last module's lesson
   * getCurrentLesson(courseId, moduleId);
   * --> get first uncompleted lesson or last.
   */
  async actionComputeCurrentLesson({ commit, state }, { orientation }) {
    const { currentModuleLessons } = state;
    switch (orientation) {
      case "first":
        return fp.first(currentModuleLessons);
      case "last":
        return fp.last(currentModuleLessons);
      default:
        return (
          fp.find((lesson) => !lesson.is_completed, currentModuleLessons) ||
          fp.last(currentModuleLessons)
        );
    }
  },

  async actionFetchLesson({ commit }, { courseId, moduleId, lessonId }) {
    try {
      commit("SET_LOADING", { lesson: true });
      if (!courseId || !moduleId || !lessonId) {
        commit("SET_CURRENT_LESSON", {});
      } else {
        const lesson = await serviceCourse.read(
          `/${courseId}/module/${moduleId}/lesson/${lessonId}`
        );
        commit("SET_CURRENT_LESSON", lesson);
      }
    } finally {
      // commit("SET_LOADING", { lesson: false });
    }
  },

  async actionFetchComments({ commit, state }) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_LOADING", { comments: true });
      const response = await serviceCourse.read(
        `${courseId}/module/${moduleId}/lesson/${lessonId}/comment`
      );
      commit("SET_LESSON_COMMENTS", response.reverse());
    } finally {
      // commit("SET_LOADING", { comments: false });
    }
  },

  async actionCommentLike({ commit, state, dispatch }, { id }) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_LOADING", { comments: true });
      await serviceCourse.updateId({
        id:
          courseId +
          "/module/" +
          moduleId +
          "/lesson/" +
          lessonId +
          "/comment/" +
          id +
          "/like",
      });
      await dispatch("actionFetchComments");
    } finally {
      commit("SET_LOADING", { comments: false });
    }
  },

  async actionCommentUnlike({ commit, state, dispatch }, { id }) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_LOADING", { comments: true });
      await serviceCourse.destroy({
        id:
          courseId +
          "/module/" +
          moduleId +
          "/lesson/" +
          lessonId +
          "/comment/" +
          id +
          "/like",
      });
      await dispatch("actionFetchComments");
    } finally {
      commit("SET_LOADING", { comments: false });
    }
  },

  async actionCommentCreate(
    { commit, state, dispatch },
    { parent_lesson_comment_id, comment }
  ) {
    let { courseId, moduleId, lessonId } = state.params;
    
    var data = {
      id: courseId + "/module/" + moduleId + "/lesson/" + lessonId + "/comment",
      comment,
      parent_lesson_comment_id,
    };
    try {
      commit("SET_LOADING", { comments: true });
      await serviceCourse.postID(data);
      await dispatch("actionFetchComments");
    } finally {
      commit("SET_LOADING", { comments: false });
    }
  },

  async actionCommentEdit(
      { commit, state, dispatch },
      { id, comment }
  ) {
    let { courseId, moduleId, lessonId } = state.params;
    let data = {
      id: courseId + "/module/" + moduleId + "/lesson/" + lessonId + "/comment/" + id,
      comment
    };

    try {
      commit("SET_LOADING", { comments: true });
      await serviceCourse.update(data);
      await dispatch("actionFetchComments");
    } finally {
      commit("SET_LOADING", { comments: false });
    }
  },

  async actionCommentApprove({ commit, dispatch }, { id }) {
    try {
      commit("SET_LOADING", { comments: true });
      await serviceComments.postID({ id, status: "published" });
      await dispatch("actionFetchComments");
    } finally {
      commit("SET_LOADING", { comments: false });
    }
  },

  async actionCommentDelete({ commit, dispatch, state }, { id }) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_LOADING", { comments: true });
      await serviceCourse.destroy(
        courseId +
          "/module/" +
          moduleId +
          "/lesson/" +
          lessonId +
          "/comment/" +
          id
      );
      await dispatch("actionFetchComments");
    } finally {
      commit("SET_LOADING", { comments: false });
    }
  },

  async actionFetchCoursePercentage({ commit }, { courseId }) {
    const { lessons_completed, lessons_count } = await serviceCourse.read(
      `/${courseId}/progress`
    );
    if (!lessons_completed || !lessons_count) {
      return 0;
    }
    const percentage = parseInt(
      Math.min(100, (lessons_completed / lessons_count) * 100),
      10
    );
    commit("SET_COURSE_PERCENTAGE", percentage);
  },
  /**
   * - when: there's previous lesson on current module.<br>
   *   - then: fill onPrev with this lesson link.
   * - when: there's no previous class on current module but there's a previous module.<br>
   *   - then: fill onPrev with previous module link
   * - when: there's no previous class or module<br>
   *   - then: onPrev will be null
   * - the same logic for onNext
   */
  actionComputeNavigation({ commit, state }) {
    const { modules, currentModuleLessons } = state;
    const { courseId, moduleId, lessonId } = state.params;

    const lessonsFiltered = currentModuleLessons.filter(
      (lesson) => lesson.is_liberated == true
    );

    const moduleFiltered = modules.filter(
      (module) => module.is_liberated == true
    );

    const currentModuleIndex = fp.findIndex(
      (module) => module.id == moduleId,
      moduleFiltered
    );
    const currentLessonIndex = fp.findIndex(
      (lesson) => lesson.id == lessonId,
      lessonsFiltered
    );

    const prevLesson = lessonsFiltered[currentLessonIndex - 1];
    const prevModule = moduleFiltered[currentModuleIndex - 1];
    commit(
      "SET_PREV_LESSON",
      prevLesson || prevModule
        ? {
            courseId,
            moduleId: prevLesson?.module_id || prevModule?.id,
            lessonId: prevLesson?.id,
            orientation: "last",
          }
        : null
    );

    const nextLesson = lessonsFiltered[currentLessonIndex + 1];
    const nextModule = moduleFiltered[currentModuleIndex + 1];
    commit(
      "SET_NEXT_LESSON",
      nextLesson || nextModule
        ? {
            courseId,
            moduleId: nextLesson?.module_id || nextModule?.id,
            lessonId: nextLesson?.id,
            orientation: "first",
          }
        : null
    );
  },

  async actionPostRating({ state }, rating) {
    const { courseId, moduleId, lessonId } = state.params;
    await serviceCourse.postID({
      id: `${courseId}/module/${moduleId}/lesson/${lessonId}/rating`,
      rating: rating,
    });
  },

  async actionCompleteLesson({ commit, state, dispatch }) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_SUBMITING", { completion: true });
      let course = await serviceCourse.postID({
        id: `${courseId}/module/${moduleId}/lesson/${lessonId}/progress`,
        completed: true,
      });

      commit("SET_CURRENT_LESSON", course.lesson);
      
      await dispatch("actionFetchCoursePercentage", { courseId });
      await dispatch("actionFetchModuleLessons", { courseId, moduleId });
    }
    finally {
      commit("SET_SUBMITING", { completion: false });
    }
  },

  async actionCompleteState({ commit, state, dispatch }, completedState) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_SUBMITING", { completion: true });
      let lesson = await serviceCourse.post(
        `${courseId}/module/${moduleId}/lesson/${lessonId}/completeState`,
        {
          id: lessonId,
          completedState: completedState,
        }
    );
 
      commit("SET_CURRENT_LESSON", lesson);
    }
    finally {
      commit("SET_SUBMITING", { completion: false });
    }
  },

  async actionLoadSingleCertificate({ state }) {
    const { courseId } = state.params;
    try {
      const response = await serviceCourse.read(`${courseId}/certificate/me`);

      if (response.showed === 0) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  },

  async actionDisableCertificate() {
  
    try {
      await serviceCertificate
      .read("/notification")
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error)
    }
  },

  async actionAnswerLessonQuiz({ state, commit }, data) {
    const { courseId, moduleId, lessonId } = state.params;
    try {
      commit("SET_SUBMITING", { answer: true });
      await serviceCourse.postID2({
        id: {
          id: `${courseId}/module/${moduleId}/lesson/${lessonId}/question/${data.question_id}/answer/student`,
        },
        data,
      });
    } finally {
      commit("SET_SUBMITING", { answer: false });
    }
  },

  async actionClear({ commit }) {
    commit("CLEAR")
  },

  actionTUSUpload({ commit }, { location, file, metadata }) {
    return new Promise((resolve, reject) => {
      const upload = new Tus.Upload(file, {
        location,
        uploadUrl: location,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 128 * MB_1,
        removeFingerprintOnSuccess: true,
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: function (error) {
          console.log('Failed because: ' + error)
          reject(error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, percentage + '%')
        },
        onSuccess: function () {
          console.log('Download %s from %s', upload.file.name, upload.url)
          resolve();
        },
      })
    
      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0])
        }
    
        // Start the upload
        upload.start()
      })
    });
  }
};
