<template>
    <a 
      v-if="label && newtab == '1'"
      :href="url" class="menu-item" target="_blank">
      <IconSax :name="icon" primary />
      {{ label }}
    </a>
    <router-link 
      v-else-if="label"
      :to="{ name: 'custom_link', params: { index } }" 
      class="menu-item">
      <IconSax :name="icon" primary />
      {{ label }}
    </router-link>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  props: ['index'],
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
    }),
    icon() {
        return this.getSettings[`link_menu_${this.index}_icon`] || "link"
    },
    label() {
        return this.getSettings[`link_menu_${this.index}_label`]
    },
    url() {
        return this.getSettings[`link_menu_${this.index}_url`]
    },
    newtab() {
        return this.getSettings[`link_menu_${this.index}_newtab`]
    }
  }
}

</script>
<style lang="scss" scoped>

.menu-item {
  @include flex-row;
  width: 13.0625rem;
  padding: 0.625rem 0rem;
  color: var(--fontcolor);
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  ::v-deep svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: var(--maincolor);
    }
  }

  &.router-link-active {
    color: var(--maincolor);
  }

  &:hover {
    color: var(--maincolor);
  }
}

</style>