export default {
  SET_ME(state, me) {
    state.me = me;
  },
  SET_PROFILE(state, payload) {
    state.me.current.currentMember = {
      ...state.me.current.currentMember,
      name: payload.first_name + " " + payload.last_name,
      cellphone: payload.cellphone,
    };
  },
  SET_AVATAR(state, avatar) {
    state.me.current.currentMember = {
      ...state.me.current.currentMember,
      picture: avatar
    };
    setTimeout(() => {
      location.reload();
    }, 500);
  },
  authSuccess: (state, auth_greennCourse) => {
    state.auth_greennCourse = auth_greennCourse;
  },
  authFeedback: (state, payload) => {
    state.feedback = payload;
  },
  authLogout: (state) => {
    state.auth_greennCourse = null;
  },
  userCustomFields: (state, resp) => {
    state.userCustomFields = resp;
  },
};
