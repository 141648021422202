const SALT = "#123$ABCD";

const DEFAULT = {
  "iduser": null,
  "seguranca": "E019B4EC5C6A75A926E20C3813E52291",
  "autoplay": "0",
  "remarketing": 0,
  "idvideo": "At_RevZkdGE",
  "idunico": "p0ZnOydjZONk",
  "pixelNomeEvento": "vsltube",
  "formato": "horizontalmode",
  "PW": "",
  "BW": "",
  "BC": "#a3a3a3",
  "pRadius": "",
  "shadowW": "",
  "shadowC": "",
  "livemodeOn": "0",
  "barraProgressoColor": "#ff0000",
  "fullscreenOn": "1",
  "timerOn": "1",
  "muteOn": "1",
  "intro": "0",
  "introText1": "Seu vídeo já começou",
  "introText2": "Clique para ouvir",
  "barraProgressoOn": "1",
  "barraProgressoControle": "1",
  "barraAltura": "6",
  "barraFake": "0",
  "barraFakeTime": "",
  "actionColor": "#007bff",
  "openScreenBG": "",
  "pauseScreenBG": "",
  "endScreenBG": "",
  "endScreenText": "Assistir novamente",
  "endScreenNextText": "Próxima Aula",
  "showElement": "nome-do-elemento",
  "showTime": 5,
  "cookieOn": "0",
  "cookieColor": "",
  "cookieTitulo": "Você já começou a assistir este vídeo",
  "cookieTextoContinue": "Continuar assistindo",
  "cookieTextoReiniciar": "Começar do inicio"
}

export function vsl_build(obj = {}) {
  return Object.assign({}, DEFAULT, obj)
}

export function encode_vsl(data) {
  function cipher(salt) {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }
  
  const myCipher = cipher(SALT);
  return myCipher(JSON.stringify(data));
}

export function decode_vsl(data) {
  function decipher(salt) {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
  }
  var myDecipher = decipher(SALT);
  return JSON.parse(myDecipher(data))
}

export function load_vsl(vsltubekey) {
  if (!vsltubekey) {
    return
  }

  if (vsltubekey.cookieColor == undefined) {
    vsltubekey.cookieColor = vsltubekey.actionColor;
    vsltubekey.cookieTitulo = "Você já começou a assistir este vídeo";
    vsltubekey.cookieTextoContinue = "Continuar assistindo";
    vsltubekey.cookieTextoReiniciar = "Começar do inicio";

    vsltubekey.endScreenText = "Assistir novamente?";
  }

  if (vsltubekey.shadowW == undefined) {
    vsltubekey.shadowW = "0";
    vsltubekey.shadowC = "";
    vsltubekey.pRadius = "0";
  }

  if (vsltubekey.pixelNomeEvento == undefined) {
    vsltubekey.pixelNomeEvento = "vsltube";
  }

  if (vsltubekey.formato == undefined) {
    vsltubekey.formato = "";
  }

  function extractDomain(url) {
    var domain;

    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }

    domain = domain.split(':')[0];

    return domain;
  }

  function getRootHostName(url) {
    var parts = extractDomain(url).split('.');
    var partsLength = parts.length - 3;

    for (let i = 0; i < partsLength; i++) {
      parts.shift();
    }
    var rootDomain = parts.join('.');

    return rootDomain;
  }



  var jsCookies = {

    get: function (c_name) {
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) {
            c_end = document.cookie.length;
          }
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return "";
    },

    set: function (c_name, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie = c_name + "=" + escape(value) + "; domain=" + getRootHostName(window.location.hostname).replace('www.', '') + "; path=/ " + ((expiredays == null) ? "" : "; expires=" + exdate.toUTCString());

    },

    check: function (c_name) {
      c_name = jsCookies.get(c_name);
      if (c_name != null && c_name != "") {
        return true;
      } else {
        return false;
      }
    }

  };

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  function isIpad() {
    return (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  function isIphone() {
    return navigator.userAgent.match(/iPhone/i) != null;
  }

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  var fileExtension = vsltubekey.openScreenBG.split('.').pop();

  var vsltubeBg = "";

  if (fileExtension == 'mp4' || vsltubekey.openScreenBG.indexOf("player.vimeo.com/progressive_redirect") != -1) {
    if (isSafari || iOS()) {
      vsltubeBg = '<img  class="vlst-bg" src="' + vsltubekey.openScreenBG + '" >';
    } else {
      vsltubeBg = '<video id="vlst-bg" class="vlst-bg " poster="" playsinline autoplay muted loop> <source src="' + vsltubekey.openScreenBG + '" type="video/mp4"> </video>';
    }
  } else {
    vsltubeBg = '<img  class="vlst-bg" src="' + vsltubekey.openScreenBG + '" >';
  }


  if (navigator.userAgent.indexOf(' UCBrowser/') >= 0 || vsltubekey.openScreenBG == "") {
    vsltubeBg = '<img  class="vlst-bg" src="https://img.youtube.com/vi/' + vsltubekey.idvideo + '/sddefault.jpg" >';
  }

  var isPlataform = true;
  var vcookie = "";
  var blackVload = "";


  var lastCurrentTime = 0;
  if (jsCookies.check(vsltubekey.idunico + "vsltube_lastCurrentTime") && vsltubekey.cookieOn == 1) {
    lastCurrentTime = Math.floor(jsCookies.get(vsltubekey.idunico + "vsltube_lastCurrentTime"));
  }



  if (vsltubekey.cookieOn == 1 && vsltubekey.livemodeOn == 0) {
    if (lastCurrentTime > 0) {
      vcookie = "vload";
      blackVload = ' style="background:black;" ';

      if (vsltubekey.barraFake == 1)
        vsltubekey.barraFakeTime = 2;
    }
  }


  var youtubelink = "https://www.youtube.com";
  if (vsltubekey.remarketing != undefined) {
    if (vsltubekey.remarketing == 0) {
      youtubelink = 'https://www.youtube-nocookie.com';
    }
  }

  if (jsCookies.check(vsltubekey.idunico + "vsltube_VideoDuration")) {
    var vsltube_VideoDuration = Math.floor(jsCookies.get(vsltubekey.idunico + "vsltube_VideoDuration"));
    if (vsltubekey.livemodeOn == 1) {
      if (lastCurrentTime > (vsltube_VideoDuration - 10)) {
        if (lastCurrentTime > 0) {
          vcookie = "vfim vplay video-vsl-live";
        }
      }
    }
  }


  function loadMediaQuery() {
    if (window.parent.innerWidth < 1100) {
      addClass(document.getElementById('vsltube'), "frametablet");
    }

    if (window.parent.innerWidth > 1100) {
      removeClass(document.getElementById('vsltube'), "frametablet");
    }

    if (window.parent.innerWidth < 600) {
      addClass(document.getElementById('vsltube'), "framemobile");
    }

    if (window.parent.innerWidth > 600) {
      removeClass(document.getElementById('vsltube'), "framemobile");
    }
  }

  var pageHosted = window.location.hostname;

  //roda em modo iframe
  if (window.location.href.indexOf("srcdoc") != -1) {
    addClass(document.getElementById('vsltube'), "framemode");

    vsltubekey.shadowW = "0";
    vsltubekey.pRadius = "0";

    window.parent.onresize = function (event) {
      loadMediaQuery();
    };

    loadMediaQuery();

    pageHosted = window.parent.location.href;
    vsltubekey.BW = 0;
    vsltubekey.PW = '';

    window.parent.document.getElementById("vsltube_" + vsltubekey.idunico).insertAdjacentHTML("beforeend", "<style>@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');.fullscreenfake{ overflow:visible!important; width: 100%!important; Left:0; right:0; max-width:100%!important; border:0!important; position:fixed!important; top: 50%; transform: translateY(-50%); z-index:9998  } .fullscreenfake:before{content:''; background: #000; position:fixed; margin:auto; height: 100vh; width: 100vw; top: 0;bottom: 0;right: 0px;left: 0px;  }.vsltube-cta{padding:20px 10px !important; font-size:10px !important;  text-align:center !important}.vsltube-cta-title{font-size:2.2em !important; margin-bottom:1em !important; font-weight:500 !important; font-family: roboto !important;}.vsltube-cta-btn{ width: 400px !important; max-width:95% !important; font-size:1.6em !important; line-height:3em !important; min-height:3em !important; background: #23be75 !important; color:#fff  !important; display:block !important;margin:0 auto !important;cursor:pointer !important; border-radius:10px !important; box-shadow:inset 0 0 0 1px rgba(0, 0, 0, .2) !important; font-family: roboto !important; text-decoration: none !important}.vsltube-cta-btn:hover{box-shadow:inset 0 0 0 1000px rgba(0, 0, 0, .2) !important}.vsltube-cta .payment{ width: 15% !important; max-width:66px !important; margin: 10px 0 0 0px !important}.vsltube-cta #cta-card{ width: 60% !important; max-width:264px !important}.mobile-mode .vsltube-cta .vsltube-cta-title{font-size:1.6em !important}.mobile-mode .vsltube-cta .vsltube-cta-btn{font-size:1.4em !important}@media (max-width:720px) , (max-height: 600px){.mobile-mode .vsltube-cta .vsltube-cta-title{font-size:1.6em !important}.mobile-mode .vsltube-cta .vsltube-cta-btn{font-size:1.4em !important}}</style>");
  }

  const playSVG = `
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41" cy="41" r="41" fill="${vsltubekey.actionColor}"/>
      <path d="M56.8594 39.6789C58.1927 40.4487 58.1927 42.3732 56.8594 43.143L35.1844 55.6571C33.851 56.4269 32.1844 55.4646 32.1844 53.925L32.1844 28.8969C32.1844 27.3573 33.851 26.395 35.1844 27.1648L56.8594 39.6789Z" fill="${vsltubekey.playColor}"/>
    </svg>
  `;

  const continueSVG = `
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41" cy="41" r="41" fill="${vsltubekey.playColor}"/>
      <path d="M56.8594 39.6789C58.1927 40.4487 58.1927 42.3732 56.8594 43.143L35.1844 55.6571C33.851 56.4269 32.1844 55.4646 32.1844 53.925L32.1844 28.8969C32.1844 27.3573 33.851 26.395 35.1844 27.1648L56.8594 39.6789Z" fill="${vsltubekey.actionColor}"/>
    </svg>
  `;

  const restartSVG = `
    <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41.5" cy="41" r="41" fill="${vsltubekey.playColor}" />
      <path d="M41.4998 58.9167C32.8331 58.9167 25.7998 51.8667 25.7998 43.2167C25.7998 40.1001 26.7165 37.0834 28.4498 34.4834C28.8331 33.9167 29.6165 33.7501 30.1831 34.1334C30.7498 34.5167 30.9165 35.3001 30.5331 35.8667C29.0831 38.0334 28.3165 40.5834 28.3165 43.2001C28.3165 50.4834 34.2331 56.4001 41.5165 56.4001C48.7998 56.4001 54.7165 50.4834 54.7165 43.2001C54.7165 35.9167 48.7831 30.0001 41.4998 30.0001C39.9665 30.0001 38.4665 30.2167 37.0498 30.6501C36.3831 30.8501 35.6831 30.4834 35.4831 29.8167C35.2831 29.1501 35.6498 28.4501 36.3165 28.2501C37.9831 27.7501 39.7165 27.4834 41.4998 27.4834C50.1665 27.4834 57.1998 34.5334 57.1998 43.1834C57.1998 51.8334 50.1665 58.9167 41.4998 58.9167Z" fill="${vsltubekey.actionColor}" />
      <path d="M34.6166 31.1167C34.3333 31.1167 34.0333 31.0167 33.7999 30.8167C33.2666 30.35 33.2166 29.5667 33.6666 29.05L38.4833 23.5167C38.9333 23 39.7333 22.9334 40.2499 23.4C40.7666 23.85 40.8166 24.65 40.3666 25.1667L35.5499 30.6834C35.2999 30.9667 34.9499 31.1167 34.6166 31.1167Z" fill="${vsltubekey.actionColor}" />
      <path d="M40.233 35.2167C39.983 35.2167 39.7163 35.1334 39.4996 34.9834L33.8663 30.8667C33.3163 30.4667 33.1996 29.6834 33.5996 29.1334C33.9996 28.5667 34.783 28.45 35.3496 28.85L40.9663 32.95C41.5163 33.35 41.6496 34.1334 41.233 34.7C40.9996 35.05 40.6163 35.2167 40.233 35.2167Z" fill="${vsltubekey.actionColor}" />
    </svg>
  `

  const nextLessonSVG = `
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41" cy="41" r="41" fill="${vsltubekey.playColor}"/>
      <path d="M56.8594 39.6789C58.1927 40.4487 58.1927 42.3732 56.8594 43.143L35.1844 55.6571C33.851 56.4269 32.1844 55.4646 32.1844 53.925L32.1844 28.8969C32.1844 27.3573 33.851 26.395 35.1844 27.1648L56.8594 39.6789Z" fill="${vsltubekey.actionColor}"/>
    </svg>
  `

  const muteSVG = `
    <svg id="vvslmute1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 16.75C17.84 16.75 17.69 16.7 17.55 16.6C17.22 16.35 17.15 15.88 17.4 15.55C18.97 13.46 18.97 10.54 17.4 8.45003C17.15 8.12003 17.22 7.65003 17.55 7.40003C17.88 7.15003 18.35 7.22003 18.6 7.55003C20.56 10.17 20.56 13.83 18.6 16.45C18.45 16.65 18.23 16.75 18 16.75Z" fill="${vsltubekey.actionColor}"/>
      <path d="M19.8301 19.25C19.6701 19.25 19.5201 19.2 19.3801 19.1C19.0501 18.85 18.9801 18.38 19.2301 18.05C21.9001 14.49 21.9001 9.51003 19.2301 5.95003C18.9801 5.62003 19.0501 5.15003 19.3801 4.90003C19.7101 4.65003 20.1801 4.72003 20.4301 5.05003C23.5001 9.14003 23.5001 14.86 20.4301 18.95C20.2901 19.15 20.0601 19.25 19.8301 19.25Z" fill="${vsltubekey.actionColor}"/>
      <path d="M14.02 3.77997C12.9 3.15997 11.47 3.31997 10.01 4.22997L7.09 6.05997C6.89 6.17997 6.66 6.24997 6.43 6.24997H5.5H5C2.58 6.24997 1.25 7.57997 1.25 9.99997V14C1.25 16.42 2.58 17.75 5 17.75H5.5H6.43C6.66 17.75 6.89 17.82 7.09 17.94L10.01 19.77C10.89 20.32 11.75 20.59 12.55 20.59C13.07 20.59 13.57 20.47 14.02 20.22C15.13 19.6 15.75 18.31 15.75 16.59V7.40997C15.75 5.68997 15.13 4.39997 14.02 3.77997Z" fill="${vsltubekey.actionColor}"/>
    </svg>
  `

  const muteSVG2 = `
    <svg id="vvslmute2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 16.75C17.84 16.75 17.69 16.7 17.55 16.6C17.22 16.35 17.15 15.88 17.4 15.55C18.66 13.87 18.93 11.64 18.12 9.70995C17.96 9.32995 18.14 8.88995 18.52 8.72995C18.9 8.56995 19.34 8.74995 19.5 9.12995C20.52 11.55 20.17 14.3599 18.6 16.4599C18.45 16.6499 18.23 16.75 18 16.75Z" fill="${vsltubekey.actionColor}"/>
      <path d="M19.8301 19.25C19.6701 19.25 19.5201 19.2 19.3801 19.1C19.0501 18.85 18.9801 18.38 19.2301 18.05C21.3701 15.2 21.8401 11.38 20.4601 8.08995C20.3001 7.70995 20.4801 7.26995 20.8601 7.10995C21.2401 6.94996 21.6801 7.12996 21.8401 7.50996C23.4301 11.29 22.8901 15.67 20.4301 18.95C20.2901 19.15 20.0601 19.25 19.8301 19.25Z" fill="${vsltubekey.actionColor}"/>
      <path d="M14.04 12.96C14.67 12.33 15.75 12.78 15.75 13.67V16.6C15.75 18.32 15.13 19.61 14.02 20.23C13.57 20.48 13.07 20.6 12.55 20.6C11.75 20.6 10.89 20.33 10.01 19.78L9.36998 19.38C8.82998 19.04 8.73998 18.28 9.18998 17.83L14.04 12.96Z" fill="${vsltubekey.actionColor}"/>
      <path d="M21.77 2.23C21.47 1.93 20.98 1.93 20.68 2.23L15.73 7.18C15.67 5.58 15.07 4.38 14.01 3.79C12.89 3.17 11.46 3.33 10 4.24L7.09 6.06C6.89 6.18 6.66 6.25 6.43 6.25H5.5H5C2.58 6.25 1.25 7.58 1.25 10V14C1.25 16.42 2.58 17.75 5 17.75H5.16L2.22 20.69C1.92 20.99 1.92 21.48 2.22 21.78C2.38 21.92 2.57 22 2.77 22C2.97 22 3.16 21.92 3.31 21.77L21.77 3.31C22.08 3.01 22.08 2.53 21.77 2.23Z" fill="${vsltubekey.actionColor}"/>
    </svg>
  `

  const fullSVG = `
    <svg id="vvslfullscreen1" version="1.1" viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g fill="${vsltubekey.actionColor}" fill-rule="evenodd" id="Page-1" stroke="black" stroke-width=".5"
            stroke-opacity="0.3">
            <g id="Core" transform="translate(-215.000000, -257.000000)">
                <g id="fullscreen" transform="translate(215.000000, 257.000000)">
                    <path
                        d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z"
                        id="Shape" />
                </g>
            </g>
        </g>
    </svg>
  `

  const fullSVG2 = `
    <svg id="vvslfullscreen2" version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g fill="${vsltubekey.actionColor}" fill-rule="evenodd" id="Page-1" stroke="black" stroke-width=".5"
            stroke-opacity="0.3">
            <g id="Core" transform="translate(-257.000000, -257.000000)">
                <g id="fullscreen-exit" transform="translate(257.000000, 257.000000)">
                    <path
                        d="M0,11 L3,11 L3,14 L5,14 L5,9 L0,9 L0,11 L0,11 Z M3,3 L0,3 L0,5 L5,5 L5,0 L3,0 L3,3 L3,3 Z M9,14 L11,14 L11,11 L14,11 L14,9 L9,9 L9,14 L9,14 Z M11,3 L11,0 L9,0 L9,5 L14,5 L14,3 L11,3 L11,3 Z"
                        id="Shape" />
                </g>
            </g>
        </g>
    </svg>
  `

  const playerHTML = `
  <div
    id="videovsl" 
    class="video-vsl ${vcookie} ${vsltubekey.formato}"
    style="border: solid ${vsltubekey.BW / 10}em ${vsltubekey.BC}; max-width: ${vsltubekey.PW}px; margin: ${vsltubekey.shadowW}px auto; box-shadow:0 0 ${vsltubekey.shadowW}px ${vsltubekey.shadowC};width: calc(100% - ${vsltubekey.shadowW}px); border-radius: ${vsltubekey.pRadius}px;">
    <div class="vcontrol-group force-hidden">
      <div id="controlgroup" class="vcontrol-group-inner">
        <div style="color: ${vsltubekey.actionColor}" id="timerdisplay" class="vvsl-timer "><span id="current-time">5:00</span> <span id="durationtime">/
          <span id="duration">10:00</span></span></div>
        <div id="mutebtn" class="vslt-control-icon "> 
          ${muteSVG}
          ${muteSVG2}
        </div>
        <div id="fullbtn" class="vslt-control-icon ">
          ${fullSVG}
          ${fullSVG2}
        </div>
      </div>
      <div id="progbar" class="vslt-range">
        <input id="proginput" type="range" value="0" min="0" max="100">
          <span class="vslt-range-barra" style="background: ${vsltubekey.barraProgressoColor}" />
      </div>
    </div>
    ${vsltubeBg}
    <div class="box-vplay-mask"></div>
    <div id="telaCookieColor" class="box-cookie box-cookie-t" style="background:${vsltubekey.cookieColor}; opacity: 0.8;"> 
      <span id="cookieTitulo">${vsltubekey.cookieTitulo}</span>
    </div>
    <div class="box-cookie box-cookie-l">
      <div class="box-inner-center flex-col items-center gap-2"> 
        ${restartSVG}
        <span id="cookieTextoReiniciar">${vsltubekey.cookieTextoReiniciar}</span> 
      </div>
    </div>
    <div class="box-cookie box-cookie-r">
      <div class="box-inner-center flex-col items-center gap-2"> 
        ${continueSVG}
        <span id="cookieTextoReiniciar">${vsltubekey.cookieTextoContinue}</span> 
      </div>
    </div>
    <div class="box-fim flex-row items-center gap-2">
      ${vsltubeBg}
      <div class="mask" style="background:${vsltubekey.cookieColor};"></div>
      <div class="box-fim-l flex-1 flex-col items-center gap-1">
        ${restartSVG}
        <span id="endScreenTxt">${vsltubekey.endScreenText}</span> 
      </div>
      <div class="box-fim-r flex-1 flex-col items-center gap-1">
        ${nextLessonSVG}
        <span id="endScreenTxt">${vsltubekey.endScreenNextText}</span> 
      </div>
    </div>
    <div ${blackVload} class="box-load"></div>
    <div class="box-intro">
      <div id="introplay" class="box-inner-center"> 
        ${playSVG}
      </div>
    </div>
    <div class="box-pause">
      <div class="box-inner-center"> 
        ${playSVG}  
      </div>
    </div>
    <div class="box-black"></div> <iframe id="player" class="fitvidsignore"
        src="${youtubelink}/embed/${vsltubekey.idvideo}?enablejsapi=1&html5=1&controls=0&fs=0&rel=0&disablekb=${(vsltubekey.barraProgressoControle == 0 ? 1 : 0)}&start=${lastCurrentTime}"
        frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="vvsl-fullscreen"></div>
  `.trim();
  document.getElementById("vsltube").innerHTML = playerHTML

  if (jsCookies.check(vsltubekey.idunico + "vsltube_OpenBtn")) {

    if (window.location.href.indexOf("srcdoc") != -1) {
      var btnOculto = window.parent.document.getElementById("ncss_" + vsltubekey.idunico);
    } else {
      var btnOculto = document.getElementById("ncss_" + vsltubekey.idunico);
    }

    if (btnOculto != null)
      btnOculto.remove();

  } else {
    if (window.location.href.indexOf("srcdoc") != -1) {
      var btnOculto = window.parent.document.getElementById("vsltube_" + vsltubekey.idunico);
      var ncss = window.parent.document.getElementById("ncss_" + vsltubekey.idunico);
    } else {
      var btnOculto = document.getElementById("vsltube");
      var ncss = document.getElementById("ncss_" + vsltubekey.idunico);
    }

    //Verifica se o css que oculta os elementos já existe, e não exista insere via código.
    if (ncss == null) {
      if (!vsltubekey.showElement.startsWith(".") || !vsltubekey.showElement.startsWith("#")) {
        vsltubekey.showElement = "." + vsltubekey.showElement;
      }


      btnOculto.insertAdjacentHTML("beforeend", '<style id="ncss_' + vsltubekey.idunico + '">' + vsltubekey.showElement + ' { display:none !important;  }</style>');
    }
  }

  function hasClass(target, className) {
    if (target == null)
      return false;

    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
  }

  function addClass(target, className) {
    if (target != null)
      target.classList.add(className);
  }

  function removeClass(target, className) {
    if (target != null)
      target.classList.remove(className);
  }


  function addCSS(target, property, value) {
    if (target != null)
      target.style.setProperty(property, value);
  }

  var externalPlayer = null;

  var playerApi = {
    playVideo: function (container) {
      if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {
        //window.onYouTubePlayerAPIReady = function() {
        //   playerApi.loadPlayer(container);
        //};

        setTimeout(function () {
          if (typeof window.onYouTubePlayerAPIReady !== 'undefined') {
            if (typeof window.gambitOtherYTAPIReady === 'undefined') {
              window.gambitOtherYTAPIReady = [];
            }
            window.gambitOtherYTAPIReady.push(window.onYouTubePlayerAPIReady);
          }
          window.onYouTubePlayerAPIReady = function () {

            playerApi.loadPlayer(container);

            if (typeof window.gambitOtherYTAPIReady !== 'undefined') {
              if (window.gambitOtherYTAPIReady.length) {
                window.gambitOtherYTAPIReady.pop()();
              }
            }
          }
        }, 2);

        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


      } else {
        playerApi.loadPlayer(container);
      }
    },
    loadPlayer: function (containerid) {
      externalPlayer = new YT.Player(containerid, {
        // height: "390",
        // width: "640",
        //videoId: idvideo,
        playerVars: {
          //mute: 0,
          //controls: 0,
          //autohide: 1,
          //wmode: "opaque",
          //showinfo: 0,
          //loop: 1,
          //rel: 0,
          //playlist: ""
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange
          //onPlaybackQualityChange: onPlayerPlaybackQualityChange

        }
      });
    }
  };

  //window.onload = function(e){ 
  playerApi.playVideo("player");
  //}

  //var lastCurrentTime = 0;
  var done = false;
  var startLoadvar = null;
  var restartLoadvar = null;


  var pausapassou = false;
  var isStart = false;
  var isFirst = false;


  var fakePause = false;
  var pageCached = false;
  var old_lastCurrentTime;


  function loadPause() {


    startLoadvar = setInterval(function () {

      if (typeof externalPlayer.getCurrentTime === 'function') {


        /*
          
        if(Math.floor(externalPlayer.getCurrentTime()) < (lastCurrentTime - 3) || Math.floor(externalPlayer.getCurrentTime()) > (lastCurrentTime + 3)){
          
        
             if(vsltubekey.livemodeOn == 0)
                externalPlayer.mute();
          
          
            if(!hasClass(document.querySelector('.video-vsl'), "vfim")){
              
              console.log("last loadt: " +lastCurrentTime)
              externalPlayer.seekTo(lastCurrentTime, true);
            }
              
          
          
          
          
            } else {//vmute
              if(vsltubekey.livemodeOn == 0 && !hasClass(document.querySelector('.video-vsl'), "vmute"))  
                  externalPlayer.unMute();
            }
        
        */


      }




      var livetest = hasClass(document.querySelector('.video-vsl'), "video-vsl-live");
      var vpausetest = hasClass(document.querySelector('.video-vsl'), "vpause");
      if (livetest && vpausetest) {

        if (!pageCached) {
          if (!hasClass(document.querySelector('.video-vsl'), "vcookie")) {
            externalPlayer.playVideo();
          }
        }

        removeClass(document.querySelector('.video-vsl'), "vpause");
      }


    }, 1000);

  }



  window.onpageshow = function (event) {
    if (event.persisted) {


      //playerApi.playVideo("player");
      PageCached = true;
      old_lastCurrentTime = lastCurrentTime;

      //alert(document.querySelector('.video-vsl').classList.toString());
      document.querySelector('.box-load').style.cssText = "background:black;";

      if (document.querySelector('.video-vsl').classList.contains("vplayStart")) {
        if (vsltubekey.livemodeOn == 0) {
          removeClass(document.querySelector('.video-vsl'), "vpause");
          removeClass(document.querySelector('.video-vsl'), "vplay");
        }
        externalPlayer.loadVideoById(vsltubekey.idvideo, old_lastCurrentTime);

        clearInterval(startLoadvar);

        setTimeout(function () {
          loadPause();
        }, 5000);
      }


      if (document.querySelector('.video-vsl').classList.contains("vmute") && vsltubekey.livemodeOn == 1) {
        externalPlayer.loadVideoById(vsltubekey.idvideo, old_lastCurrentTime);

        setTimeout(function () {
          loadPause();
        }, 5000);
        clearInterval(startLoadvar);
      }


      if (document.querySelector('.video-vsl').classList.contains("vpause")) {

        // if (vsltubekey.pauseScreenBG == "")
        //   document.querySelector('.box-pause').style.cssText = 'background-image: url("https://cdn.vsltube.com/app/vsltube/vsltube-pause-default.png")';

        externalPlayer.cueVideoById(vsltubekey.idvideo, old_lastCurrentTime);
        fakePause = true;
      }
    }
  };




  function ativaPixelPorcentagem(porcentagem, pixelNomeEvento) {

    var win = window;
    if (window.location.href.indexOf("srcdoc") != -1)
      win = window.parent;

    if (win.fbq != undefined)
      win.fbq('trackCustom', pixelNomeEvento, { percentage: porcentagem });

    if (win.dataLayer != undefined)
      win.gtag('event', pixelNomeEvento, { "percentage": porcentagem });

    if (win._tfa != undefined)
      win._tfa.push({ notify: 'event', name: pixelNomeEvento, id: porcentagem });

    if (win.obApi != undefined)
      win.obApi('track', pixelNomeEvento + "-" + porcentagem);

    if (win.ttq != undefined)
      win.ttq.track('ViewContent', { content_name: pixelNomeEvento, value: porcentagem });

    if (win.kwaiq != undefined)
      win.kwaiq.track("contentView", { content_name: pixelNomeEvento, value: porcentagem });
  }


  function ativaPixelCTA(pixelNomeEvento) {

    var win = window;
    if (window.location.href.indexOf("srcdoc") != -1)
      win = window.parent;

    if (win.fbq != undefined)
      win.fbq('trackCustom', pixelNomeEvento, { cta: 1 });

    if (win.dataLayer != undefined)
      win.gtag('event', pixelNomeEvento, { "cta": 1 });

    if (win._tfa != undefined)
      win._tfa.push({ notify: 'event', name: pixelNomeEvento + "-cta", id: 1 });

    if (win.obApi != undefined)
      win.obApi('track', pixelNomeEvento + "-cta");

    if (win.ttq != undefined)
      win.ttq.track('ViewContent', { content_name: pixelNomeEvento + "-cta", value: 1 });

    if (win.kwaiq != undefined)
      win.kwaiq.track("contentView", { content_name: pixelNomeEvento + "-cta", value: 1 });
  }


  if (oldTime == null) {
    var oldTime = setInterval(function () {
      if (hasClass(document.querySelector('.video-vsl'), "vplayStart") && !hasClass(document.querySelector('.video-vsl'), "vpause") && !hasClass(document.querySelector('.video-vsl'), "vfim")) {


        //if(Math.floor(externalPlayer.getCurrentTime()) < (lastCurrentTime - 10) || Math.floor(externalPlayer.getCurrentTime()) > (lastCurrentTime + 10)){

        //
        if (Math.floor(externalPlayer.playerInfo.currentTime) != 0) {
          lastCurrentTime = lastCurrentTime + 1;


          // console.log(lastCurrentTime);

          if (externalPlayer.playerInfo.currentTime == (Math.floor(externalPlayer.getDuration() - 2)))
            addClass(document.querySelector('.video-vsl'), "vfim");

          jsCookies.set(vsltubekey.idunico + "vsltube_lastCurrentTime", lastCurrentTime, 365);
        }
      }


    }, 1000);
  }

  var inicio = false;
  var lastState;
  var autoClick = false;
  var isEnd = false;

  function onPlayerStateChange(event) {
    if (event.data != YT.PlayerState.PAUSED) {
      removeClass(document.querySelector('.video-vsl'), "vintro");
      removeClass(document.querySelector('.video-vsl'), "vcookie");
      addClass(document.querySelector('.video-vsl'), "vload");
    }

    if (event.data == YT.PlayerState.UNSTARTED) {
      if (inicio)
        removeClass(document.querySelector('.video-vsl'), "vintro");

      if (lastCurrentTime == 0) {
        var busca = setInterval(function () {
          if (!hasClass(document.querySelector('.video-vsl'), "vplayStart") && !inicio) {
            addClass(document.querySelector('.video-vsl'), "vintro");
            removeClass(document.querySelector('.video-vsl'), "vload");
            removeClass(document.querySelector('.video-vsl'), "vcookie");
            removeClass(document.querySelector('.video-vsl'), "vpause");
            removeClass(document.querySelector('.video-vsl'), "vfim");
            clearInterval(busca);
          }
        }, 1000);
      }
    }


    if (event.data == YT.PlayerState.PLAYING) {
      addClass(document.querySelector('.video-vsl'), "vplayStart");
      addClass(document.querySelector('.video-vsl'), "vplay");
      removeClass(document.querySelector('.video-vsl'), "vload");

      var videovsl = document.querySelector('.box-load');
      if (videovsl != null)
        videovsl.removeAttribute("style");

      inicio = true;

      if (!isStart) {
        if (vsltubekey.barraProgressoControle == 0)
          loadPause();

        isStart = true;
      }

      removeClass(document.querySelector('.video-vsl'), "vcookie");
      removeClass(document.querySelector('.video-vsl'), "vintro");
      removeClass(document.querySelector('.video-vsl'), "vpause");

      if (vsltubekey.livemodeOn == 0)
        removeClass(document.querySelector('.video-vsl'), "vfim");
    }

    if (event.data == YT.PlayerState.PAUSED) {
      //lastCurrentTime = Math.floor(externalPlayer.getCurrentTime());

      removeClass(document.querySelector('.video-vsl'), "vplayStart");
      addClass(document.querySelector('.video-vsl'), "vpause")
    }

    if (event.data == YT.PlayerState.ENDED) {

      isEnd = true;

      if (vsltubekey.livemodeOn == 0) {
        addClass(document.querySelector('.video-vsl'), "vfim");
        lastCurrentTime = 0;
        jsCookies.set(vsltubekey.idunico + "vsltube_lastCurrentTime", lastCurrentTime, 365); 
        externalPlayer.cueVideoById(vsltubekey.idvideo, 0);
      }

      if (lastCurrentTime > (Math.floor(externalPlayer.getDuration() - 7)))
        addClass(document.querySelector('.video-vsl'), "vfim")
    }
  }

  function onPlayerReady(event) {
    externalPlayer.setVolume(100);
    // jsCookies.set(vsltubekey.idunico + "vsltube_VideoDuration", externalPlayer.getDuration(), 365);

    if (lastCurrentTime == 0) {
      addClass(document.querySelector('.video-vsl'), "vintro");
    } else {
      if (vsltubekey.cookieOn == 1 && vsltubekey.livemodeOn == 0) {
        addClass(document.querySelector('.video-vsl'), "vcookie");

        if (!isEnd)
          removeClass(document.querySelector('.video-vsl'), "vload");

      } else {
        addClass(document.querySelector('.video-vsl'), "vintro");
      }

    }

    if (vsltubekey.livemodeOn == 1) {
      if (lastCurrentTime == externalPlayer.getDuration()) {
        addClass(document.querySelector('.video-vsl'), "vfim");
        removeClass(document.querySelector('.video-vsl'), "vload");
        addClass(document.querySelector('.video-vsl'), "vplay");
        removeClass(document.querySelector('.video-vsl'), "vintro");
      }

    }

    document.querySelector(".box-cookie-l").addEventListener("click", (e) => {
      lastCurrentTime = 0;
      inicio = false;
      jsCookies.set(vsltubekey.idunico + "vsltube_lastCurrentTime", lastCurrentTime, 365);
      externalPlayer.cueVideoById(vsltubekey.idvideo, 0);
      addClass(document.querySelector('.video-vsl'), "vload");
      removeClass(document.querySelector('.video-vsl'), "vintro");
    });

    document.querySelector(".box-vplay-mask").addEventListener("click", (e) => {
      var vpause = hasClass(document.querySelector('.video-vsl'), "vpause"),
        vmute = hasClass(document.querySelector('.video-vsl'), "vmute"),
        live = hasClass(document.querySelector('.video-vsl'), "video-vsl-live");

      if (live) {
        if (vmute) {
          removeClass(document.querySelector('.video-vsl'), "vmute");
          externalPlayer.unMute()
        } else {
          addClass(document.querySelector('.video-vsl'), "vmute");
          externalPlayer.mute();
        }

      } else {
        if (vpause) {
          if (fakePause) {
            removeClass(document.querySelector('.video-vsl'), "vplay");
            addClass(document.querySelector('.video-vsl'), "vload");
            removeClass(document.querySelector('.video-vsl'), "vpause");

            if (vsltubekey.pauseScreenBG == "")
              document.querySelector('.box-pause').style.cssText = "";

            fakePause = false;
          }
          externalPlayer.playVideo();
        } else {
          externalPlayer.pauseVideo();
        }
      }
    });

    document.querySelector(".box-fim-l").addEventListener("click", (e) => {
      lastCurrentTime = 0;
      inicio = false;
      jsCookies.set(vsltubekey.idunico + "vsltube_lastCurrentTime", lastCurrentTime, 365);
      externalPlayer.seekTo(lastCurrentTime);
      addClass(document.querySelector('.video-vsl'), "vload");
      removeClass(document.querySelector('.video-vsl'), "vintro");
    });

    document.querySelector(".box-fim-r").addEventListener("click", (e) => {
      vsltubekey.next?.()
    });

    updateTimerDisplay();

    clearInterval(time_update_interval);

    var time_update_interval = setInterval(function () {
      AtivaPixel();

      updateTimerDisplay();
      showtimefunc();
      if (document.getElementById("proginput") != null) {
        var barhover = document.getElementById("proginput").matches(':hover');
        if (barhover == false) {
          updateProgressBar();
        }
      }
    }, 100);

    loadPreview()
  }

  /*
  function onPlayerPlaybackQualityChange(event) {
      var playbackQuality = event.target.getPlaybackQuality();
      var suggestedQuality = 'small';
  
      console.log("Quality changed to: " + playbackQuality );
  
      if( playbackQuality !== 'small') {
          console.log("Setting quality to " + suggestedQuality );
          event.target.setPlaybackQuality( suggestedQuality );
      }
  }*/


  //-------------------BARRA E TEMPO DO VIDEO-------------------------
  var currentTime = document.getElementById('current-time');
  var duration = document.getElementById('duration');
  var progressBar = document.getElementById('proginput');

  var pixel25 = false;
  var pixel50 = false;
  var pixel75 = false;
  var pixel95 = false;


  function AtivaPixel() {

    if (typeof externalPlayer.getCurrentTime === 'function') {

      var duracao = (externalPlayer.getCurrentTime() / externalPlayer.getDuration()) * 100;



      try {

        if (duracao > 25 && duracao < 50 && !pixel25) {
          ativaPixelPorcentagem(25, vsltubekey.pixelNomeEvento);
          pixel25 = true;
        }

        if (duracao > 50 && duracao < 75 && !pixel50) {
          ativaPixelPorcentagem(50, vsltubekey.pixelNomeEvento);
          pixel50 = true;
        }

        if (duracao > 75 && duracao < 95 && !pixel75) {
          ativaPixelPorcentagem(75, vsltubekey.pixelNomeEvento);
          pixel75 = true;
        }

        if (duracao > 95 && !pixel95) {
          ativaPixelPorcentagem(95, vsltubekey.pixelNomeEvento);
          pixel95 = true;
        }

      } catch (e) {

      }

    }
  }

  function updateTimerDisplay() {
    try {
      currentTime.innerHTML = formatTime(externalPlayer.getCurrentTime());
      duration.innerHTML = formatTime(externalPlayer.getDuration());
    } catch (e) {

    }

    removeClass(document.querySelector('.vcontrol-group'), "force-hidden");

  }


  function updateProgressBar() {
    if (typeof externalPlayer.getCurrentTime === 'function') {
      const currentTime = (externalPlayer.getCurrentTime() / externalPlayer.getDuration()) * 100;

      if (vsltubekey.barraProgressoControle == 0) {
        if (currentTime <= 20) {
          progressBar.value = currentTime * 3;
        } else {
          var d = currentTime - 20;
          var b = d / 2;
          progressBar.value = 60 + b;
        }
      } else {
        progressBar.value = currentTime;
      }
    }

  }


  progressBar.addEventListener('change', function (e) {
    var newTime = Math.floor(externalPlayer.getDuration() * (e.target.value / 100));

    if (externalPlayer.getDuration() >= newTime)
      newTime = newTime - 3;

    lastCurrentTime = newTime;
    externalPlayer.seekTo(lastCurrentTime);

    setTimeout(function () {
      externalPlayer.seekTo(lastCurrentTime);
    }, 100);

  });


  function formatTime(time) {
    time = Math.round(time);

    var minutes = Math.floor(time / 60),
      seconds = time - minutes * 60;

    seconds = seconds < 10 ? '0' + seconds : seconds;

    return minutes + ":" + seconds;
  }


  var pixelCta = false;

  function loadPreview() {
    switch (vsltubekey.preview) {
      case "start": {
        clearState();
        addClass(document.querySelector('.video-vsl'), "vintro");
        break;
      }
      case "pause": {
        clearState();
        addClass(document.querySelector('.video-vsl'), "vplay");
        addClass(document.querySelector('.video-vsl'), "vpause")
        break;
      }
      case "continue": {
        clearState();
        addClass(document.querySelector('.video-vsl'), "vintro");
        if (vsltubekey.cookieOn == 1) {
          addClass(document.querySelector('.video-vsl'), "vcookie");
        }
        break;
      }
    }
  }

  function showtimefunc() {
    if (externalPlayer != null) {

      if (typeof externalPlayer.getCurrentTime === 'function') {
        var videotime = externalPlayer.getCurrentTime();
        if (videotime > vsltubekey.showTime) {

          //ativa o pixel do CTA
          try {
            if (!pixelCta) {
              ativaPixelCTA(vsltubekey.pixelNomeEvento);
              pixelCta = true;
            }

          } catch (ex) {

          }

          if (window.location.href.indexOf("srcdoc") != -1) {
            var btnOculto = window.parent.document.getElementById("ncss_" + vsltubekey.idunico);
          } else {
            var btnOculto = document.getElementById("ncss_" + vsltubekey.idunico);
          }

          if (btnOculto != null)
            btnOculto.remove();
          //cookie manter aberto
          jsCookies.set(vsltubekey.idunico + "vsltube_OpenBtn", "1", 365);
        }
      }

    }
  }

  //-------------------FIM - FULLSCREEN-------------------------

  function getFullscreenElement() {

    return document.fullscreenElement ||
      document.webkitFullscreenElement;

  }


  function toggleFullscreen() {

    if (window.location.href.indexOf("srcdoc") != -1) {


      var objFullFake = window.parent.document.getElementById("vsltube_" + vsltubekey.idunico);

      if (isSafari) {
        if (!hasClass(objFullFake, "fullscreenfake")) {
          addClass(objFullFake, "fullscreenfake");
        } else {
          removeClass(objFullFake, "fullscreenfake");
        }
      }


      var objFull = window.parent.document.getElementById("vsltube_iframe_" + vsltubekey.idunico);
      var objFullDoc = window.parent.document;
    } else {
      var objFull = document.getElementById("videovsl");
      var objFullDoc = document;
    }

    var full = hasClass(document.getElementById("videovsl"), "vvsl-fullscreen-act");

    if (full) {
      try {
        objFullDoc.exitFullscreen();
      } catch (e) {

      }

      removeClass(document.getElementById('videovsl'), "vvsl-fullscreen-act");
    } else {

      try {
        objFull.requestFullscreen().catch(console.log);
      } catch (e) {

      }

      addClass(document.getElementById('videovsl'), "vvsl-fullscreen-act");

    }

  }

  //---gambiarra

  if (window.location.href.indexOf("srcdoc") != -1) {

    window.parent.document.addEventListener('fullscreenchange', () => {
      if (window.parent.document.fullscreenElement) {
        //console.log('Fullscreen');
      } else {
        removeClass(document.getElementById('videovsl'), "vvsl-fullscreen-act");
      }
    });

  } else {
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        //console.log('Fullscreen');
      } else {
        removeClass(document.getElementById('videovsl'), "vvsl-fullscreen-act");
        //console.log('Normal');
      }
    });
  }



  //------------------botoes-------------------------

  document.getElementById("fullbtn").addEventListener("click", (e) => {
    toggleFullscreen();
  });


  document.getElementById("mutebtn").addEventListener("click", (e) => {
    if (externalPlayer.isMuted()) {
      externalPlayer.unMute();
      removeClass(document.getElementById('videovsl'), "vmute");
    } else {
      externalPlayer.mute();
      addClass(document.getElementById('videovsl'), "vmute");
    }

  });




  //videobar base----------------------------------------------------
  document.querySelector('.vslt-range input[type="range"]').addEventListener("mousemove", (e) => {
    addCSS(document.querySelector('.vslt-range-barra'), "width", e.target.value + '%');
  });


  //videobar control

  setInterval(function () {
    var con = hasClass(document.getElementById('progbar'), "fake-bar");
    if (con == false) {
      if (document.querySelector('.vslt-range input[type="range"]') != null) {
        document.querySelector('.vslt-range input[type="range"]').dispatchEvent(new Event("mousemove"));
      }
    }

  }, 100)

  function clearState() {
    removeClass(document.querySelector('.video-vsl'), "vplayStart");
    removeClass(document.querySelector('.video-vsl'), "vpause");
    removeClass(document.querySelector('.video-vsl'), "vload");
    removeClass(document.querySelector('.video-vsl'), "vcookie");
    removeClass(document.querySelector('.video-vsl'), "vintro");
  }

  function loadparametros() {
    try {
      removeClass(document.getElementById('videovsl'), "video-vsl-live");
      document.querySelectorAll(".force-hidden").forEach(fh => { fh.classList.remove("force-hidden"); })
      removeClass(document.getElementById('progbar'), "control-bar-off");
      removeClass(document.getElementById('progbar'), "fake-bar");

      document.querySelector('.video-vsl').style.cssText = 'border:' + vsltubekey.BW / 10 + 'em solid ' + vsltubekey.BC + '; max-width:' + vsltubekey.PW + 'px; margin: ' + vsltubekey.shadowW + 'px auto; box-shadow:0 0 ' + vsltubekey.shadowW + 'px ' + vsltubekey.shadowC + '; width: calc(100% - ' + vsltubekey.shadowW + 'px); border-radius: ' + vsltubekey.pRadius + 'px; ';



      if (vsltubekey.livemodeOn == 1) {
        addClass(document.getElementById('videovsl'), "video-vsl-live");
      } else {
        removeClass(document.getElementById('videovsl'), "video-vsl-live");
      }

      if (vsltubekey.fullscreenOn == 0) {
        addClass(document.getElementById('fullbtn'), "force-hidden");
      } else {
        removeClass(document.getElementById('fullbtn'), "force-hidden");
      }


      if (vsltubekey.timerOn == 0) {
        addClass(document.getElementById('timerdisplay'), "force-hidden");
      } else {
        removeClass(document.getElementById('timerdisplay'), "force-hidden");
      }


      if (vsltubekey.muteOn == 0) {
        addClass(document.getElementById('mutebtn'), "force-hidden");
      } else {
        removeClass(document.getElementById('mutebtn'), "force-hidden");
      }

      if (vsltubekey.fullscreenOn == 0 && vsltubekey.timerOn == 0 && vsltubekey.muteOn == 0) {
        addClass(document.getElementById('controlgroup'), "force-hidden");
      } else {
        removeClass(document.getElementById('controlgroup'), "force-hidden");
      }


      if (vsltubekey.barraProgressoOn == 0) {
        addClass(document.getElementById('progbar'), "force-hidden");
      }
      if (vsltubekey.barraProgressoControle == 0) {
        addClass(document.getElementById('progbar'), "control-bar-off");
      }
      if (vsltubekey.barraFake == 1) {
        addClass(document.getElementById('progbar'), "fake-bar");
        addCSS(document.querySelector('.vslt-range-barra'), "animation-duration", vsltubekey.barraFakeTime + 's');
        addCSS(document.querySelector('.vslt-range-barra'), "width", '100%');
      }

      addCSS(document.querySelector('.vslt-range'), "font-size", vsltubekey.barraAltura / 10 + 'em');

      //Controla os botões de play
      switch (vsltubekey.intro) {
        case '0':
          removeClass(document.getElementById('introplay'), "force-hidden");
          removeClass(document.getElementById('intromute'), "force-hidden");
          break;
        case '1':
          addClass(document.getElementById('introplay'), "force-hidden");
          document.getElementById("introtext1").innerHTML = vsltubekey.introText1;
          document.getElementById("introtext2").innerHTML = vsltubekey.introText2;
          break;
        case '2':
          document.querySelector('.box-intro *').innerHTML = "";
          break;
      }


      document.getElementById("cookieTitulo").innerHTML = vsltubekey.cookieTitulo;
      // document.getElementById("cookieTextoContinue").innerHTML = vsltubekey.cookieTextoContinue;
      document.getElementById("cookieTextoReiniciar").innerHTML = vsltubekey.cookieTextoReiniciar;

      document.getElementById("endScreenTxt").innerHTML = vsltubekey.endScreenText;

      addCSS(document.querySelector('.box-intro-center'), "background", vsltubekey.actionColor);
      addCSS(document.querySelector('.box-cookie-t'), "background", vsltubekey.cookieColor);
      addCSS(document.querySelector('.vslt-range-barra'), "background", vsltubekey.barraProgressoColor);

      if (vsltubekey.pauseScreenBG != "")
        addCSS(document.querySelector('.box-pause'), "background-image", 'url(' + vsltubekey.pauseScreenBG + ')');

      if (vsltubekey.beginScreenBG != "")
        addCSS(document.querySelector('.box-fim'), "background-image", 'url(' + vsltubekey.beginScreenBG + ')');

    } catch (e) {

    }
  }

  loadparametros();
  loadPreview()
}