import Rest from '@/services/Rest';

/**
 * @typedef {HomeService}
 */
export default class HomeService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'home'

    getEmptyState(){
        return this.get(`empty-state/get`);
    }

    disableEmptyState(key){
        return this.post(`empty-state/disableEmptyState`, {"key": key});
    }
}