export default {
	SET_COMBOS(state, combos) {
		state.combos = combos;
	},
	SET_DEACTIVATED_COMBOS(state, combos) {
		state.deactivatedCombos = combos;
	},
	DEACTIVATE_COMBO(state, pivotId) {
		const combos = state.combos ?? [];
		const combo = combos.find(combo => combo.pivot.id === pivotId);
		state.deactivatedCombos.push(combo);
	},
	REACTIVATE_COMBO(state, pivotId) {
		const deactivatedCombos = state.deactivatedCombos ?? [];
		const combo = deactivatedCombos.find(combo => combo.pivot.id === pivotId);
		state.combos.push(combo);
	},
};
