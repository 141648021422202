<template>
  <div class="search-list">
    <TextPlay class="p-2" v-if="items.length == 0">
      Nenhum resultado encontrado
    </TextPlay>
    <SearchItem
      v-if="items.length > 0"
      v-for="item in items"
      :key="item.id"
      :item="item"
      @click="() => $emit('item-click', item)"
    />
  </div>
</template>
<script>

import TextPlay from "../common/TextPlay.vue";
import SearchItem from "./SearchItem.vue";

export default {
  components: {
    SearchItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}

</script>

<style lang="scss" scoped>

.search-list {
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

}

.p-2 {
  padding: 1rem;
}

</style>
