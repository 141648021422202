import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();
import { convertTrigger } from "@/helpers.js";

export default {
	async fetchWebhooks({ commit }) {
		return new Promise(async resolve => {
			const response = await serviceCallback.read("?type=webhook");
			const list = [];

			response.reverse().forEach(item => {
				if (item.type == "webhook") {
					list.push({
						name: convertTrigger(item.trigger),
						sub_trigger: convertTrigger(item.sub_trigger),
						url: JSON.parse(item.args).url,
						id: item.id,
						obj: item,
					});
				}
			});

			commit("SET_WEBHOOKS", list);
			resolve(list);
		});
	},
	async deleteWebhook({ commit }, webhook) {
		return new Promise(async resolve => {
      let data = {
				id: webhook.id,
			};

      await serviceCallback.destroy(data);
			commit("DELETE_WEBHOOK", webhook);
			resolve();
		});
	},
};
