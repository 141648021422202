<template>
  <span>
    <modal-intro></modal-intro>
    <b-modal id="modal-intro-init" hide-footer>
      <div class="allContentModal">
        <div>
          <img class="filterColorImg" src="@/assets/icons/initintro.svg" alt="intro" />
        </div>
        <div class="spaceText1">
          <p class="text1">Vamos La!?</p>
        </div>
        <div class="spaceText2">
          <p class="text2">Você deseja realizar o Onboarding!</p>
        </div>
        <div>
          <button class="button2" @click="concluirIntro">Não Quero</button>
          <button class="button" @click="getIntrojs">Começar</button>
        </div>
      </div>
    </b-modal>
  </span>
</template>
<script>
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import ModalIntro from "@/components/ModalIntro.vue";
import Cookies from "js-cookie";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import { mapGetters } from "vuex";

export default {
  name: "Intro",
  components: {
    ModalIntro
  },
  data() {
    return {
      steps: [
        {
          element: "#BsXvZ",
          intro: "Olá, vou te apresentar a Plataforma, vamos lá?",
        },
        {
          element: "#step2",
          intro: "Aqui é o onde ficam os cursos disponíveis!",
        },
        {
          element: "#step3",
          intro: "Clique Aqui sempre que quiser criar um novo curso!",
        },
        {
          element: "#asasa",
          intro: "Agora vamos apresentar o menu!",
        },
        {
          element: "#slidebar",
          intro: "Esse aqui é o menu principal!",
        },
        {
          element: "#search",
          intro: "Aqui você pode procurar seus cursos, módulos e suas aulas!",
        },
        {
          element: "#home",
          intro: "Aqui serve para voltarmos pra dashboard!",
        },
        {
          element: "#certificado",
          intro: "Aqui conseguimos acessar nossos certificados!",
        },
        {
          element: "#comunidade",
          intro: "Aqui conseguimos gerenciar os comentários!",
        },
        {
          element: "#membros",
          intro: "Aqui vemos os membros do painel!",
        },
        {
          element: "#assinatura",
          intro: "Aqui gerenciamos as assinaturas!",
        },
        {
          element: "#helper",
          intro: "E aqui é o centro de apoio!",
        },
        {
          element: "#fhdsjfhkjds",
          intro: "Seja bem-vindo!",
        },
      ],
      client: {
        width: 0,
      },
      currentSiteId: null,
    };
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
      isMobile: "layout/getIsMobile",
    }),
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toHome() {
      this.$router.push("/home");
      setTimeout(() => {
        this.$root.$emit("bv::hide::modal", "modal-intro", "#btnShow");
      }, 1000);
    },
    getMetaTermos() {
      serviceMeta
        .search("keys[]=termsOfUseActivated")
        .then((resp) => {
          //console.log("meta termos", resp);
          var data = resp.termsOfUseActivated;
          if (data === "on") {
            this.verifyAcceptTerms();
          } else {
            this.getPermission();
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    verifyAcceptTerms() {
      this.$root.$emit("loadOn");

      //console.log("get site and member", resp);
      this.currentSiteId = this.getMe.current.currentSite.id;
      serviceMember
        .read("/meta?keys[]=_termos_" + this.currentSiteId)
        .then((resp) => {
          //console.log("get terms to painel", resp);
          const property = ("_termos_" + this.currentSiteId).toString();
          let typeView = Cookies.get("type_student");
          let permission = Cookies.get("access_level");

          if (permission === "admin" || permission === "owner") {
            if (typeView === 1 || typeView === "1") {
              this.$root.$emit("bv::show::modal", "modal-termos", "#btnShow");
            }
          } else {
            if (resp[property] !== "accepted") {
              this.$root.$emit("bv::show::modal", "modal-termos", "#btnShow");
            } else {
              this.getPermission();
            }
          }

          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });

    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getPermission() {
      let permission = Cookies.get("access_level");
      let typeView = Cookies.get("type_student");

      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === "1") {
          return false;
        }
        if (this.client.width > 576) {
          this.openModal();
        }
      }
    },
    openModal() {
      serviceMember.read("/meta?keys[]=onboarding").then((resp) => {
        //console.log("meta onboarding course", resp);
        var data = resp.onboarding;
        if (data === null) {
          this.$root.$emit("bv::show::modal", "modal-intro-init", "#btnShow");
        }
      });
    },
    concluirIntro() {
      this.$root.$emit("bv::hide::modal", "modal-intro-init", "#btnShow");
      var data = {
        id: `meta/onboarding`,
        value: "completed",
      };
      //console.log(data);
      serviceMember.postID(data).then((resp) => {
        //console.log("meta comments update", resp);
      });
    },
    getIntrojs() {
      var that = this;
      this.$root.$emit("bv::hide::modal", "modal-intro-init", "#btnShow");
      setTimeout(() => {
        let introJS = this.$intro();
        introJS.addSteps(this.steps);
        introJS.setOption("nextLabel", "Próximo");
        introJS.setOption("prevLabel", "Anterior");
        introJS.setOption("skipLabel", "Fechar");
        introJS.setOption("doneLabel", "Concluir");
        introJS.setOption("disableInteraction", true);
        introJS.setOption("showBullets", false);
        introJS.onexit(function (event) {
          //console.log('on exit', event);
        });
        introJS.oncomplete(function () {
          //console.log('completed');
          var data = {
            id: `meta/onboarding`,
            value: "completed",
          };
          //console.log(data);
          serviceMember.postID(data).then((resp) => {
            //console.log("meta comments update", resp);
            that.$root.$emit("completedtoopenmodalintro");
          });
        });
        introJS.onafterchange(function (e) {
          //console.log('after new step', e, this._currentStep);
          if (this._currentStep === 3) {
            document.getElementById("slidebar").classList.add("hoverbar");
          }
          if (this._currentStep === 10) {
            document.getElementById("slidebar").classList.remove("hoverbar");
          }
        });
        introJS.start();
      }, 1000);
    },
  },
  mounted() {
    if (process.env.VUE_APP_TYPE !== "DEV") {
      this.getMetaTermos();
    }
    this.getMetaTermos();
    this.$root.$on("completedtoopenmodalintro", (data) => {
      this.$root.$emit("bv::show::modal", "modal-intro", "#btnShow");
    });
  },
};
</script>

<style lang="scss">
span.introjs-helperNumberLayer {
  border: none;
  background: #000;
  color: #fff;
  text-shadow: none;
  border-radius: 3px;
  padding: 5px;
  margin-top: 20px;
  margin-left: -15px;
}

.introjs-tooltip {
  padding: 30px 45px;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}

.introjs-helperLayer {
  background: rgba(255, 255, 255, 0.2) !important;
}

.introjs-tooltiptext {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
}

.introjs-bullets ul li a {
  background: #ededf0;
  margin: 5px 0;
}

.introjs-bullets ul li a.active,
.introjs-bullets ul li a:hover {
  background: #002363;
}

.introjs-tooltipbuttons a {
  background: none;
  outline: none;
  text-shadow: none;
}

.introjs-tooltipbuttons a:hover {
  background: #ededf0;
  color: #000;
}

/* .introjs-bullets{
    pointer-events: none !important;
} */

#modal-intro-init {
  header {
    border: none;
  }

  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .text1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    text-align: center;
  }

  .text2 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #818181;
  }

  .button2 {
    background: #f7f7f7;
    border: none;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #81858e;
    width: 215px;
    height: 55px;
    margin-right: 15px;
  }

  .button {
    background: #000000;
    width: 215px;
    height: 55px;
    border-radius: 3px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: none;
  }
}
</style>
