<template>
	<div
		@click="onClick"
		class="arquive-card"
	>
		<div class="arquive-card__thumb">
			<ArchiveThumb
				borderRadius="8px 8px 0 0"
				:isIframe="false"
				:type="type"
				:src="sanitizedImage"
			/>
		</div>
		<div class="arquive-card__content">
			<TextPlay
				class="arquive-card__content__title"
				:text="truncate(title, { length: 100, omission: '...' })"
				size="sm"
				color="var(--text-color)"
			/>
		</div>

		<div
			v-if="typeof progress === 'number' || typeof progress === 'string'"
			class="arquive-card__progress"
		>
			<b-progress
				class="mt-2"
				max="100"
				height="4px"
			>
				<b-progress-bar
					height="4px"
					:value="Number(progress)"
				></b-progress-bar>
			</b-progress>
			<TextPlay
				class="mt-2"
				size="sm"
				color="var(--text-color)"
				:text="$t('media_management.uploading', { progress: Number(progress).toFixed(2) })"
			/>
			<TextPlay
				class="mt-1"
				size="sm"
				color="var(--fontcolor)"
				:text="status"
				weight="semibold"
			/>
		</div>
	</div>
</template>

<script>
import { truncate } from "lodash";
import ArchiveThumb from "@/components/ArquiveThumb.vue";

export default {
	name: "arquive-card",
	components: { ArchiveThumb },
	props: {
		img: String,
		title: String,
		progress: [Number, String],
		status: String,
		type: {
			type: String,
			default: "image",
		},
	},
	computed: {
		sanitizedImage() {
			const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
			const extension = this.img?.split(".").pop().toLowerCase();
			return imageExtensions.includes(extension) || this.type == "application/octet-stream" ? this.img : null;
		},
	},
	methods: {
		truncate,
		onClick() {
			this.$emit("click");
		},
	},
};
</script>

<style lang="scss" scoped>
.arquive-card {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		transform: translateY(-4px);
	}

	&__thumb {
		width: 100%;
		height: 138px;
		border-radius: 8px 8px 0 0;
	}

	&__content {
		border-radius: 0 0 8px 8px;
		background-color: var(--arquive-card-content-bg);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px;
		height: 92px;

		&__title {
			text-align: center !important;
			width: 100ch;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>
