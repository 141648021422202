export const getGtmConfig = (router) => {
  const id = process.env.GTM_IDS.split(";") || '';
  return {
    id,
    queryParams: {},
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: false,
  };
};
