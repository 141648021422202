<template>
    <b-modal id="modal-intro" hide-footer>
        <div class="allContentModal">
            <div>
                <img class="filterColorImg" src="@/assets/icons/intro.svg" alt="intro">
            </div>
            <div class="spaceText1">
                <p class="text1">Vamos La!?</p>
            </div>
            <div class="spaceText2">
                <p class="text2">Parabens por concluir o nosso onboarding!</p>
            </div>
            <div>
                <button class="button" @click="toHome">Começar</button>
            </div>
        </div>
    </b-modal>
</template>
<script>

export default {
    components: {
    },
    data(){
        return{
        }
    },
    methods:{
        toHome(){
            this.$router.push('/home');
            setTimeout(() => {
                this.$root.$emit("bv::hide::modal", "modal-intro", "#btnShow");
            }, 1000);
        }
    },
    mounted(){
    }
}
</script>
<style lang="scss">
#modal-intro{
    z-index: 99999999999;
    .modal-header{
        border: none;
    }
}
</style>
<style lang="scss" scoped>
#modal-intro{
    .filterColorImg{
        filter: var(--filterimg);
    }
    .allContentModal {
        padding: 0px 10px;
        margin: 0 auto;
        display: block;
        text-align: -webkit-center;
    }
    .spaceText2{
        margin-bottom: 20px;
    }
    .spaceText1{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .text1{
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        text-align: center;
    }
    .text2{
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #818181;
    }
    .button{
        background: #000000;
        width: 215px;
        height: 55px;
        border-radius: 3px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        border: none;
    }
}
</style>