
import UtilService from "@/services/resources/UtilService"
const serviceUtilService = UtilService.build()
import fp from "lodash/fp"


export default {
  async actionLoad({ dispatch, state, commit }) {
    if (state.loaded) {
      return
    }
    await dispatch("actionLoadMetas");
    commit("SET_LOADED", true)
  },
  async actionUnload({ commit }) {
    commit("SET_LOADED", false)
  },
  async actionLoadMetas({ dispatch, commit }) {
    const settingsFeched = await dispatch("config/actionFetchSettings", undefined, { root: true, })
    localStorage.setItem("mainColor", settingsFeched.main_color || "#000");
    const storage = localStorage.getItem("mainColor");
    commit("config/SET_MAIN_COLOR", storage, {
      root: true,
    });

    await dispatch("auth/actionFetchMe", undefined, { root: true });

    await Promise.all([
      dispatch("user/actionLoadMetas", undefined, { root: true }),
      dispatch("user/getMetasSite", undefined, { root: true }),
      dispatch("user/getCertificateConfig", undefined, { root: true }),
    ]);
  },
  async actionCheckUrlEmbed({ }, { url }) {
    return serviceUtilService.read(`/check-embed?url=${url}`)
  },

  async actionShowModal({ commit, state }, modal) {
    commit("SET_MODALS", [
      ...state.modals,
      modal
    ])
  },

  async actionCloseModal({ commit, state }) {
    commit("SET_MODALS", fp.dropRight(1, state.modals))
  }

};
