import store from "@/store/index";

async function isAuthenticated(to, from, next) {
  await store.dispatch("auth/actionLoginFromRDR");

  if (store.getters["auth/isAuthenticated"]) {
    return next();
  }

  return next("/");
}

const authentication = isAuthenticated;

export default authentication;
