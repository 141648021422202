<template>
  <b-modal id="modal-categoria" hide-footer size="xl">
    <div :class="{ createNewCategory: createNew, hide: !createNew }" class="animationOpacity2">
      <div class="contentAdd">
        <svg @click="close" class="close" width="17" height="18" viewBox="0 0 17 18" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M16 1.42603L1 16.426M1 1.42603L16 16.426" stroke="#B5B9C5" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>

        <div class="title">{{ title_modal }}</div>
        <div class="space">
          <b-form-group label="Nome da Categoria" label-for="name-category">
            <b-form-input v-model="nameCategory" placeholder="Nome da Categoria"></b-form-input>
          </b-form-group>
        </div>
        <div class="space" v-if="criar">
          <button class="btn-salvar" @click="salvar">Salvar</button>
        </div>
        <div class="space" v-else>
          <button class="btn-salvar" @click="salvarEditar">Salvar</button>
        </div>
      </div>
    </div>
    <b-row class="category">
      <b-col cols="12" md="10" class="mx-auto pt-1">
        <div class="flexHeader">
          <div>
            <div class="title">Listagem de Categorias</div>
            <div class="subtitle">Pesquise, crie e edite uma categoria</div>
          </div>
          <div>
            <button class="btn-NewCategoria" @click.prevent="NewCategory">
              Adicionar uma Categoria
            </button>
          </div>
        </div>
        <div class="lineDiv"></div>
        <div class="flexHeader">
          <div class="inputSearch">
            <!-- <svg @click="getCategory" class="Pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 13.0061L10.1 10.1061M11.6667 6.33944C11.6667 9.28496 9.27885 11.6728 6.33333 11.6728C3.38781 11.6728 1 9.28496 1 6.33944C1 3.39392 3.38781 1.0061 6.33333 1.0061C9.27885 1.0061 11.6667 3.39392 11.6667 6.33944Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <b-form-input @change="getCategory" v-model="searchCategory" placeholder="Pesquisar categoria"></b-form-input> -->
          </div>
          <div>
            <div class="totalCategory">
              {{ totalCategory }} Categorias encontradas
            </div>
          </div>
        </div>
        <div class="animationOpacity2">
          <div class="spaceTable">
            <div class="header">
              <div>Título Categoria</div>
              <div>Modificado em</div>
              <div>Ações</div>
            </div>
            <div class="itens">
              <draggable :list="AllCategory" :disabled="!enabled" class="list-group" ghost-class="ghost" @end="onEnd">
                <div v-for="item in AllCategory" :key="item.id" class="spaceItens" :data-id="item.id">
                  <div>
                    <div class="txt">{{ item.title }}</div>
                  </div>
                  <div>
                    <div class="txt">
                      {{ item.updated_at | moment("DD/MM/YYYY HH:mm") }}
                    </div>
                  </div>
                  <div class="actions">
                    <div class="editar" v-on:click="editar(item)">Editar</div>
                    <div class="deletar" v-on:click="deletar(item.id)">
                      Deletar
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import CategoryService from "@/services/resources/CategoryService";
const serviceCategory = CategoryService.build();
import draggable from "vuedraggable";
import notify from "@/services/libs/notificacao";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      searchCategory: "",
      AllCategory: [],
      totalCategory: "",
      createNew: false,
      nameCategory: "",
      criar: true,
      id_edit: null,
      title_modal: "Nova Categoria",
      rowClass: "row-table-body",
      emptyText: "Sem Resultados",
      filterOn: [],
      fieldsx: [
        { key: "title", label: "Título Categoria", sortable: true },
        { key: "updated_at", label: "Modificado em", sortable: true },
        { key: "id", label: "Ações" },
      ],
      enabled: true,
      dragging: false,
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    onEnd: function (e) {
      //console.log('event', e);

      setTimeout(() => {
        var array = [];
        var lessons = document.querySelectorAll(".spaceItens");
        for (let i = 0; i < lessons.length; i++) {
          const lessonId = lessons[i].getAttribute("data-id");
          array.push(parseInt(lessonId));
        }
        //console.log('xxxxx', array);
        let data = {
          id: "/order",
          array,
        };
        this.$root.$emit("loadOn");
        serviceCategory
          .postIDArray(data)
          .then((resp) => {
            //console.log("order module", resp, array);
            notify("sucesso", "Ordenação Salva!");
            this.getCategory();
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            notify("erro", "Erro ao Salvar Ordenação!");
            this.$root.$emit("loadOff");
          });
      }, 1000);
    },
    NewCategory() {
      this.title_modal = "Nova Categoria";
      this.createNew = true;
      this.criar = true;
      this.nameCategory = "";
    },
    close() {
      this.createNew = false;
    },
    getCategory() {
      this.$root.$emit("loadOn");
      serviceCategory
        .search()
        .then((resp) => {
          //console.log("category modal", resp);
          this.AllCategory = resp;
          this.totalCategory = resp.length;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    editar(data) {
      this.title_modal = "Editar Categoria";
      this.nameCategory = data.title;
      this.id_edit = data.id;
      this.createNew = true;
      this.criar = false;
    },
    salvarEditar() {
      this.$root.$emit("loadOn");
      let data = {
        id: this.id_edit,
        title: this.nameCategory,
      };
      serviceCategory
        .postID(data)
        .then((resp) => {
          //console.log("update category", resp);
          this.createNew = false;
          this.nameCategory = "";
          this.getCategory();
          notify("sucesso", "Categoria atualizada com Sucesso!");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao salvar Categoria!");
          this.$root.$emit("loadOff");
        });
    },
    deletar(id) {
      this.$root.$emit("loadOn");
      serviceCategory
        .destroy(id)
        .then((resp) => {
          //console.log("delete category", resp);
          this.getCategory();
          notify("sucesso", "Categoria excluída com Sucesso!");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao deletar Categoria!");
          this.$root.$emit("loadOff");
        });
    },
    salvar() {
      let name = this.nameCategory;
      this.$root.$emit("loadOn");
      serviceCategory
        .create({
          title: name,
        })
        .then((resp) => {
          //console.log("create category", resp);
          this.getCategory();
          notify("sucesso", "Categoria Criada com Sucesso!");
          this.nameCategory = "";
          this.createNew = false;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Criar Categoria!");
          this.$root.$emit("loadOff");
        });
    },
  },
  mounted() {
    this.$root.$on("bv::show::modal", (data) => {
      if (data === "modal-categoria") {
        this.getCategory();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
#modal-categoria {
  .spaceTable {
    margin-top: 30px;

    .header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 25px;

      div {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
      }
    }

    .itens::-webkit-scrollbar {
      background-color: #fff;
      width: 2px;
    }

    .itens::-webkit-scrollbar-track {
      background-color: #c7c7c7;
    }

    .itens::-webkit-scrollbar-thumb {
      background-color: #4d4d4d52;
      border-radius: 31px;
    }

    .itens::-webkit-scrollbar-button {
      display: none;
    }

    .itens {
      height: 38vh;
      overflow: auto;

      .spaceItens {
        cursor: grab;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: 40px;
        border-bottom: 0.5px solid #ededf0;
        margin: 15px 5px;

        .txt {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }

        .actions {
          display: flex;

          .deletar {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 0.2px;
            color: #ff0c37;
            cursor: pointer;
          }

          .editar {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 0.2px;
            color: #81858e;
            margin-right: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .createNewCategory {
    position: fixed;
    background: var(--fundomodal);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999999;

    .contentAdd {
      z-index: 9999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: var(--backgroundcolor);
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;

      .close {
        cursor: pointer;
      }

      .space {
        margin-top: 40px;
        margin-bottom: 40px;

        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }

        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }

        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }

        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }

      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }

  .hide {
    display: none !important;
  }

  .table {
    margin-top: 40px;

    th {
      border: none !important;
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      letter-spacing: 0.2px !important;
      color: #333 !important;
    }

    tr.row-table-body {
      line-height: 40px !important;
    }

    td {
      border-top: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
    }

    .table thead th {
      border: none !important;
    }

    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.2px;
      color: #81858e;
    }

    .flex {
      display: flex;

      .deletar {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #ff0c37;
        cursor: pointer;
      }

      .editar {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #81858e;
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }

  .totalCategory {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #81858e;
    margin-top: 30px;
  }

  .inputSearch {
    display: flex;
    margin-right: 30px;
    margin-top: 5px;

    input {
      width: 34em !important;
      height: 45px !important;
      box-sizing: border-box !important;
      border-radius: 0px !important;
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      border: none;
      color: #333 !important;
      padding-left: 45px !important;
      background: #fff !important;
      border-bottom: solid 1px var(--bordercolor) !important;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03) !important;
    }

    svg {
      position: relative;
      left: 30px;
      top: 15px;
    }
  }

  .category {
    .lineDiv {
      height: 0.5px;
      background: #e5e5e5;
      transform: matrix(1, 0, 0, -1, 0, 0);
      width: 100%;
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  .btn-NewCategoria {
    width: 240px;
    height: 45px;
    background: var(--maincolor);
    border-radius: 30px;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
  }

  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    margin-bottom: 5px;
  }

  .subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #81858e;
  }

  .flexHeader {
    display: flex;
    justify-content: space-between;
  }

  .modal-header {
    border: none !important;
  }
}</style>
