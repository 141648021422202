// mutations.js

import Cookies from "js-cookie";
import defineMainColor from "@/store/modules/config/utils.js";

export default {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_MODE_ADMIN(state, admin) {
    state.admin = admin;
  },
  SET_CONFIG(state, settings) {
    state.settings = settings;
  },
  SET_MAIN_COLOR(state, payload) {
    defineMainColor(payload);
  },
  SET_GTM(state, gtm) {
    state.gtm = gtm;
  },
  SET_THEME(state, theme) {
    state.theme = theme;
    document.querySelector("html").setAttribute("data-theme", state.theme);
    Cookies.set("themeClub", state.theme, { expires: 365 });
  },
  SET_NPS_LINK(state, link) {
    state.linkResearchNPS = link;
  },
};
