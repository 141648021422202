import Cookies from "js-cookie";

export default {
  metasSite: (state) => state.metasSite,
  certificateConfig: (state) => state.certificateConfig,
  currentSite: (state) => state.currentSite,
  hasEssayPermission: (state) => state.hasEssayPermission,
  getExistConfigCert: (state) => state.existConfigCert,
  studentCantView: () => {
    return Cookies.get("type_student") == 1 &&
      Cookies.get("access_level") == "student"
      ? false
      : true;
  },
  getTypeView: () => {
    return Cookies.get("access_level");
  },
  hasPermission: (state) => {
    const role = state?.currentSite?.currentMember?.role;

    if (role === "admin" || role === "owner") {
      return true;
    } else {
      return state.hasPermission;
    }
  },
  getMetas: (state) => state.metas,
};
