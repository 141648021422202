export default {
  getSelectedExercise: (state) => state.selectedExercise,
  getExercisesAreas: (state) => state.exercisesAreas,
  getExercises: (state) => state.exercises,
  getAllCategories: (state) =>
    state.allCategories.filter((category) => category.has_exercises),
  getSelectedCategory: (state) => state.selectedCategory,
  getSubCategory: (state) => state.subCategory,
  getAreasFromCategory: (state) => {
    const exercisesAreas = state.exercisesAreas || [];
    if (!state.selectedCategory) return exercisesAreas;
    return exercisesAreas.filter(
      (exercise) => exercise.area_id === state.selectedCategory.id
    );
  },
};
