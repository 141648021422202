<template>
  <b-spinner class="loading" :style="cssVars"></b-spinner>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '3rem',
    },
    color: {
      type: String,
      default: 'var(--maincolor)',
    },
  },
  computed: {
    cssVars() {
      return {
        '--size': this.size,
        '--color': this.color
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.loading {
  width: var(--size);
  height: var(--size);
  color: var(--color);
}

</style>
