<template>
	<div
		class="textarea-play-container"
		:class="{
			...computedClass,
			'textarea-play-container--is-valid': !errors.has(name),
			'textarea-play-container--is-invalid': errors.has(name),
		}"
		:style="textareaContainerStyle"
	>
		<textarea
			:id="id"
			:disabled="disabled"
			:value="value"
			:readonly="readonly"
			class="textarea-play"
			:name="name"
			:min="min"
			:max="max"
			:rows="rows"
			:cols="cols"
			:heightByWrite="heightByWrite"
			:oninput="oninput"
			:placeholder="placeholder"
			:maxlength="maxlength"
			onwheel="this.blur()"
			autocomplete="off"
			@input="$emit('input', $event.target.value)"
			@keydown.enter="$emit('enter')"
		/>
	</div>
</template>

<script>
export default {
	$_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    }
  },
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		name: String,
		placeholder: String,
		validation: [String, Object],
		value: [String, Number],
		id: String,
		heightByWrite: {
			type: Boolean,
			default: false,
		},
		rows: [String, Number],
		cols: [String, Number],
		skeleton: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		w: {
			type: String,
			default: "100%",
		},
		maxlength: {
			type: [String, Number],
			default: 100,
		},
		min: {
			type: [String, Number],
		},
		oninput: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		max: {
			type: [String, Number],
		},
	},
	computed: {
		computedClass() {
			return {
				"skeleton-play textarea-play-container--skeleton": this.skeleton,
				"textarea-play-container--disabled": this.disabled,
			};
		},
		textareaContainerStyle() {
			return {
				width: this.w,
			};
		},
		handleValue() {
			return this.manageCleanups(this.value);
		},
	},
	mounted() {
		const textarea = this.$el.querySelector("textarea");

		textarea.addEventListener("keyup", e => {
			textarea.style.height = "auto";
			let scrollHeight = e.target.scrollHeight;
			textarea.style.height = scrollHeight + "px";
		});
	},
};
</script>

<style lang="scss" scoped>
.textarea-play-container {
	display: flex;
	align-items: center;
	border: 1px var(--input-border-color) solid;
	border-radius: 8px;
	width: 100%;
	min-height: 48px;
	padding: 12px;

	&--is-valid {
		border-color: var(--input-border-color) !important;
	}

	&--is-invalid {
		border-color: var(--feedback-error-500, #ff3932) !important;
	}

	&--skeleton {
		border-radius: 4px;
	}

	&--disabled {
		input {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	.textarea-play {
		border: none !important;
		border-radius: 8px;
		width: 100%;
		height: 100%;
		background: transparent !important;
		font-weight: normal;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
		}
	}
}
</style>
