<template>
	<div
		:id="id"
		class="tabs-play d-flex flex-column"
	>
		<div class="tabs-play__tabs d-flex">
			<div
				v-for="(tab, index) in tabs"
				:key="index"
				class="tabs-play__tabs__tab d-flex align-items-center py-2"
				:class="tabClasses(index)"
				@click="handleClick(index)"
			>
				<IconSax
					v-if="tab.icon"
					:primary="value == index"
					:name="tab.icon"
					size="1rem"
					class="mr-2"
				/>
				<TextPlay
					tag="span"
					weight="bold"
					:size="textSize"
					:text="tab.label"
				/>
			</div>
		</div>

		<div class="tabs-play__content mt-3">
			<slot :name="value">{{ value }}</slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			id: `tabs-play-${this._uid}`,
		};
	},
	props: {
		tabs: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Number,
			default: 0
		},
		enableToNextTab: {
			type: Boolean,
			default: false,
		},
		enableToBackTab: {
			type: Boolean,
			default: false,
		},
		enableAllTabs: {
			type: Boolean,
			default: false,
		},
		enablePreviousTabs: {
			type: Boolean,
			default: false,
		},
		enableNextTabs: {
			type: Boolean,
			default: false,
		},
		enabledTabs: {
			type: Array,
			default: () => [],
		},
		textSize: {
			type: String,
			default: "base",
			validator: value =>
				["xxs", "xs", "sm", "base", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl", "8xl", "9xl"].includes(
					value
				),
		}
	},
	methods: {
		goToTop() {
			const layout = document.querySelector(`.layout-page__overflow`);
			const tabsElement = document.querySelector(`#${this.id}`);

			if (layout && tabsElement) {
				layout.scrollTo({
					top: tabsElement.offsetTop - 61,
					behavior: "instant",
				});
			}
		},
		changeTab(index) {
			this.$emit("input", index);
		},
		handleClick(index) {
			const currentIndex = this.value;

			if (
				(currentIndex !== index && this.enableAllTabs) ||
				(this.enablePreviousTabs && index < this.value) ||
				(this.enableNextTabs && index > this.value)
			) {
				this.changeTab(index);
			}

			if ((this.enableToBackTab && index < currentIndex) || this.enabledTabs.includes(index)) {
				this.changeTab(index);
			}

			if ((index === currentIndex + 1 && this.enableToNextTab) || this.enabledTabs.includes(index)) {
				this.changeTab(index);
			}
		},
		isTabEnabled(index) {
			const currentIndex = this.value;
			if (index == currentIndex) return false;

			if (
				this.enableAllTabs ||
				this.enabledTabs.includes(index) ||
				(this.enablePreviousTabs && index < this.value) ||
				(this.enableNextTabs && index > this.value)
			)
				return true;

			if (this.enableToBackTab && index < currentIndex) {
				return true;
			}

			if (index === currentIndex + 1 && this.enableToNextTab) {
				return true;
			}

			return false;
		},
		tabClasses(index) {
			let isDisabled =
				!(this.value == index) &&
				!this.isTabEnabled(index) &&
				!this.enableAllTabs &&
				!this.enabledTabs.includes(index) &&
				!(this.enablePreviousTabs && index < this.value) &&
				!(this.enableNextTabs && index > this.value);

			return {
				[`tab__${index}`]: true,
				[`tab__${this.tabs?.[index]?.id}`]: true,
				"tabs-play__tabs__tab--active": this.value == index,
				"tabs-play__tabs__tab--disabled": isDisabled,
				"tabs-play__tabs__tab--closed": this.isTabEnabled(index) && !isDisabled && !(this.value == index),
			};
		},
	},
	watch: {
		value() {
			this.goToTop();
		}
	},
	mounted() {
		const tab = this.$route.query.tab || this.value;
		this.handleClick(tab);
	},
};
</script>

<style lang="scss">
.tabs-play {
	p,
	span {
		color: var(--fontcolor);
	}

	&__tabs {
		gap: 2rem;

		&__tab {
			font-weight: bold;
			cursor: pointer;

			&--active {
				border-bottom: 2px solid var(--maincolor);
			}

			&--disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}

			&--closed {
				opacity: 0.5;
			}
		}
	}
}
</style>
