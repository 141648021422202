<template>
    <b-modal id="modal-certificate-available" hide-backdrop no-close-on-backdrop hide-footer>
      <template #modal-header="{ close }">
        <div></div>
        <div class="modal-certificate-available__close" @click="closeModal()">
          <IconSax name="close-circle" type="outline" />
        </div>
      </template>
      <div class="modal-certificate-available">
        <div class="modal-certificate-available__hero">
          <IconSax name="medal-star" type="bold" />
        </div>
        <div class="modal-certificate-available__title">
          {{ $t('modal_certificate_available.title') }}
        </div>
        <div class="modal-certificate-available__percent">
          {{ $t('modal_certificate_available.percent') }}
        </div>
      </div>
    </b-modal>
  </template>
  <script>
  import CertificateExtensionService from "@/services/resources/CertificateExtensionService";
  const certificateExtensionService = CertificateExtensionService.build();
  import { mapGetters } from "vuex";
  export default {
    components: {},
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters({
        getCurrentSite: "user/currentSite"
      })
    },
    methods: {
        closeModal() {
        certificateExtensionService
        .saveCertificate(this.getCurrentSite.currentSite.id, this.getCurrentSite.currentMember.id, this.$route.params.courseId)
        .then((resp) => {
          this.$root.$emit("bv::hide::modal", "modal-certificate-available");
        })
        .catch((err) => {
          console.log(err);
          });
        },
    },
  };
  </script>
  
  <style lang="scss">
  #modal-certificate-available {
    .modal-header {
      border: none;
      padding: 16px 15px 0px 15px !important;
    }
    .modal-content {
      padding: 0px !important;
      min-height: 430px;
      max-width: 430px;
      background: var(--backgroundcolor) !important;
      border-radius: 8px;
    }
    .modal-body {
      padding: 0px 0;
    }
  }
  </style>
  
  <style lang="scss" scoped>
  .modal-certificate-available  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 40px 69px 40px 69px;
    text-align: center;
    font: var(--body-regular-16);
    color: var(--opposite-color);
  
    &__close {
      cursor: pointer;
  
      &:hover {
        opacity: 0.7;
      }
  
      ::v-deep {
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: var(--fontcolor);
          }
        }
      }
    }
  
    &__hero {
      ::v-deep svg {
        width: 80px;
        height: 80px;
        path {
          fill: var(--maincolor);
        }
      }
    }
  
    &__title {
      font: var(--body-bold-24);
    }
  
    &__action {
      font: var(--body-semibold-12);
  
      &--button {
        cursor: pointer;
  
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  </style>
  