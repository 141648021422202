// state.js
export default {
  startDate: null,
  totalWeeks: null,
  weekNumber: 1,
  currentWeekNumber: 1,
  modules: {},
  hoursPerWeek: null,
  language: null,
  includeBasicMath: null,
};

