<template>
  <div class="isax svg-css" :style="cssVars" data-testid="icon-sax" @click="$emit('click')">
    <inline-svg :class="{ 'svg-inner': true, [type]: true, color, primary }"  :src="get()" :width="size" :height="size" />
  </div>
</template>

<script type="text/javascript">
import InlineSvg from 'vue-inline-svg';

export default {
  components: { InlineSvg },
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: (value) => ["outline", "bold", "twotone", "broken", "linear", "bulk"].includes(value),
      default: () => "outline",
    },
    color: {
      type: String,
    },
    primary: Boolean,
    size: {
      type: String,
      default: "1.3rem"
    },
    package: {
      type: String,
      validator: (value) => ["iconsax", "iconsax-crypto"].includes(value),
      default: () => "iconsax",
    }
  },
  methods: {
    get() {
      if(this.package && this.type && this.name){
        return process.env.VUE_ASSETS_URL+`/icons/${this.package}/${this.type}/${this.name}.svg`;
      }

      return false;
    }
  },
  computed: {
    cssVars() {
      return {
        '--color': this.color
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.svg-css {
  display: flex;
  align-items: center;
}

::v-deep .svg-inner path {
  fill: var(--fontcolor);
}

::v-deep .svg-inner.primary path {
  fill: var(--maincolor);
}

::v-deep .svg-inner.color path {
  fill: var(--color);
}
</style>
