export function textLimit(text, limit) {
    if (text.length > limit) {
        return text.substring(0, limit) + "...";
    } else {
        return text;
    }
}

export function sanitize(text) {
    return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/gu, "")
        .replace(/[\p{P}\p{S}]/gu, "")
        .trim()
        .replaceAll(" ", "-")
        .toLowerCase();
}

export default {
    textLimit,
    sanitize,
};
