<template>
  <div>
    <Modal
      :id="id"
      :visible="visible"
      :title="$t('media_management.title')"
      :subtitle="$t('media_management.subtitle')"
      hide-footer
      size="xl"
    >
      <div class="upload-modal">
        <div class="upload-modal__tabs d-flex">
          <template v-if="notLinks === 'notlinks'">
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'upload' }" @click="tabs('upload')">
              <IconSax size="1.2rem" type="bold" name="document-upload" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Enviar arquivo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'library' }" @click="tabs('library')">
              <IconSax size="1.2rem" type="bold" name="folder-open" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Biblioteca de mídias" />
            </div>
          </template>

          <template v-else-if="notLinks === 'links'">
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'panda' }" @click="tabs('panda')" v-if="hasPanda">
              <IconSax size="1.2rem" type="bold" name="document-upload" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Enviar arquivo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'library' }" @click="tabs('library')" v-if="hasPanda">
              <IconSax size="1.2rem" type="bold" name="folder-open" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Biblioteca de mídias" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'voomptube' }" @click="tabs('voomptube')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="video-play" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Voomp Tube" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'linkvideo' }" @click="tabs('linkvideo')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="link-circle" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Link de Vídeo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'vimeo' }" @click="tabs('vimeo')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="play-circle" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Vimeo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" @click="removeLogin()" v-if="connectGoogleId !== null || connectVimeoId !== null">
              <IconSax size="1.2rem" type="bold" name="back-square" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Voltar" />
            </div>
          </template>

          <template v-else-if="notLinks === 'integrations'">
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'youtube' }" @click="tabs('youtube')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="video-play" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="YouTube" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'vimeo' }" @click="tabs('vimeo')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="play-circle" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Vimeo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" @click="removeLogin()" v-if="connectGoogleId !== null || connectVimeoId !== null">
              <IconSax size="1.2rem" type="bold" name="back-square" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Voltar" />
            </div>
          </template>

          <template v-else>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'panda' }" @click="tabs('panda')" v-if="hasPanda">
              <IconSax size="1.2rem" type="bold" name="document-upload" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Enviar arquivo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'library' }" @click="tabs('library')" v-if="hasPanda">
              <IconSax size="1.2rem" type="bold" name="folder-open" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Biblioteca de mídias" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'youtube' }" @click="tabs('youtube')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="video-play" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="YouTube" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" :class="{ 'upload-modal__tabs__tab--active': active == 'vimeo' }" @click="tabs('vimeo')" v-if="typeLesson === 'VIDEO'">
              <IconSax size="1.2rem" type="bold" name="play-circle" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Vimeo" />
            </div>
            <div class="upload-modal__tabs__tab d-flex" @click="removeLogin()" v-if="connectGoogleId !== null || connectVimeoId !== null">
              <IconSax size="1.2rem" type="bold" name="back-square" />
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" text="Voltar" />
            </div>
          </template>
        </div>

        <div class="upload-modal__panda-storage my-3 w-32 d-flex flex-column" v-if="active === 'panda' && notLinks !== 'integrations'">
          <b-progress class="plans" :max="100">
            <b-progress-bar :value="value"></b-progress-bar>
          </b-progress>
          <TextPlay class="mt-2" size="sm" color="var(--fontcolor)" :text="`${value2} / ${value1}`"/>
        </div>

        <div class="upload-modal__panda-upload upload-area" v-if="active === 'panda'">
          <b-form-file id="arquivo" name="arquivo" class="panda-upload" :class="{ 'd-none': hasFiles }" :accept="typeUpload" plain multiple @change="changeFiles" v-validate="'required'"></b-form-file>
          <!-- accept=".pdf,.zip,.rar,.7zip" -->
          <div class="upload-area__drop" v-if="!hasFiles">
            <IconSax size="40px" color="var(--fontcolor)" name="gallery-add" />
            <TextPlay color="var(--fontcolor)" size="lg" weight="semibold" :text="$t('media_management.drag_and_drop')" />
            <TextPlay color="var(--fontcolor)" size="sm" :text="$t('media_management.or')" />
            <ButtonPlay
              p="16px"
              :text="$t('media_management.select_arquives')"
              type="normal"
              @click="callPandaUpload"
            />
            <TextPlay class="mt-4" color="var(--fontcolor)" size="sm" v-html="$t('media_management.max_size', {size: '80mb'})" />
          </div>

          <div v-if="hasFiles" class="upload-area__uploading mt-3">
            <div class="d-flex mb-3">
              <div @click="clearList()" class="d-flex align-items-center cursor-pointer">
                <IconSax name="undo" />
                <TextPlay class="ml-2" color="var(--fontcolor)" size="sm" :text="$t('media_management.back')" />
              </div>
            </div>
            <div class="cards-grid">
              <ArquiveCard
                v-for="(file, index) in files"
                :key="file.name+index"
                :title="typeLesson === 'AUDIO'? getNameAudio(index) : file.name"
                :progress="file.porcentage ?? 0"
                :status="file.textstatus"
              />
            </div>
          </div>  
        </div>

        <div class="upload-modal__selected-tab">
          <div v-if="active == 'upload'">
            <UploadComponent :id="id" sizex="80mb" :acceptedFileExtensions="allowedExtensions" :postAction="returnApi() + '/media'" />
          </div>
          <div class="library-tab" v-if="active == 'library'">
            <div class="library-tab__filters">
              <InputPlay
                class="library-tab__filters__search"
                @change="getMidiasNotConnection()"
                v-model="searchMidias"
                :placeholder="$t('media_management.search_media')"
                leftIcon="search-normal-1"
                @leftIconClick="getMidiasNotConnection()"
                @enter="getMidiasNotConnection()"
              />
              <SelectPlay
                name="filter-library"
                class="library-tab__filters__select"
                @input="changeLibrarySelect" 
                v-model="mediaTypeSelect" 
                :placeholder="$t('select')"
                :options="librarySelectOptions"
              />
            </div>
            <div class="library-tab__content">
              <div class="library-tab__content__title">
                <TextPlay color="var(--fontcolor)" weight="semibold" size="lg" :text="filteredLibraryTitle" />
                <PaginationPlay
                  v-if="!isLoadingMedia && AllMedias.data.length > 0"
                  @onSelectPage="changePage"
                  @onPrev="selectPageMedia('prev')"
                  @onNext="selectPageMedia('next')"
                  :total="AllMedias.total"
                  :currentPage="pageMedia"
                  :perPage="AllMedias.per_page"
                  maxVisible="4"
                />
              </div>
              <div v-if="!isLoadingMedia && AllMedias.data.length > 0" class="library-tab__content__cards-grid cards-grid">
                <ArquiveCard
                  v-for="(media, index) in AllMedias.data"
                  :key="index"
                  :title="media.title"
                  :type="media.mime"
                  :img="media.thumbnail || media.large_horizontal || media.cdn_url"
                  @click="selectMidiasUploaded(media)"
                  :data-url="media.cdn_url"
                  :data-id="media.id"
                />
              </div>
              <div v-else-if="isLoadingMedia" class="library-tab__content__loading">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <div class="library-tab__content__not-found" v-else>
                <TextPlay color="var(--textcolor)" :text="$t('media_management.not_found')" />
              </div>
              <div class="library-tab__content__pagination">
                <PaginationPlay
                  v-if="!isLoadingMedia && AllMedias.data.length > 0"
                  @onSelectPage="changePage"
                  @onPrev="selectPageMedia('prev')"
                  @onNext="selectPageMedia('next')"
                  :total="AllMedias.total"
                  :currentPage="pageMedia"
                  :perPage="AllMedias.per_page"
                  maxVisible="4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-row class="upload">
        <b-col cols="12">
          <div>
            <b-row class="spaceImageslib" v-if="active == 'youtube'">
              <b-col cols="12" md="12" class="d-flex image">
                <div class="listLinksVideos">
                  <div v-if="connectGoogleId === null">
                    <b-list-group v-for="(google, index) in GoogleConnections" :key="google.id+index">
                      <b-list-group-item class="listIntegrationYt" @click="selectGoogleConnect(google.id)"
                        style="cursor: pointer">{{ google.id }} - {{ google.title }}</b-list-group-item>
                    </b-list-group>
                    <b-form-group label="Integração com o Google" label-for="name-module">
                      <button class="btnIntegraçãoVimeo" @click="openWindowGoogle">
                        Fazer Integração
                      </button>
                      <!-- <btn-google></btn-google> -->
                    </b-form-group>
                    <iframe v-if="openIntFrame" height="0" width="0" :src="srcIframe"></iframe>
                  </div>
                  <div v-else>
                    <div class="inputSearch">
                      <IconSearch name="search-normal" size="1rem" @click="getVideoYt('search')" class="Pointer search" />
                      <b-form-input class="input-busca" @change="getVideoYt('search')" v-model="searchVideo"
                        placeholder="Pesquise seu video"></b-form-input>
                    </div>
                    <div class="gridListen">
                      <b-list-group v-for="(googlemidias, index) in MidiasGoogle" :key="googlemidias.id.videoId+index">
                        <b-list-group-item @click="selectUrlYoutube(googlemidias)" class="listYt">
                          <div>
                            <img v-if="googlemidias.snippet" loading="lazy" class="imgVideo"
                              :src="googlemidias.snippet.thumbnails.default.url"
                              :alt="googlemidias.snippet.thumbnails.default.url" />
                          </div>
                          <div v-if="googlemidias.snippet" class="titleVideo">
                            {{ googlemidias.snippet.title }}
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                    <div class="paginacao">
                      <div class="txtPaginacao" @click="selectPageYt('prev')">
                        Anterior
                      </div>
                      <div class="txtPaginacao" @click="selectPageYt('next')">
                        Proxima
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="spaceImageslib" v-if="active == 'vimeo'">
              <b-col cols="12" md="12" class="d-flex image">
                <div class="listLinksVideos">
                  <div v-if="connectVimeoId === null">
                    <b-list-group v-for="(vimeo, index) in VimeoConnections" :key="vimeo.id+index">
                      <b-list-group-item class="listIntegration" @click="selectVimeoConnect(vimeo.id)"
                        style="cursor: pointer">{{ vimeo.id }} - {{ vimeo.title }}</b-list-group-item>
                    </b-list-group>
                    <b-form-group label="Integração com o Vimeo" label-for="name-module">
                      <button class="btnIntegraçãoVimeo" @click="openLinkIntegrationVimeo">
                        Fazer Integração
                      </button>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <div class="inputSearch">
                      <IconSax name="search-normal" size="1rem" @click="getVideoVimeo" class="Pointer search" />
                      <b-form-input class="input-busca" @change="getVideoVimeo" v-model="searchVideo"
                        placeholder="Pesquise seu video"></b-form-input>
                    </div>
                    <div class="gridListen">
                      <b-list-group v-for="(vimeomidias, index) in MidiasVimeo" :key="vimeomidias.id+index">
                        <b-list-group-item @click="selectUrlVimeo(vimeomidias)" class="listYt">
                          <div>
                            <img loading="lazy" class="imgVideo" :src="vimeomidias.pictures.sizes[2].link"
                              :alt="vimeomidias.name" />
                          </div>
                          <div class="titleVideo">{{ vimeomidias.name }}</div>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                    <div class="paginacao">
                      <div class="txtPaginacao" @click="selectPageVimeo('prev')">
                        Anterior
                      </div>
                      <div class="txtPaginacao" @click="selectPageVimeo('next')">
                        Proxima
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="spaceImageslib" v-if="active == 'linkvideo'">
              <b-col cols="12" md="12" class="">
                <div class="listLinksVideos">
                  <b-form-group label="Tipo de Link" label-for="name-module">
                    <b-form-select v-model="selectedLink"
                      :options="optionsType"></b-form-select>
                  </b-form-group>
                  <b-form-group v-if="selectedLink === 'youtube'" label="Youtube" label-for="name-module">
                    <b-form-input v-model="midiaEmbedYt" placeholder="Seu link"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="selectedLink === 'vimeo'" label="Vimeo" label-for="name-module">
                    <b-form-input v-model="midiaEmbedVimeo" placeholder="Seu link"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="selectedLink === 'iframe'" label="Iframe" label-for="name-module">
                    <b-form-textarea id="textarea" v-model="midiaEmbedIframe" placeholder="Seu Iframe aqui..." rows="3"
                      max-rows="6"></b-form-textarea>
                  </b-form-group>
                  <button id="btn-link" class="btn-upload col-md-4 col-4 mt-5" @click="salvarMidiaLink">
                    Selecionar vídeo
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                        fill="white" />
                    </svg>
                  </button>
                </div>
              </b-col>
            </b-row>
            <ConfigCourseVoompTube
              v-if="active == 'voomptube'"
              :data="media?.type == active ? media?.data : ''"
              @onConfigured="onVoompTubeConfigured"
              class="flex-1"
            />
          </div>
        </b-col>
      </b-row>

      <!-- Media Selector (right sidebar) -->
      <div class="selector-sidebar" v-if="openSelectorSidebar">
        <div class="media-sidebar animationMenu2 animationOpacity">
          <div class="media-sidebar__header" @click="closeModalItem">
            <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" :text="$t('media_management.preview')" />
            <IconSax color="var(--fontcolor)" name="close-circle" />
          </div>

          <div class="selected-media">
            <div class="selected-media__thumb">
              <div class="selected-media__thumb__video">
                <video
                  v-if="midiaSelectedModal.mime === 'youtube'"
                  ref="videoPlayer" class="video-js widthVideoPlayer"
                  :tech-order="'youtube'"
                  :autoplay="false"
                  :controls="true"
                >
                  <source
                    :src="'https://www.youtube.com/watch?v=' + midiaSelectedModal.id.videoId"
                    :type="'video/youtube'"
                  />
                </video>

                <video
                  v-if="midiaSelectedModal.mime === 'vimeo'"
                  ref="videoPlayer"
                  class="video-js widthVideoPlayer"
                  :tech-order="'vimeo'"
                  :autoplay="false"
                  :controls="true"
                >
                  <source :src="midiaSelectedModal.link" :type="'video/vimeo'" />
                </video>
              </div>

              <div class="selected-media__thumb__image" v-if="midiaSelectedModal.mime !== 'youtube' && midiaSelectedModal.mime !== 'vimeo'">
                <ArquiveThumb
                  :isIframe="isPdf || isMp4WithCdnUrl"
                  :type="midiaSelectedModal.mime"
                  :src="isMpeg ? midiaSelectedModal.cover : midiaSelectedModal.cdn_url || midiaSelectedModal.thumbnail || midiaSelectedModal.cover"
                />
              </div>
            </div>

            <audio class="selected-media__audio-control" v-if="isMpeg" controls>
              <source :src="midiaSelectedModal.cdn_url" :type="midiaSelectedModal.mime">
            </audio>

            <div class="selected-media__title">
              <div class="d-flex align-items-center justify-content-between">
                <TextPlay
                  size="sm"
                  color="var(--fontcolor)"
                  weight="semibold"
                  :text="$t('media_management.file_name')"
                />
                <IconSax v-if="!isVimeo && !isYoutube" id="filename-info" color="var(--text-color)" name="info-circle" />
                <b-tooltip custom-class="tooltipXX" target="filename-info">
                  <div class="customized-tooltip">
                    <TextPlay size="xs" v-html="$t('media_management.filename_rules')" />
                  </div>
                </b-tooltip>
              </div>

              <TextPlay
                v-if="!isEditingFileName && !isVimeo && !isYoutube"
                size="sm" color="var(--text-color)"
                :text="midiaSelectedModal.title"
              />
              <TextareaFormPlay
                showErrorMessage
                name="Nome"
                heightByWrite
                required
                v-validate="{ max:100, name_pattern: true }"
                @enter="handleToggleInputEditFileName"
                v-if="isEditingFileName && !isVimeo && !isYoutube"
                v-model="selectedMediaName"
                max="100"
              />
              <div
                v-if="!isVimeo && !isYoutube"
                @click="handleToggleInputEditFileName"
                class="cursor-pointer d-flex"
              >
                <IconSax class="mr-2" size="0.9rem" :name="isEditingFileName ? 'tick-square' :'edit'" primary />
                <TextPlay
                  size="sm"
                  color="var(--maincolor)"
                  :text="isEditingFileName ? $t('media_management.save') : $t('media_management.edit')"
                />
              </div>
            </div>

            <DividerPlay />

            <div class="selected-media__type">
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" :text="$t('media_management.type')" />
              <TextPlay size="sm" color="var(--text-color)" :text="selectedMediaType" />
            </div>

            <DividerPlay />

            <div class="selected-media__audio" v-if="midiaSelectedModal.mime === 'audio/mpeg'">
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" :text="$t('media_management.audio_thumb')" />
              <TextPlay size="sm" color="var(--text-color)" v-html="$t('media_management.recommended_size')" />
              <ButtonPlay
                @click="showModalUploadThumbAudio"
                type="normal"
                :text="$t('media_management.click_and_upload_audio_thumb')"
              />
            </div>

            <DividerPlay v-if="midiaSelectedModal.mime === 'audio/mpeg'" />

            <div class="selected-media__dates">
              <TextPlay size="sm" color="var(--fontcolor)" weight="semibold" :text="$t('media_management.upload_date')" />
              <TextPlay size="sm" color="var(--text-color)" :text="selectedMediaUpdatedDate" />
            </div>

            <DividerPlay v-if="midiaSelectedModal.mime === 'application/pdf'" />

            <div class="selected-media__protect" v-if="midiaSelectedModal.mime === 'application/pdf'">
              <div class="d-flex align-items-center justify-content-between">
                <TextPlay tag="label" for="protect-doc" size="sm" color="var(--fontcolor)" weight="semibold" :text="$t('media_management.protected_archive')" />
                <IconSax id="protect-doc" color="var(--text-color)" name="info-circle" />
                <b-tooltip custom-class="tooltipXX" target="protect-doc">
                  <div class="customized-tooltip">
                    <TextPlay size="xs" v-html="$t('media_management.changes_student_data')" />
                  </div>
                </b-tooltip>
              </div>

              <b-form-checkbox
                v-model="midiaSelectedModal.protected"
                @change="updateBlocked(midiaSelectedModal)"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>

            <DividerPlay v-if="midiaSelectedModal.mime === 'application/pdf'" />

            <div class="selected-media__custom-fields" v-if="midiaSelectedModal.mime === 'application/pdf'">
              <TextPlay tag="label" for="custom-fields" size="sm" color="var(--fontcolor)" weight="semibold" :text="$t('media_management.custom_fields')" />
              <b-form-checkbox
                v-model="midiaSelectedModal.use_custom_fields"
                @change="updateCustoms(midiaSelectedModal)"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>

            <div v-if="midiaSelectedModal.use_custom_fields">
              <multiselect-play
                closeOnSelect
                multiple
                :showLabels="false"
                v-model="customInputs"
                :options="optionsLessonCategory"
              />
            </div>
          </div>

          <div class="selector-actions mt-3">
            <div class="selector-actions__library" v-if="(active === 'library' && midiaSelectedModal.disk !== 'panda') || selectInUpload">
              <ButtonPlay
                :text="$t('media_management.select_media')"
                v-show="showSelectBtnMedia"
                type="normal"
                w="100%"
                p="16px"
                @click="SelectMidia"
              />
              <ButtonPlay
                v-if="midiaSelectedModal.id"
                color="white"
                bgColor="var(--feedback-error-500)"
                borderColor="var(--feedback-error-500)"
                bgColorOnHover="var(--feedback-error-600)"
                borderColorOnHover="var(--feedback-error-600)"
                w="100%"
                p="16px"
                @click="removeMidia(midiaSelectedModal.id)"
                :text="$t('media_management.delete_media')"
              />
            </div>

            <div class="selector-actions__youtube" v-if="midiaSelectedModal.mime === 'youtube'">
              <ButtonPlay
                :text="$t('media_management.select_video')"
                v-show="showSelectBtnMedia"
                type="normal"
                w="100%"
                p="16px"
                @click="addUrlYoutube"
              />
            </div>

            <div class="selector-actions__panda" v-if="isPanda">
              <ButtonPlay
                v-show="showSelectBtnMedia"
                :text="$t('media_management.select_video')"
                type="normal"
                w="100%"
                p="16px"
                @click="addUrlPanda"
              />
              <ButtonPlay
                v-if="midiaSelectedModal.id"
                color="white"
                bgColor="var(--feedback-error-500)"
                borderColor="var(--feedback-error-500)"
                bgColorOnHover="var(--feedback-error-600)"
                borderColorOnHover="var(--feedback-error-600)"
                w="100%"
                p="16px"
                @click="removeVideo(midiaSelectedModal.id)"
                :text="$t('media_management.delete_video')"
              />
            </div>

            <div class="selector-actions__vimeo" v-if="midiaSelectedModal.mime === 'vimeo'">
              <ButtonPlay
                v-show="showSelectBtnMedia"
                :text="$t('media_management.select_video')"
                type="normal"
                w="100%"
                p="16px"
                @click="addUrlVimeo"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Media Selector (right sidebar) -->
    </Modal>

    <ModalUploadPanda />
    <ModalPlanos :percentage="value" />
  </div>
</template>

<script>
import UploadComponent from "@/components/UploadComponent";
import MediasService from "@/services/resources/MediaService";
import ConnectionService from "@/services/resources/ConnectionsService";
import SiteConfigSerivice from "@/services/resources/SiteConfigService";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import "videojs-youtube";
const serviceSiteConfig = SiteConfigSerivice.build();
const serviceMedias = MediasService.build();
const serviceConnection = ConnectionService.build();
import Cookies from "js-cookie";
import axios from "axios";
import CustomFieldsService from "@/services/resources/CustomFieldsService";
const service = CustomFieldsService.build();
import ModalPlanos from "@/components/ModalPlanos.vue";
import { mapGetters, mapActions } from "vuex";
import ConfigCourseVoompTube from "@/views/ConfigCourseVoompTube.vue";
import { getExtensionFileFromURL } from "@/helpers";
import ArquiveCard from "@/components/ArquiveCard.vue";
import ArquiveThumb from "@/components/ArquiveThumb.vue";

export default {
  components: {
    UploadComponent,
    ModalPlanos,
    ConfigCourseVoompTube,
    ArquiveCard,
    ArquiveThumb,
  },
  props: {
    typeLesson: String,
    courseId: Number,
    moduleId: Number,
    lessonId: Number,
    id: {
      type: String,
      default: "modal-upload"
    },
    visible: {
      default: false
    },
    type: {
      type: String,
      default: "image"
    },
    media: {
      type: Object
    }
  },
  data() {
    return {
      selectedMediaName: "",
      isEditingFileName: false,
      isLoadingMedia: false,
      value: 0,
      value1: 0,
      value2: 0,
      videoFile: "",
      files: [],
      typeToUrl: "",
      AllMedias: [],
      idMidia: "",
      idMidiaUrl: "",
      midiaEmbed: "",
      notLinks: false,
      GoogleConnections: "",
      connectGoogleId: null,
      VimeoConnections: "",
      connectVimeoId: null,
      vimeoUrlConnection: "",
      MidiasGoogle: "",
      MidiasVimeo: "",
      searchVideo: "",
      nextPageToken: "",
      prevPageToken: "",
      toPageToken: "",
      pageVimeo: 1,
      lastPageVimeo: 1,
      lastPageMedia: 1,
      pageMedia: 1,
      openSelectorSidebar: false,
      midiaSelectedModal: null,
      searchMidias: "",
      openIntFrame: false,
      srcIframe: "",
      idMidiaType: "",
      midiaEmbedYt: "",
      midiaEmbedVimeo: "",
      midiaEmbedIframe: "",
      selectedLink: "vimeo",
      optionsType: [
        { value: "vimeo", text: "Link do Tipo Vimeo" },
        { value: "youtube", text: "Link do Tipo Youtube" },
        { value: "iframe", text: "Iframe" },
      ],
      active: "upload",
      customInputs: null,
      optionsLessonCategory: [],
      mediaTypeSelect: this.type?.toLowerCase(),
      data: "",
      hasPanda: true,
      typeUpload: ".mp3",
      idMidiaTitle: "",
      midiaSelectedModal2: null,
      selectInUpload: false,
      allItens: false,
      showSelectBtnMedia: true,
    };
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
    }),
    filteredLibraryTitle() {
      const optionsMap = {
        todo: this.$t("media_management.all"),
        image: this.$t("media_management.images"),
        video: this.$t("media_management.videos"),
        application: this.$t("media_management.documents"),
        audio: this.$t("media_management.audios"),
      }
      return optionsMap[this.mediaTypeSelect.toLowerCase()];
    },
    librarySelectOptions() {
      const options = [
        { id: 'todo', label: this.$t("media_management.all") },
        { id: 'image', label: this.$t("media_management.images") },
        { id: 'video', label: this.$t("media_management.videos")} , 
        { id: 'application', label: this.$t("media_management.documents") },
      ];
      if(this.typeLesson !== 'VIDEO' || this.allItens !== false){
        options.splice(3, 0, {id: 'audio', label: this.$t("media_management.audios")});
      }
      return options;
    },
    hasFiles() {
      return this.files.length > 0;
    },
    isYoutube() {
      return this.midiaSelectedModal.mime === 'youtube';
    },
    isVimeo() {
      return this.midiaSelectedModal.mime === 'vimeo';
    },
    isVideoMime() {
      return ['video/mp4', 'video/x-matroska', 'video/quicktime'].includes(this.midiaSelectedModal.mime);
    },
    isMp4WithoutCdnUrl() {
      return this.isVideoMime && !this.midiaSelectedModal.cdn_url && !!this.midiaSelectedModal.thumbnail;
    },
    isMp4WithCdnUrl() {
      return this.isVideoMime && !!this.midiaSelectedModal.cdn_url;
    },
    isMpeg() {
      return this.midiaSelectedModal.mime === 'audio/mpeg' || this.midiaSelectedModal.mime === 'audio/mp3' || this.midiaSelectedModal.mime === 'mpeg';
    },
    isPdf() {
      return this.midiaSelectedModal.mime === 'application/pdf';
    },
    isImageWithSmallHorizontalUnset() {
      return (
				(this.midiaSelectedModal.mime === "image/jpeg" ||
					this.midiaSelectedModal.mime === "image/jpg" ||
					this.midiaSelectedModal.mime === "image/webp" ||
					this.midiaSelectedModal.mime === "image/png") &&
				this.midiaSelectedModal.small_horizontal !== null &&
				this.midiaSelectedModal.small_horizontal !== undefined
			);
    },
    srcSettingHorizontalUnset() {
      return this.midiaSelectedModal.small_horizontal +
              ' 200w, ' +
              this.midiaSelectedModal.medium_horizontal +
              ' 300w, ' +
              this.midiaSelectedModal.large_horizontal +
              ' 1000w';            
    },
    isRar() {
      return this.midiaSelectedModal.mime === 'application/x-rar' ||
              this.midiaSelectedModal.mime === 'application/x-rar-compressed';
    },
    isWordDoc() {
      return this.midiaSelectedModal.mime === 'application/msword' ||
              this.midiaSelectedModal.mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    },
    isExcelDoc() {
      return this.midiaSelectedModal.mime === 'application/vnd.ms-excel' ||
              this.midiaSelectedModal.mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    },
    isPowerPointDoc() {
      return this.midiaSelectedModal.cdn_url.includes('.pptx') ||
              this.midiaSelectedModal.mime === 'application/vnd.ms-powerpoint' ||
              this.midiaSelectedModal.mime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    },
    isImageWithSmallHorizontalSetted() {
			return (
				((this.midiaSelectedModal.mime === "image/jpeg" ||
					this.midiaSelectedModal.mime === "image/jpg" ||
					this.midiaSelectedModal.mime === "image/webp" ||
					this.midiaSelectedModal.mime === "image/png") &&
					this.midiaSelectedModal.small_horizontal === null) ||
				this.midiaSelectedModal.small_horizontal === undefined
			);
		},
    isPanda() {
      return (
				(this.midiaSelectedModal.mime === "video/mp4" ||
					this.midiaSelectedModal.mime === "video/x-matroska" ||
					this.midiaSelectedModal.mime === "video/quicktime") &&
				this.midiaSelectedModal.disk === "panda"
			);
    },
    selectedMediaType() {
      const mime = this.midiaSelectedModal.mime;
      return mime === 'youtube' ? 'YouTube' : mime === 'vimeo' ? 'Vimeo' : mime;
    },
    selectedMediaUpdatedDate() {
      const media = this.midiaSelectedModal;
      const mime = media.mime;
      const time = mime === 'youtube' ? media.snippet.publishedAt : mime === 'vimeo' ? media.created_time : media.updated_at;
      return this.$moment(time).format('DD/MM/YYYY HH:mm:ss');
    },
    getNameAudio: function () {
      return (index) => {
        if (this.files[index].data_file) {
          return this.files[index].data_file.title;
        } else {
          return this.files[index].title;
        }
      };
    },
    allowedExtensions: function () {
      if (this.typeToUrl === "certify") {
        return ["png", "jpeg", "jpg", "webp"];
      }
      return [];
    },
    assetsUrl() {
      return process.env.VUE_ASSETS_URL
    }
  },
  methods: {
    ...mapActions({
      fetchMe: "memberPlan/fetchMe",
      actionTUSUpload: "courses/actionTUSUpload",
      updateFileName: "media/updateFileName",
      fetchMedias: "media/fetchMedias",
    }),
    changeLibrarySelect() {
      this.pageMedia = 1;
      this.getMidiasNotConnection();
    },
    handleToggleInputEditFileName() {
      if(this.isEditingFileName) {
        this.saveTitle();
        return;
      }
      this.isEditingFileName = true;
    },
    callPandaUpload() {
      document.getElementById("arquivo").click();
    },
    shouldShowIframe(mime) {
      const supportedMimes = [
        'application/pdf',
        'ppt',
        'pptx',
        'application/vnd.ms-excel',
        'application/msword'
      ];

      return supportedMimes.includes(mime);
    },
    getIframeSrc(mime, cdnUrl) {
      return mime !== 'application/msword' ? cdnUrl : '';
    },
    removeVideo(id) {
      serviceMedias
        .destroy(id)
        .then(() => {
          this.$bvToast.toast(this.$t('media_management.delete_media_success'), {
            title: this.$t('media_management.success'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.closeModalItem();
        })
        .catch((err) => {
          let error = JSON.parse(err.response.data);
          let msg = "";
          for (let indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            this.$bvToast.toast(msg, {
              title: this.$t('media_management.error'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        }).finally(() => {
          this.getMidiasNotConnection()
          this.getCustoms();
          this.getPorcentage();
        });
    },
    removeMidia(id) {
      serviceMedias
        .destroy(id)
        .then(() => {
          this.$bvToast.toast(this.$t('media_management.delete_media_success'), {
            title: this.$t('media_management.success'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.closeModalItem();
        })
        .catch((err) => {
          let error = JSON.parse(err.response.data);
          let msg = "";
          for (let indice in error) {
            msg += error[indice][0];
          }
          if (msg !== "") {
            this.$bvToast.toast(msg, {
              title: this.$t('media_management.error'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        }).finally(() => {
          this.getMidiasNotConnection()
          this.getCustoms();
          this.getPorcentage();
        });
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    async getPorcentage() {
      const response = await this.fetchMe()

      this.value = parseInt(response.percentage, 10);
      this.value1 = response.storage_limit;
      this.value2 = response.used_storage;
      this.minsize = 15;

      if (this.value >= 90 &&
        !Cookies.get("modalPlans")
      ) {
        Cookies.set("modalPlans", true, { expires: 1 });
        this.$root.$emit("bv::show::modal", "modal-plans", "#btnShow");
      }

    },
    async saveTitle() {
      await this.$validator._base.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$bvToast.toast(this.$t('media_management.file_name_rules_error'), {
          title: this.$t('media_management.save_name_error'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        let data = {
          id: this.midiaSelectedModal.id,
          title: this.selectedMediaName,
        };

        try {
          await this.updateFileName(data);
          this.idMidiaTitle = this.midiaSelectedModal.title;
          this.$bvToast.toast(this.$t('media_management.save_name_success'), {
            title: this.$t('media_management.save_name_success_desc'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.isEditingFileName = false;
          this.midiaSelectedModal.title = this.selectedMediaName;
        } catch (error) {
          this.$bvToast.toast(this.$t('media_management.save_name_error_desc'), {
            title: this.$t('media_management.save_name_error'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      }
    },
    showModalUploadThumbAudio() {
      this.midiaSelectedModal2 = this.midiaSelectedModal;
      this.closeModalItem();
      this.$root.$emit("uploadcapaaudio");
      this.$root.$emit("bv::show::modal", this.id, "#btnShow");
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    verifyResource() {
      let data = {
        id: "/has_panda",
      };
      serviceSiteConfig
        .read(data)
        .then((resp) => {
          if (resp.has_panda === "off") {
            this.typeUpload = ".mp3";
            this.mediaTypeSelect = "audio";
          } else {
            this.typeUpload = ".mp4, .mp3, .mkv";
          }
        })
    },
    clearList() {
      this.files = [];
    },
    async sendToBack() {
      let allfiles = this.files;
      this.files = allfiles.reverse();
      let video = this.files;
      window.temp1 = document.getElementById("arquivo");
      let that = this;
      for (let i = 0; i < video.length; i++) {
        let xsize = video[i].size;
        let data = {
          mime: video[i].type,
          size: xsize.toString(),
          file_name: video[i].name,
          type: "video",
        };

        let header = {
          Authorization: Cookies.get("auth_greennCourse"),
          "Content-Type": "application/json",
        };

        if (video[i].type === "audio/mpeg") {
          data = {
            file: temp1.files[i],
          };
          header = {
            Authorization: Cookies.get("auth_greennCourse"),
            "Content-Type": "multipart/form-data",
          };
          data = this.jsonToFormData(data);
        }

        let indexx = i;

        let config = {
          method: "post",
          url: this.returnApi() + "/media",
          headers: header,
          data: data,
        };
        await axios(config)
          .then(function (response) {
            that.getPorcentage();
            if (video[i].type === "audio/mpeg") {
              that.files[i].data_file = response.data;
              that.selectInUpload = true;
              that.$root.$emit("statusUpload", [i, "Upload feito com Sucesso"]);
              return;
            }
            that.$root.$emit("statusUpload", [indexx, "Fazendo Upload"]);
            that.sendPanda(response.data.location, indexx, response.data.metadata);
          })
          .catch(function (err) {
            that.$root.$emit("statusUpload", [i, "Erro ao fazer o Pré-Upload"]);
            let error = err.response.data;
            let msg = "";
            for (let indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              this.$bvToast.toast(msg, {
                title: this.$t('media_management.error'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            }
            if (error.response.data.size) {
              that.$root.$emit("bv::show::modal", "modal-plans", "#btnShow");
            }
            that.getPorcentage();
          });
      }
    },
    initProgress(stop, index) {
      const timer = setInterval(() => {
        const file = this.files[index] || {};
        if (file.porcentage < 99 && !stop) {
          const random = Math.random() * 30;
          if (file.porcentage + random < 99) {
            file.porcentage += random;
          }
          let teste = this.files;
          this.files = [];
          this.files = teste;
        } else {
          file.porcentage = 100;
          let teste = this.files;
          this.files = [];
          this.files = teste;
          clearInterval(timer);
        }
      }, 1000);
    },
    async sendPanda(location, index, metadata) {

      let that = this;
      let file = this.files[index];
      this.files[index].porcentage = 0;
      this.initProgress(false, index);

      try {
        await this.actionTUSUpload({
          location,
          file,
          metadata
        });
        that.$root.$emit("statusUpload", [
          index,
          "Upload feito com Sucesso",
        ]);
        that.$root.$emit(
          "bv::show::modal",
          "modal-upload-planda",
          "#btnShow"
        );
        that.initProgress(true, index);
      } catch (err) {
        that.$root.$emit("statusUpload", [index, "Erro ao fazer Upload"]);
      }
    },
    changeFiles(e) {
      if (!e.target.files) {
        return;
      }

      let videos = Array.from(e.target.files).map(video => {
        video.textstatus = "Fazendo Pré-Upload";
        return video;
      });

      this.files = [...this.files, ...videos];
      this.videoFile = videos;

      setTimeout(() => {
        this.sendToBack();
      }, 500);
    },
    updatePanda() {
      let that = this;
    },
    returnApi() {
      return process.env.VUE_APP_API_HOST;
    },
    customLabel({ text }) {
      return `${text}`;
    },
    getCustoms() {
      service
        .search()
        .then((resp) => {
          resp.forEach((item) => {
            this.optionsLessonCategory.push({
              id: item.id,
              label: item.name,
            });
          });
        })
    },
    async updateCustoms(midia) {
      if (midia.use_custom_fields === 0 || midia.use_custom_fields === false) {
        let result = "off";
      } else {
        let result = "on";
      }

      let data = {
        id: midia.id + `/meta/use_custom_fields`,
        value: result,
      };

      await serviceMedias
        .postID(data)
        .then((resp) => {
          if (resp.value === "off" || resp.value === false) {
            resp.value = false;
          } else {
            resp.value = true;
          }
          this.midiaSelectedModal.use_custom_fields = resp.value;
        })
    },
    salvarMidiaLink() {
      if (
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedYt === "" &&
        this.midiaEmbedIframe === ""
      ) {
        this.$bvToast.toast(this.$t('media_management.put_a_link'), {
          title: this.$t('media_management.error'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      } else if (
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedYt === "" &&
        this.midiaEmbedIframe === ""
      ) {
        this.$bvToast.toast(this.$t('media_management.only_one_field'), {
          title: this.$t('media_management.error'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if (
        this.midiaEmbedYt !== "" &&
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedIframe === ""
      ) {
        let youtube_id = getYoutubeID(this.midiaEmbedYt);
        let data = {
          id: {
            videoId: youtube_id,
          },
          snippet: {
            thumbnails: {
              standard: {
                url:
                  "https://img.youtube.com/vi/" + youtube_id + "/sddefault.jpg",
              },
            },
          },
        };
        let datato = this.typeToUrl;
        if (datato === "uploadintroducao") {
          this.$root.$emit("midiaintroducaoyt", data);
        } else if (datato === "uploadlesson") {
          this.$root.$emit("midialessonyt", data);
        } else if (datato === "videotermos") {
          this.$root.$emit("midiatermoyt", data);
        }
      } else if (
        this.midiaEmbedYt === "" &&
        this.midiaEmbedVimeo !== "" &&
        this.midiaEmbedIframe === ""
      ) {
        let data = {
          link: this.midiaEmbedVimeo,
          duration: "",
          pictures: {
            sizes: {
              0: { link: "vimeolink" },
            },
          },
        };
        let datato = this.typeToUrl;
        if (datato === "uploadintroducao") {
          this.$root.$emit("midiaintroducaovimeo", data);
        } else if (datato === "uploadlesson") {
          this.$root.$emit("midialessonvimeo", data);
        } else if (datato === "videotermos") {
          this.$root.$emit("midiatermovimeo", data);
        }
      } else if (
        this.midiaEmbedYt === "" &&
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedIframe !== ""
      ) {
        let data = {
          link: this.midiaEmbedIframe,
          duration: "",
          pictures: {
            sizes: {
              0: { link: "iframe" },
            },
          },
        };

        let datato = this.typeToUrl;
        if (datato === "uploadlesson") {
          this.$root.$emit("midialessoniframe", data);
        } else if (datato === "videotermos") {
          this.$root.$emit("midiatermoiframe", data);
        } else {
          this.$bvToast.toast(this.$t('media_management.iframe_just_for_class'), {
            title: this.$t('media_management.error'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      }

      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", this.id, "#btnShow");

      setTimeout(() => {
        (this.midiaEmbedYt = ""),
          (this.midiaEmbedVimeo = ""),
          (this.midiaEmbedIframe = "");
      }, 1000);
    },
    openWindowGoogle() {
      const assign =
        "auth_greennCourse" +
        "=" +
        escape(Cookies.get("auth_greennCourse")) +
        ";";
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString() + ";";
      const path = "path=/;";
      let domain = "domain=" + "app.greenn.club" + ";";

      document.cookie = assign + expires + path + domain;
      if (process.env.VUE_APP_TYPE === "DEV") {
        this.srcIframe =
          "http://localhost:8080/login-to-google/" +
          Cookies.get("auth_greennCourse");
      } else {
        this.srcIframe =
          "https://app.greenn.club/login-to-google/" +
          Cookies.get("auth_greennCourse");
      }
      setTimeout(() => {
        this.openIntFrame = true;
        this.openIntegracaoGoogle();
      }, 1000);
    },
    removeLogin() {
      this.connectGoogleId = null;
      this.connectVimeoId = null;
      this.VimeoConnections = [];
      this.GoogleConnections = [];
      this.getConnections("vimeo");
      this.getConnections("google");
    },
    openIntegracaoVimeo() {
      clearInterval(interval);
      let interval = setInterval(() => {
        this.getConnections("vimeo");
      }, 5000);
    },
    openIntegracaoGoogle() {
      clearInterval(interval);
      let interval = setInterval(() => {
        this.getConnections("google");
      }, 5000);
    },
    updateBlocked(midia) {
      let data = {
        id: midia.id,
        title: midia.title,
        protected: midia.protected ? 1 : 0,
      };
      this.$root.$emit("loadOn");
      serviceMedias
        .postID(data)
        .then((resp) => {
          if (resp.protected === 0 || resp.protected === false) {
            resp.protected = false;
          } else {
            resp.protected = true;
          }
          this.midiaSelectedModal = resp;
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    changePage(page) {
      this.pageMedia = page;
      this.getMidiasNotConnection();
    },
    selectPageMedia(to) {
      if (to === "next") {
        if (this.pageMedia == this.lastpageMedia) {
          return;
        } else {
          this.pageMedia++;
          this.getMidiasNotConnection();
          return;
        }
      } else {
        if (this.pageMedia === 1) {
          return;
        } else {
          this.pageMedia--;
          this.getMidiasNotConnection();
          return;
        }
      }
    },
    selectPageYt(to) {
      if (to === "next") {
        if (this.nextPageToken === null) {
          return;
        } else {
          this.toPageToken = this.nextPageToken;
          this.getVideoYt(this.nextPageToken);
          return;
        }
      } else {
        if (this.prevPageToken === null) {
          return;
        } else {
          this.toPageToken = this.nextPageToken;
          this.getVideoYt(this.prevPageToken);
          return;
        }
      }
    },
    getVideoYt(page) {
      let url = "";
      if (page === undefined || page === null) {
        url =
          "?type=google&connection_id=" +
          this.connectGoogleId +
          "&search=" +
          this.searchVideo;
      } else if (page === "search") {
        url =
          "?type=google&connection_id=" +
          this.connectGoogleId +
          "&pageToken=" +
          this.toPageToken +
          "&search=" +
          this.searchVideo;
      } else {
        url =
          "?type=google&connection_id=" +
          this.connectGoogleId +
          "&pageToken=" +
          page +
          "&search=" +
          this.searchVideo;
      }
      this.$root.$emit("loadOn");
      serviceMedias
        .read(url)
        .then((resp) => {
          this.MidiasGoogle = resp.items;
          this.nextPageToken = resp.nextPageToken;
          this.prevPageToken = resp.prevPageToken;
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    selectPageVimeo(to) {
      if (to === "next") {
        if (this.pageVimeo == this.lastPageVimeo) {
          return;
        } else {
          this.pageVimeo++;
          this.getVideoVimeo();
          return;
        }
      } else {
        if (this.pageVimeo === 1) {
          return;
        } else {
          this.pageVimeo--;
          this.getVideoVimeo();
          return;
        }
      }
    },
    getVideoVimeo() {
      let url = "";
      if (this.pageVimeo === 1 || this.pageVimeo === null) {
        url =
          "?type=vimeo&connection_id=" +
          this.connectVimeoId +
          "&search=" +
          this.searchVideo;
      } else {
        url =
          "?type=vimeo&connection_id=" +
          this.connectVimeoId +
          "&search=" +
          this.searchVideo +
          "&page=" +
          this.pageVimeo;
      }
      this.$root.$emit("loadOn");
      serviceMedias
        .read(url)
        .then((resp) => {
          this.MidiasVimeo = resp.data;
          let lastPageVimeo = resp.paging.last;
          let myParamPage = lastPageVimeo.split("page=")[1];
          this.lastPageVimeo = myParamPage;
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    addUrlVimeo() {
      let datato = this.typeToUrl;
      if (datato === "uploadintroducao") {
        this.$root.$emit("midiaintroducaovimeo", this.midiaSelectedModal);
      } else if (datato === "uploadlesson") {
        this.$root.$emit("midialessonvimeo", this.midiaSelectedModal);
      } else if (datato === "videotermos") {
        this.$root.$emit("midiatermovimeo", this.midiaSelectedModal);
      }
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", this.id, "#btnShow");
    },
    selectUrlVimeo(item) {
      item.mime = "vimeo";
      this.openSelectorSidebar = true;
      this.midiaSelectedModal = item;
      setTimeout(() => {
        this.getPlayer();
      }, 1000);
    },
    addUrlPanda() {
      let datato = this.typeToUrl;
      if (this.midiaSelectedModal.cdn_url === null) {
        this.midiaSelectedModal.cdn_url = "panda";
      }
      if (datato === "uploadintroducao") {
        this.$root.$emit("midiaintroducaopanda", this.midiaSelectedModal);
      } else if (datato === "uploadlesson") {
        this.$root.$emit("midialessonpanda", this.midiaSelectedModal);
      } else if (datato === "videotermos") {
        this.$root.$emit("midiatermopanda", this.midiaSelectedModal);
      }
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", this.id, "#btnShow");
    },
    addUrlYoutube() {
      let datato = this.typeToUrl;
      if (datato === "uploadintroducao") {
        this.$root.$emit("midiaintroducaoyt", this.midiaSelectedModal);
      } else if (datato === "uploadlesson") {
        this.$root.$emit("midialessonyt", this.midiaSelectedModal);
      } else if (datato === "videotermos") {
        this.$root.$emit("midiatermoyt", this.midiaSelectedModal);
      }
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", this.id, "#btnShow");
    },
    selectUrlYoutube(item) {
      item.mime = "youtube";
      this.openSelectorSidebar = true;
      this.midiaSelectedModal = item;
      setTimeout(() => {
        this.getPlayer();
      }, 1000);
    },
    getPlayer() {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
        }
      );
    },
    selectGoogleConnect(id_connection) {
      this.$root.$emit("loadOn");
      serviceMedias
        .read("?type=google&connection_id=" + id_connection)
        .then((resp) => {
          this.connectGoogleId = id_connection;
          this.MidiasGoogle = resp.items;
          this.openIntFrame = false;
          this.nextPageToken = resp.nextPageToken;
          this.prevPageToken = resp.prevPageToken;
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    selectVimeoConnect(id_connection) {
      this.$root.$emit("loadOn");
      serviceMedias
        .read("?type=vimeo&connection_id=" + id_connection)
        .then((resp) => {
          this.connectVimeoId = id_connection;
          this.MidiasVimeo = resp.data;
          let lastPageVimeo = resp.paging.last;
          let myParamPage = lastPageVimeo.split("page=")[1];
          this.lastPageVimeo = myParamPage;
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    openLinkIntegrationVimeo() {
      this.openIntegracaoVimeo();
      this.$root.$emit("loadOn");
      serviceConnection
        .read("vimeoUrl")
        .then((resp) => {
          this.vimeoUrlConnection = resp.url;
          window.open(resp.url);
          this.$root.$emit("loadOff");
        })
        .catch(() => {
          this.$root.$emit("loadOff");
        });
    },
    closeModalItem() {
      this.idMidia = "";
      this.idMidiaUrl = "";
      this.midiaSelectedModal = null;
      this.openSelectorSidebar = false;
      this.isEditingFileName = false;
    },
    selectMidiasUploaded(media) {
      if (!media) {
        return;
      }
      this.idMidia = media.id;
      this.idMidiaUrl = media.cdn_url;
      this.idMidiaType = media.mime;
      this.idMidiaTitle = media.title;
      if (media.protected === 0) {
        media.protected = false;
      } else {
        media.protected = true;
      }

      this.midiaSelectedModal = media;
      this.openSelectorSidebar = true;
    },
    SelectMidia() {
      let datato = this.typeToUrl;

      if (datato === "uploadcapa") {
        this.$root.$emit("midiacapa", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadlessonadditional") {
        this.$root.$emit("midiaadditional", {
          id: this.idMidia,
          url: this.idMidiaUrl,
          type: this.idMidiaType,
        });
      } else if (datato === "uploadlesson") {
        this.$root.$emit("uploadlessonnew", {
          id: this.idMidia,
          url: this.idMidiaUrl,
          type: this.idMidiaType,
          title: this.idMidiaTitle,
        });
      } else if (datato === "picprofile") {
        this.$root.$emit("newpicprofile", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "logopainel") {
        this.$root.$emit("newlogopainel", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadcapacard") {
        this.$root.$emit("newuploadcapacard", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "certify") {
        const allowedExtensions = ['png', 'jpeg', 'jpg', 'webp'];
        const extension = getExtensionFileFromURL(this.idMidiaUrl);

        if(!allowedExtensions.includes(extension)) {
          this.$bvToast.toast({
            title: this.$t('certificates_component.unsupported_format', { extensions: allowedExtensions.join('/').toUpperCase() }),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }

        this.$root.$emit("midiacertify", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "pdfteste") {
        this.$root.$emit("midiapdfteste", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadlessonthumb") {
        this.$root.$emit("uploadlessonthumbnew", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "logomarcapainel") {
        this.$root.$emit("newlogomarcapainel", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "backgroundlogin") {
        this.$root.$emit("newbackgroundlogin", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadcapamodulo") {
        this.$root.$emit("newuploadcapamodulo", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadcapaaudio") {
        this.showSelectBtnMedia = false;
        this.$root.$emit("newuploadcapaaudio", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
        return;
      } else if (datato === "uploadbanner") {
        this.$root.$emit("newuploadbanner", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadbannermobile") {
        this.$root.$emit("newuploadbannermobile", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      }

      this.$emit("onMediaSelected", {
        origin: datato,
        id: this.idMidia,
        url: this.idMidiaUrl,
        type: this.idMidiaType?.split("/")?.[0]
      });

      if (this.midiaSelectedModal.use_custom_fields) {
        let result = [];
        let dados = this.customInputs;
        for (let i = 0; i < dados.length; i++) {
          const element = dados[i];
          result.push(element.text);
        }
        let data = {
          id: this.idMidia + `/meta/custom_fields`,
          value: result,
        };
        serviceMedias.postID(data)
      }

      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", this.id, "#btnShow");
    },
    getMidiasNotConnection() {
      let dataUrl = `?type=media&page=${this.pageMedia}&mime=${this.mediaTypeSelect}&search_string=${this.searchMidias}`;
      
      this.isLoadingMedia = true;
      this.fetchMedias(dataUrl)
        .then((resp) => {
          this.AllMedias = resp;
          this.pageMedia = resp.current_page;
          this.lastPageMedia = resp.last_page;
          this.updatePanda();
          this.isLoadingMedia = false;
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('media_management.error_loading_media_desc'), {
            title: this.$t('media_management.error_loading_media'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
    },
    tabs(active) {
      this.pageMedia = 1;
      this.searchMidias = "";
      this.active = active;
      this.files = [];
      if (active === "library") {
        this.getMidiasNotConnection();
      } else if (active === "upload") {
        this.getConnections("vimeo");
      } else if (active === "panda") {
        this.verifyResource();
        this.getConnections("vimeo");
      } else if (active === "youtube") {
        this.getConnections("google");
      } else if (active === "vimeo") {
        this.getConnections("vimeo");
      }
      this.openIntFrame = false;
    },
    getConnections(type) {
      serviceConnection
        .search2("type=" + type)
        .then((resp) => {
          if (type === "google") {
            this.GoogleConnections = resp;
          } else if (type === "vimeo") {
            this.VimeoConnections = resp;
          }
        })
    },
    onVoompTubeConfigured(data) {
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", this.id, "#btnShow");
      this.$emit("onMediaSelected", Object.assign({
        origin: this.typeToUrl
      }, data));
    }
  },
  mounted() {
    this.$validator.extend('name_pattern', {
      getMessage(field) {
        return `O campo ${field} não pode conter os seguintes caracteres: /,\\ , :, *, ?, \", <, >, |`;
      },
      validate(value) {
        // eslint-disable-next-line no-useless-escape
        return /^[^\/\\:\*\?\"<>\|]+$/.test(value);
      }
    })
    this.$root.$on("statusUpload", (data) => {
      let index = data[0];
      let teste = this.files;
      const file = teste[index] || {}
      file.textstatus = data[1];
      this.files = [];
      this.files = teste;
    });

    this.$root.$on("uploadcapa", () => {
      this.typeToUrl = "uploadcapa";
      this.active = 'upload'
      this.allItens = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });

    this.$root.$on("uploadWithoutSelect", (data) => {
      this.showSelectBtnMedia = data.showSelectBtnMedia || false;
      this.typeToUrl = "uploadWithoutSelect";
      this.active = 'upload'
      this.allItens = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });

    this.$root.$on("uploadcapamodulo", () => {
      this.typeToUrl = "uploadcapamodulo";
      this.active = 'upload'
      this.allItens = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.getPorcentage();
      this.mediaTypeSelect = "image";
    });

    this.$root.$on("uploadcapaaudio", () => {
      this.mediaTypeSelect = "image";
      this.typeToUrl = "uploadcapaaudio";
      this.showSelectBtnMedia = true;
      this.active = 'upload'
      this.allItens = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.getPorcentage();
    });

    this.$root.$on("uploadbanner", () => {
      this.typeToUrl = "uploadbanner";
      this.active = 'upload'
      this.allItens = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });

    this.$root.$on("uploadbannermobile", () => {
      this.typeToUrl = "uploadbannermobile";
      this.active = 'upload'
      this.allItens = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });

    this.$root.$on("uploadintroducao", () => {
      this.typeToUrl = "uploadintroducao";
      this.active = "panda";
      this.allItens = false;
      this.notLinks = "links";
      this.selectedLink = "vimeo";
      this.midiaEmbedYt = "";
      this.midiaEmbedVimeo = "";
      this.midiaEmbedIframe = "";
      this.mediaTypeSelect = "video";
      this.verifyResource();
      this.getPorcentage();
    });

    this.$root.$on("uploadlesson", (data) => {
      if (data === "audio") {
        this.mediaTypeSelect = "audio";
        this.active = "upload";
        this.notLinks = "notlinks";
      } else {
        this.mediaTypeSelect = "video";
        this.active = "panda";
        this.notLinks = "links";
      }
      this.typeToUrl = "uploadlesson";
      this.allItens = false;

      this.verifyResource();
      this.selectedLink = "vimeo";
      this.midiaEmbedYt = "";
      this.midiaEmbedVimeo = "";
      this.midiaEmbedIframe = "";
      this.getPorcentage();
    });

    this.$root.$on("uploadlessonitem", (data) => {
      this.typeToUrl = "uploadlesson";
      this.active = 'upload';
      this.files = [];
      this.allItens = false;

      this.notLinks = "links";
      this.selectedLink = "vimeo";
      this.midiaEmbedYt = "";
      this.midiaEmbedVimeo = "";
      this.midiaEmbedIframe = "";
      this.selectedLink = data.type;
      if (data.type === "youtube") {
        this.midiaEmbedYt = data.link;
      } else if (data.type === "iframe") {
        this.midiaEmbedIframe = data.link;
      } else {
        this.midiaEmbedVimeo = data.link;
      }
      this.getCustoms();
      this.mediaTypeSelect = "video";
      this.getPorcentage();
    });
    this.$root.$on("newuploadcapaaudio", async (data) => {
      this.midiaSelectedModal = this.midiaSelectedModal2;
      this.midiaSelectedModal.cover = data.url;
      this.selectMidiasUploaded(this.midiaSelectedModal);
      this.typeToUrl = "uploadlesson";

      let data2 = {
        id: this.midiaSelectedModal.id + `/meta/cover_id`,
        value: data.id,
      };

      await serviceMedias.postID(data2)
    });

    this.$root.$on("uploadlessonadditional", () => {
      this.typeToUrl = "uploadlessonadditional";
      this.active = 'upload';
      this.files = [];
      this.notLinks = "notlinks";
      this.getPorcentage();

      this.getCustoms();
      this.mediaTypeSelect = "todo";
      this.allItens = true;
    });

    this.$root.$on("returngoogle", () => {
      this.getConnections("google");
    });

    this.$root.$on("picprofile", () => {
      this.typeToUrl = "picprofile";
      this.active = 'upload';
      this.files = [];
      this.getPorcentage();

      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });

    this.$root.$on("backgroundlogin", () => {
      this.typeToUrl = "backgroundlogin";
      this.active = 'upload';
      this.files = [];
      this.getPorcentage();

      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });

    this.$root.$on("pdfteste", () => {
      this.typeToUrl = "pdfteste";
      this.active = 'upload';
      this.getPorcentage();

      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "application";
      this.allItens = false;
    });

    this.$root.$on("logopainel", () => {
      this.typeToUrl = "logopainel";
      this.active = 'upload';
      this.files = [];
      this.getPorcentage();

      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("logomarcapainel", () => {
      this.typeToUrl = "logomarcapainel";
      this.active = 'upload';
      this.getPorcentage();

      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });

    this.$root.$on("uploadcapacard", () => {
      this.typeToUrl = "uploadcapacard";
      this.active = 'upload';
      this.files = [];
      this.notLinks = "notlinks";
      this.getPorcentage();

      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });

    this.$root.$on("certify", () => {
      this.typeToUrl = "certify";
      this.active = 'upload';
      this.files = [];
      this.getPorcentage();
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });

    this.$root.$on("uploadlessonthumb", () => {
      this.typeToUrl = "uploadlessonthumb";
      this.active = 'upload';
      this.files = [];
      this.getPorcentage();

      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });

    this.$root.$on("midiaUploadedSucess", (data) => {
      
      let fileType = data.file.type;

      if (data.id == this.id) {
        if (fileType.includes("image")) {
          this.mediaTypeSelect = "image";
        } else if (fileType.includes("video")) {
          this.mediaTypeSelect = "video";
        } else if (fileType.includes("application")) {
          this.mediaTypeSelect = "application";
        } else if (fileType.includes("audio")) {
          this.mediaTypeSelect = "audio";
        } else {
          this.mediaTypeSelect = "todo";
        }

        this.tabs("library");
      }
    });

    this.$root.$on("multipleMediaUploadSuccess", () => {
      this.tabs("library");

      this.mediaTypeSelect = "todo";
      this.allItens = true;
    });

    this.$root.$on("videotermos", () => {
      this.mediaTypeSelect = "video";
      this.typeToUrl = "videotermos";
      this.active = "panda";
      this.allItens = false;

      this.verifyResource();
      this.notLinks = "links";
      this.selectedLink = "vimeo";
      this.midiaEmbedYt = "";
      this.midiaEmbedVimeo = "";
      this.midiaEmbedIframe = "";
      this.mediaTypeSelect = "video";
      this.getPorcentage();

    });
    this.$root.$on("opengoogleint", () => {
      this.openIntegracaoGoogle();
    });

    if (/image/gi.test(this.type)) {
      this.active = 'upload';
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    }
  },
  watch: {
    type() {
      if (!this.type) {
        return
      }
      this.typeLesson = this.type.toUpperCase()
    },
    midiaSelectedModal(val) {
      if (val) {
        this.selectedMediaName = val.title;
      }
    },
  }
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.customized-tooltip {
  text-align: start;
  padding: 10px;
}

.upload-modal {
  padding-right: 8px;

  &__tabs {
    display: flex !important;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 32px;

    &__tab {
      display: flex;
      gap: 8px;
      cursor: pointer;
      padding: 8px 0;
      transition: all 0.3s;

      &--active, &:hover {
        .isax path {
          fill: var(--maincolor) !important;
        }

        .text-play {
          color: var(--maincolor) !important;
        }
      }
    }
  }

  .upload-area {
    width: 100%;
    min-height: 55vh;
    display: flex;
    gap: 8px;

    &__drop {
      min-height: 55vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    &__uploading {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }

  .cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(232px, 1fr));
    gap: 16px;
  }

  input.panda-upload {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75% !important;
    opacity: 0;
  }

  .library-tab {
    &__filters {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
      align-items: center;

      &__search.input-play-container {
        max-width: 360px !important;
      }

      &__select {
        max-width: 300px !important;
      }
    }

    &__content {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 32px 0;
      }

      &__pagination {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
      }

      &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40vh;
      }

      &__not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40vh;
      }
    }
  }
}

.selector-sidebar {
  position: fixed;
  background: var(--fundomodal);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;

  .media-sidebar {
    z-index: 99999999;
    position: absolute;
    height: 100%;
    width: 425px;
    top: 0px;
    background: var(--backgroundcolor);
    right: 0px;
    border-radius: 3px 0px 0px 3px;
    padding: 39px;
    overflow: auto;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .isax {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .selected-media {
    &__thumb {
      iframe, img {
        border-radius: 8px;
        width: 100%;
      }

      &__image{
        height: 208px;
        overflow: hidden;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &__audio-control {
      margin-top: 12px;
      width: 100%;
    }

    &__title {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .text-play {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__type, &__dates, &__protect, &__custom-fields, &__audio {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .selector-actions {
    &__youtube, &__library, &__vimeo, &__panda {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

@media(max-width: 768px) {
  .upload-modal {
    &__tabs {
      gap: 12px;
    }
  }
}

  .select-media {
    height: 60px !important;
  }

  fieldset[disabled] .multiselect {
    pointer-events: none;
  }

  .multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block;
  }

  .multiselect__spinner:after,
  .multiselect__spinner:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: #41b883;
    box-shadow: 0 0 0 1px transparent;
  }

  .multiselect__spinner:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  .multiselect__spinner:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }

  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    opacity: 0;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-family: inherit;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
  }

  .multiselect * {
    box-sizing: border-box;
  }

  .multiselect:focus {
    outline: none;
  }

  .multiselect--disabled {
    background: #ededed;
    pointer-events: none;
    opacity: 0.6;
  }

  .multiselect--active {
    z-index: 50;
  }

  .multiselect--active:not(.multiselect--above) .multiselect__current,
  .multiselect--active:not(.multiselect--above) .multiselect__input,
  .multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .multiselect--active .multiselect__select {
    transform: rotate(180deg);
  }

  .multiselect--above.multiselect--active .multiselect__current,
  .multiselect--above.multiselect--active .multiselect__input,
  .multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .multiselect__input,
  .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border 0.1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;
  }

  .multiselect__input:-ms-input-placeholder {
    color: #35495e;
  }

  .multiselect__input::placeholder {
    color: #35495e;
  }

  .multiselect__tag~.multiselect__input,
  .multiselect__tag~.multiselect__single {
    width: auto;
  }

  .multiselect__input:hover,
  .multiselect__single:hover {
    border-color: #cfcfcf;
  }

  .multiselect__input:focus,
  .multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none;
  }

  .multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
  }

  .multiselect__tags-wrap {
    display: inline;
  }

  .multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
  }

  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #41b883;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
  }

  .multiselect__tag-icon:after {
    content: "\D7";
    color: #266d4d;
    font-size: 14px;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #369a6e;
  }

  .multiselect__tag-icon:focus:after,
  .multiselect__tag-icon:hover:after {
    color: #fff;
  }

  .multiselect__current {
    min-height: 40px;
    overflow: hidden;
    padding: 8px 30px 0 12px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
  }

  .multiselect__current,
  .multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
  }

  .multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform 0.2s ease;
  }

  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
  }

  .multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
  }

  .multiselect--active .multiselect__placeholder {
    display: none;
  }

  .multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
  }

  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
  }

  .multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
  }

  .multiselect__content::webkit-scrollbar {
    display: none;
  }

  .multiselect__element {
    display: block;
  }

  .multiselect__option {
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
  }

  .multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px;
  }

  .multiselect__option--highlight {
    background: #41b883;
    outline: none;
    color: #fff;
  }

  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: #41b883;
    color: #fff;
  }

  .multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: 700;
  }

  .multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect--disabled .multiselect__current,
  .multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
  }

  .multiselect__option--disabled {
    background: #ededed !important;
    color: #a6a6a6 !important;
    cursor: text;
    pointer-events: none;
  }

  .multiselect__option--group {
    background: #ededed;
    color: #35495e;
  }

  .multiselect__option--group.multiselect__option--highlight {
    background: #35495e;
    color: #fff;
  }

  .multiselect__option--group.multiselect__option--highlight:after {
    background: #35495e;
  }

  .multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede;
  }

  .multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }

  .multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect-enter-active,
  .multiselect-leave-active {
    transition: all 0.15s ease;
  }

  .multiselect-enter,
  .multiselect-leave-active {
    opacity: 0;
  }

  .multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
  }

  [dir="rtl"] .multiselect {
    text-align: right;
  }

  [dir="rtl"] .multiselect__select {
    right: auto;
    left: 1px;
  }

  [dir="rtl"] .multiselect__tags {
    padding: 8px 8px 0 40px;
  }

  [dir="rtl"] .multiselect__content {
    text-align: right;
  }

  [dir="rtl"] .multiselect__option:after {
    right: auto;
    left: 0;
  }

  [dir="rtl"] .multiselect__clear {
    right: auto;
    left: 12px;
  }

  [dir="rtl"] .multiselect__spinner {
    right: auto;
    left: 1px;
  }

  @keyframes spinning {
    0% {
      transform: rotate(0);
    }

    to {
      transform: rotate(2turn);
    }
  }

  .spaceMembersMobile {
    width: 100% !important;
    padding: 70px 10px 0px 27px !important;

    .table tr.row-table-body {
      line-height: 40px !important;
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
  }

  .modal .tabs-upload.active .text {
    color: var(--maincolor);
  }

  .modal .tabs-upload.active path {
    fill: var(--maincolor) !important;
  }

  .modal {
    background: var(--fundomodal) !important;

    .tosearch {
      .inputSearch {
        position: relative;
        margin-left: 20px;
        margin-bottom: 20px;
        width: 450px;
      }

      .input-busca {
        width: 100%;
        height: 60px !important;
        background: #ffffff;
        border: solid 1px var(--bordercolor);
        border-radius: 5px;
        padding: 20px 30px;
        font-family: Inter;
        font-weight: normal;
        color: #81858e;
        font-size: 14px;
        transition: 0.3s;
      }

      .input-busca:hover,
      .input-busca:focus {
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
      }

      .search {
        position: absolute;
        right: 25px;
        top: 20px;
        width: 14.7px;
        height: 14.7px;
        z-index: 999;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        cursor: pointer;
      }
    }

    .spacePdfLock {
      display: flex;

      .block-pdf {
        position: absolute;
        width: 32px;
        margin-top: -13px;
        margin-left: -12px;
      }
    }

    .gridListen {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
    }

    .gridListenImages {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 12px;
      height: 43vh;
      overflow: auto;
    }

    .modal-dialog.modal-xl {
      width: 98% !important;
      max-width: none !important;
      height: 100% !important;
      margin: auto !important;
      align-items: center;
      display: flex;
    }

    .close {
      color: var(--icon) !important;
    }

    .modal-content {
      background: var(--backgroundcolor) !important;
      height: 96% !important;
    }

    .paginacao {
      width: 100%;
      justify-content: space-around;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;

      .txtPaginacao {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor);
        cursor: pointer;
      }

      .txtPaginacao:hover {
        color: var(--maincolor);
      }
    }

    .listYt {
      cursor: pointer;
      display: flex;
      border: none;

      img.imgVideo {
        max-width: 175px;
        width: 145px;
        max-height: 80px;
        height: 80px;
      }
    }

    #modal-vimeo {
      .modal-header {
        border: none !important;
      }
    }

    textarea.textareaEmbed.form-control {
      width: 80% !important;
      min-height: 10em;
    }

    .listLinksVideos select {
      background-color: var(--backgroundcolor) !important;
    }

    .listLinksVideos::-webkit-scrollbar {
      background-color: var(--backgroundcolor);
      width: 2px;
    }

    .listLinksVideos::-webkit-scrollbar-track {
      background-color: #717171;
    }

    .listLinksVideos::-webkit-scrollbar-thumb {
      background-color: #00000075;
      border-radius: 31px;
    }

    .listLinksVideos::-webkit-scrollbar-button {
      display: none;
    }

    .listLinksVideos {
      margin-top: 30px;
      width: 100%;
      height: 51vh;
      overflow: auto;
      overflow-x: hidden;

      .inputSearch {
        position: relative;
        margin-left: 20px;
        margin-bottom: 20px;
        width: 450px;
      }

      .input-busca {
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: solid 1px var(--bordercolor);
        border-radius: 5px;
        padding: 20px 30px;
        font-family: Inter;
        font-weight: normal;
        color: #81858e;
        font-size: 14px;
        transition: 0.3s;
      }

      .input-busca:hover,
      .input-busca:focus {
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
      }

      .search {
        position: absolute;
        right: 25px;
        top: 24px;
        width: 14.7px;
        height: 14.7px;
        z-index: 999;
        -webkit-transition: 0.3s;
        transition: 0.3s;
      }

      .listIntegration {
        width: 50%;
        margin-bottom: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        border: solid 1px var(--bordercolor);
        color: var(--fontcolor);
        transform: translateY(0px);
        transition: transform 0.44s ease, box-shadow 0.44s ease;
      }

      .listIntegration:hover {
        border: 1px solid var(--maincolor);
        color: var(--maincolor);
        transform: translateY(2px);
        transition: transform 0.44s ease, box-shadow 0.44s ease;
      }

      .listIntegrationYt {
        width: 50%;
        margin-bottom: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        border: solid 1px var(--bordercolor);
        color: var(--fontcolor);
        transform: translateY(0px);
        transition: transform 0.44s ease, box-shadow 0.44s ease;
      }

      .listIntegrationYt:hover {
        border: 1px solid#2133D2;
        color: #2133d2;
        transform: translateY(2px);
        transition: transform 0.44s ease, box-shadow 0.44s ease;
      }

      .btnIntegraçãoVimeo {
        border: none;
        background: var(--maincolortrans);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        width: 231.25px;
        height: 45px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--maincolor);
      }

      .btnIntegração {
        border: none;
        background: rgba(33, 51, 210, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        width: 231.25px;
        height: 45px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #2133d2;
      }

      select {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #6d767e;
        overflow: hidden !important;
        resize: none !important;
      }

      input,
      textarea {
        border: 1px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        overflow: hidden !important;
        resize: none !important;
        background: white;
      }

      input:hover,
      select:hover,
      textarea:hover,
      input:focus,
      select:focus,
      textarea:focus {
        border: 1px solid var(--maincolor);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }

      label {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--fontcolor2);
      }
    }

    .hide {
      display: none;
    }

    .positionx {
      position: absolute;
      transition: 1s ease-in;
      margin-left: 5vw;
      margin-top: 50px;
    }

    iframe.imagem {
      pointer-events: none;
      border-radius: 3px;
      overflow: hidden;
      width: 100%;
    }

    .filter {
      filter: grayscale(100%);
    }

  .imagem {
    border-radius: 3px;
    overflow: hidden;
    background: url("../assets/img/semimagem.jpg");
    min-height: 101px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 100%;
    font-size: 0px;
  }

  .imagem::after {
    content: "";
    width: 20px;
    height: 20px;
    position: relative;
    background: red;
  }


  .allmedia {
    cursor: pointer;
    z-index: 99;
    height: 200px;
    overflow: hidden;
    justify-content: center;
    border: solid 1px #ececec;
    border-radius: 5px;
    padding: 7px;
    display: flex;
    flex-direction: column;
    transition: all .3s;
  }

  .allmedia:hover {
    border-color: #ffbdf1;
  }

    .file-symbol {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .imgMidia {
        width: auto;
        height: 60px;
      }
    }

    .titleVideo {
      flex: 0;
      text-align: center;
    }

    .media {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 36vh;
      overflow: auto;

      .box {
        box-sizing: border-box;
        padding: 1rem;
      }
    }

    .tabs-upload {
      display: flex;
      cursor: pointer;
      margin-right: 2em;

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
      }

      .text2 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #b5b9c5;
      }

      svg {
        margin-right: 10px;
      }

      .yt {
        position: relative;
        top: 4px;
      }

      .vimeo {
        position: relative;
        top: 3px;
      }
    }

    .upload {
      .btn-upload {
        background: var(--maincolor);
        border-radius: 5px;
        height: 55px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }

      .btn-delete {
        background: #ff0c37;
        border-radius: 5px;
        height: 55px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }

      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }

      .desc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #81858e;
      }
    }

    .tabs-upload:hover {
      path {
        fill: var(--maincolor) !important;
      }

      .text {
        color: var(--maincolor) !important;
      }
    }

    .tabs-upload .active {
      path {
        fill: var(--maincolor) !important;
      }

      .text {
        color: var(--maincolor) !important;
      }
    }

    .modal-header {
      border: none !important;
    }
  }

  .flexHeader {
    display: flex;
    justify-content: space-between;

    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 45px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }

    select:focus {
      border: 1px solid var(--maincolor);
    }
  }

.titleVideo {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--fontcolor);
  margin-left: 5px;
  padding-top: 10px;
  line-height: 17px;
}

  .lista-entrega ul li .icon-lixeira {
    width: 14px;
    filter: invert(50%);
  }

  .icon-lixeira:hover {
    filter: invert(0) !important;
  }

  .arquivo-item {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background: #f7f7f7;
    height: fit-content;
    border-radius: 5px;
    font-size: 15px;

    span {
      text-align: center;
    }

    .thumb {
      width: 100%;
      object-fit: cover;
    }
  }

  .arquivo-item+.arquivo-item {
    margin-top: 10px;
  }

  .arquivo-item .item-lixeira {
    width: 20px;
  }

  .arquivo-item img {
    cursor: pointer;
  }

  .newText {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--fontcolor);
  }

  .newTextDiv {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--fontcolor);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .newBtn {
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--maincolor);
    padding-top: 17px;
    width: 292.85px;
  }

  .displayImages {
    display: grid;
    grid-template-columns: repeat(auto-fit, 190px);
    gap: 12px;
    height: 43vh;
    overflow: auto;
  }

  .displayImages tr,
  .displayImages tr div {
    width: 100%;
  }

  .displayImages tr div div {
    width: 100%;
    cursor: pointer;
    overflow: hidden;
  }

  .displayImages tr div div img {
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
  }

  .imgMidiaAudio {
    height: 70%;
    margin: 0 auto;
  }

  .spaceImgModal {
    .imgMidiaAudio {
      height: 50% !important;
    }
  }

  .spaceInputs {
    margin-bottom: 30px;

    .textInfo {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #81858e;
      margin-top: 5px;
    }

    .btn-criar {
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      height: 55px;
      margin-top: 40px;
    }

    .btn-openModal {
      background: var(--maincolortrans);
      border: 2px dashed var(--maincolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      cursor: pointer;

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }

    .gerenciarCat {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
      position: absolute;
      right: 25em;
      cursor: pointer;
    }

    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }

    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }

    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }

  .name-audio {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 45px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
  }

  .change-title {
    height: 45px !important;
    float: right;
  }

  .w100 {
    width: 100% !important;
  }
</style>
