import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
  async actionFetchAllCoursesData({ commit }, payload) {
    const page = payload.page || 1;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await serviceMember.read(
          `/me/all-courses?page=${page}`
        );
        const prevCoursesData = this.getters["myCourses/getListCourses"];
        commit(
          "myCourses/SET_LIST_ALL_COURSES_DATA",
          {
            ...response,
            courses:
              page > 1
                ? [...prevCoursesData.courses, ...response.courses]
                : response.courses,
          },
          { root: true }
        );
        resolve(await response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
