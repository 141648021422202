<template>
  <div id="vsltube" />
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { load_vsl, decode_vsl, vsl_build } from "./VSLTube.js"

export default {
  props: {
    data: String,
    url: String,
    theme: String,
    beginURL: {
      type: String,
      default: ""
    },
    pauseURL: {
      type: String,
      default: ""
    },
    carryOn: {
      type: Boolean,
      default: false
    },
    preview: String,
    next: Function
  },
  data() {
    return {
      vsl: {}
    }
  },
  computed: {
    ...mapGetters({
      getSettings: 'config/getSettings'
    })
  },
  methods: {
    ...mapActions({
      actionFetchSettings: "config/actionFetchSettings",
    }),
    async load() {
      await this.actionFetchSettings();

      let vsl = Object.assign({
        url: this.url,
        theme: this.theme,
        beginURL: this.beginURL,
        pauseURL: this.pauseURL,
        carryOn: this.carryOn,
      }, this.data ? decode_vsl(this.data) : {});

      if (!vsl.url) {
        return
      }

      const mainColor = this.getSettings.main_color || "#f00"

      const playerColor = vsl.theme === "platform" ? mainColor : "#333"

      const actionColor = vsl.theme === "platform" ? mainColor : "#fff"
      
      const playColor = vsl.theme === "platform" ? "#fff" : "#000";

      load_vsl(vsl_build({
        idvideo: getYoutubeID(vsl.url),
        openScreenBG: vsl.beginURL,
        pauseScreenBG: vsl.pauseURL,
        cookieOn: vsl.carryOn ? "1" : "0",
        cookieColor: playerColor,
        actionColor: actionColor,
        playColor,
        barraProgressoColor: actionColor,
        preview: this.preview,
        next: this.next
      }));
    }
  },
  mounted() {
    setTimeout(() => {
      this.load();
    }, 50);
  },
  watch: {
    $props: {
      handler() {
        setTimeout(() => {
          this.load();
        }, 50);
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>
<style>
@import url("./VSLTube.css");
</style>